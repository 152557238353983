var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"min-width":"180px"}},[_c('div',{staticClass:"ma-0 pa-4 d-flex align-top"},[_c('div',[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"font-size":"16px !important","font-weight":"500","text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.name.replace(/_/g, ' '))+" ")]),_c('v-card-subtitle',{staticClass:"pa-0 pt-1 caption"},[_vm._v(" "+_vm._s(_vm.requestId)+" ")])],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-start ml-auto"},[_c('div',{staticClass:"d-flex align-end"},[_vm._t("action")],2)])],1),_c('v-divider'),(!_vm.borderColor)?_c('v-card-text',{staticClass:"ma-0 pa-2",style:({
      borderBottom: _vm.borderColor !== '' ? ("4px solid " + _vm.borderColor) : ''
    })},[_c('div',{staticStyle:{"width":"100%","display":"flex","word-wrap":"break-word"}},[_c('v-card-text',{staticClass:"justify-start",staticStyle:{"width":"82%"}},[_vm._v(" "+_vm._s(_vm.formattedBody)+" ")]),(_vm.formattedBody && _vm.formattedBody.length > 25)?_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.expand = !_vm.expand}}},[_c('v-icon',[_vm._v(_vm._s(_vm.expand ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1):_vm._e()],1),_c('v-card-actions',{staticClass:"justify-end mx-2 pb-4 pr-2"},[_c('v-dialog',{attrs:{"transition":"slide-x-reverse-transition","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","outlined":"","fab":"","color":"#2a51e8"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-keyboard-backspace mdi-rotate-180")])],1)]}}],null,false,623588381),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"min-height":"200px"}},[_c('v-app-bar',{staticClass:"ma-0 pa-2",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticStyle:{"font-size":"16px !important","font-weight":"500","text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.type.replace('_', ' '))+" ")]),_c('v-spacer'),_c('v-card-actions',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"justify-center mt-2"},[_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"text-center"},[_vm._v("OP")]),_c('v-list-item-subtitle',{staticClass:"text-center"},[_vm._v("IP")])],1),_c('v-list',{staticClass:"transparent"},_vm._l((_vm.filterKeys),function(filterkey,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',{staticClass:"text-center",staticStyle:{"text-transform":"capitalize","color":"#2a51e8"}},[_vm._v(_vm._s(filterkey))]),(
                    _vm.getDepenedentParamsList(_vm.type, filterkey, _vm.configuration)
                  )?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#2a51e8"}},[_vm._v("mdi-keyboard-backspace")])],1):_vm._e(),_c('v-list-item-title',{staticClass:"text-center"},[_vm._l((_vm.getDepenedentParamsList(
                      _vm.type,
                      filterkey,
                      _vm.configuration
                    )),function(depen,idx){return [_c('v-chip',{key:idx + depen,staticStyle:{"margin-right":"2px"},attrs:{"outlined":"","color":"#2a51e8"}},[_vm._v(_vm._s(depen))])]})],2)],1)}),1)],1)],1)],1)],1),_vm._t("status")],2):_c('v-card-text',{staticClass:"ma-0 pa-2",style:({
      borderBottom: _vm.borderColor !== '' ? ("4px solid " + _vm.borderColor) : ''
    })},[_c('v-list-item',[_c('v-list-item-subtitle',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.type.replace('_', ' '))+" ")])],1),_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"text-center",staticStyle:{"text-transform":"capitalize","color":"#2a51e8"}},[_vm._v("OP")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1),_c('v-list-item-subtitle',{staticClass:"text-center"},[_vm._l((_vm.filterKeys),function(filterkey,i){return [_c('v-chip',{key:i + filterkey,staticStyle:{"margin-right":"2px"},attrs:{"outlined":"","small":"","color":"#2a51e8"}},[_vm._v(_vm._s(filterkey))])]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }