<template>
  <div class="content-view-port" style="padding: 5px">
    <template v-if="isLoading">
      <v-skeleton-loader style="height: 100%">
        <template #default>
          <div
            class="v-skeleton-loader__card v-skeleton-loader__bone"
            style="height: 100%"
          >
            <div
              class="v-skeleton-loader__image v-skeleton-loader__bone"
              style="height: 100%; min-height: 100px"
            ></div>
          </div>
        </template>
      </v-skeleton-loader>
    </template>
    <template v-else>
      <template v-if="chartConfig.type === 'graph'">
        <GraphChatWrap
          :dataProvider="dataprovider"
          :chartConfig="chartConfig.configuration"
          :windowSizeIdx="windowSizeIdx"
        />
      </template>

      <template v-if="chartConfig.type === 'table'">
        <TableAdvance
          v-if="chartConfig.subType === 'advanced'"
          :dataProvider="dataprovider"
          :chartConfig="chartConfig.configuration"
          :windowSizeIdx="windowSizeIdx"
          :drillConf="drill"
        />
      </template>

      <template v-if="chartConfig.type == 'html'">
        <HtmlTextDisplay
          v-if="chartConfig.subType == 'text_display'"
          :chartConfig="chartConfig.configuration"
          :dataProvider="dataprovider"
          :windowSizeIdx="windowSizeIdx"
        />
      </template>

      <template v-if="chartConfig.type == 'number'">
        <WidgetsMultipleNumber
          v-if="chartConfig.subType == 'multiple_number'"
          :dataProvider="dataprovider"
          :chartConfig="chartConfig.configuration"
          :windowSizeIdx="windowSizeIdx"
        />
        <WidgetsPlainBigNumberTile
          v-if="chartConfig.subType == 'plain_big_number_tile'"
          :dataProvider="dataprovider"
          :chartConfig="chartConfig.configuration"
          :containerSpec="containerSpec"
          :windowSizeIdx="windowSizeIdx"
        />
        <WidgetsUltraBigNumber
          v-if="chartConfig.subType == 'ultra_big_number'"
          :dataProvider="dataprovider"
          :chartConfig="chartConfig.configuration"
          :containerSpec="containerSpec"
          :windowSizeIdx="windowSizeIdx"
        />
      </template>

      <template v-if="chartConfig.type == 'button'">
        <ButtonDownload
          v-if="chartConfig.subType == 'download'"
          :chartConfig="chartConfig.configuration"
          :dataProvider="dataprovider"
          :windowSizeIdx="windowSizeIdx"
        />
        <ButtonEmail
          v-if="chartConfig.subType == 'email'"
          :chartConfig="chartConfig.configuration"
          :dataProvider="dataprovider"
          :windowSizeIdx="windowSizeIdx"
        />
      </template>
    </template>
  </div>
</template>

<script>
import {
  mapActions as mapRootActions,
  createNamespacedHelpers as cnh
} from 'vuex'
export default {
  props: ['elemConf', 'containerSpec', 'drill'],
  data() {
    return {
      chartConfig: {},
      dataprovider: [],
      ecData: {},
      isLoading: true,
      slabs: [320, 640, 768, 1024, 1948],
      windowSizeIdx: 3
    }
  },
  created() {
    let sizeIdx = 0
    for (let slab of this.slabs) {
      if (window.innerWidth > slab) {
        sizeIdx++
      } else {
        break
      }
    }
    this.windowSizeIdx = sizeIdx
    this.chartConfig = this.$Chelper.clone(this.elemConf)
    if (this.chartConfig.configuration && this.chartConfig.name) {
      this.chartConfig.configuration.title = this.$Chelper.makeStringReadable(
        this.chartConfig.name
      )
    }
    // console.log(`[ this.chartConfig ]: `, this)
    this.loadData()
  },
  methods: {
    ...mapRootActions(['getExport']),
    async loadData() {
      try {
        this.isLoading = true
        if (this.chartConfig.type !== 'button') {
          if (this.chartConfig.dataSource) {
            if (this.chartConfig.dataSource.sources_link) {
              this.ecData = await this.getExport({
                name: this.chartConfig.dataSource.sources_link,
                params: {}
              })
              this.dataprovider = this.ecData.info
            } else {
              this.dataprovider = this.chartConfig.dataSource.rows || []
            }
          }
        }
        // console.log({ dataprovider: this.dataprovider })
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.error(error)
      }
    }
  }
}
</script>
<style>
.dl-el-box-ctn {
  width: 100%;
  height: 100%;
}
.content-view-port {
  width: 100%;
  height: 100%;
}
</style>