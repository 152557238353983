<template>
  <div>
    <v-container grid-list-xs fluid class="supp-height" style="overflow: auto">
      <v-row wrap no-gutters>
        <v-col cols="5" class="v-cols-custom">
          <v-tabs v-model="selectedTab" style="background-color: red">
            <v-tab> Connected </v-tab>
            <v-tab> Available </v-tab>
            <v-tab> Requested </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="7" style="float: right" class="v-cols-custom">
          <div style="display: flex">
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style=""
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mt-1 mb-1 mr-2 request-button"
                  @click="openRequestDialog"
                  icon
                >
                  <v-icon class="" color="white"> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>New Request</span>
            </v-tooltip>
            <!-- <v-btn
              class="mr-2"
              outlined
              v-if="selectedTab == 1"
              @click="
                suppSelectMode
                  ? submitSuppleAttachments()
                  : toggleAttachSupplementsMode()
              "
              style="background-color: white"
              color="#2a51e8"
              :disabled="btnLoader"
              ><v-badge
                color="#2a51e8"
                :content="badgeContent"
                :value="badgeContent"
              >
                {{ suppSelectMode ? 'Submit Attachements' : 'Attach' }}
              </v-badge>
            </v-btn> -->

            <v-btn
              color="#2a51e8"
              small
              style="text-transform: capitalize"
              class="ma-2 btn btn-1"
              dark
              @click="
                suppSelectMode
                  ? submitSuppleAttachments()
                  : toggleAttachSupplementsMode()
              "
              :disabled="btnLoader"
              v-if="selectedTab == 1"
            >
              <v-badge
                color="#2a51e8"
                :content="badgeContent"
                :value="badgeContent"
              >
                {{ suppSelectMode ? 'Submit Attachements' : 'Attach' }}
              </v-badge>
            </v-btn>

            <v-btn
              class="mt-1 mr-2"
              text
              small
              icon
              v-if="selectedTab == 1 && suppSelectMode"
              @click="toggleAttachSupplementsMode()"
              color="pink "
            >
              <v-icon>mdi mdi-close</v-icon>
            </v-btn>
            <v-text-field
              class="supp-search"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              label="Search Supplements"
              clearable
              v-model="filterText"
              @click:prepend-inner="setFilterText(selectedTab)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <template
          v-if="(suppSelectMode && selectedTab == 1) || selectedTab == 0"
        >
          <v-col cols="3">
            <!-- <h4>Attached To :-</h4> -->
            <v-radio-group
              v-model="suppleAttachTo"
              row
              class="radio-group-custom"
              style="margin-top: unset; padding: 16px 0 0 16px"
            >
              <v-radio
                v-for="n in ['All', 'Dashboard Level', 'Layout']"
                :key="n"
                :label="n"
                :value="n"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            v-if="suppleAttachTo === 'Layout'"
            class="d-flex mt-3"
            cols="3"
            sm="2"
          >
            <v-select
              :items="allAvailableLayoutsItems"
              label="Select Layout"
              outlined
              dense
              item-text="name"
              item-value="identity"
              return-object
              v-model="selectedLayout"
              @change="prefillSelectedSupplementCheckBoxes"
            ></v-select>
          </v-col>
        </template>
        <v-col cols="12">
          <v-tabs-items v-model="selectedTab" style="background-color: #f8fafd">
            <v-tab-item>
              <ConnectedSupplements @removeSuppl="submitSuppleAttachments" />
            </v-tab-item>
            <v-tab-item>
              <AllSupplements :selectedTab="selectedTab" ref="allSupplements" />
            </v-tab-item>
            <v-tab-item>
              <RequestedSupplements ref="requestedSupplements" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <RequestSupplementDialog
      ref="request-supplement-dialog"
      :requestSupplementDialogStatus="requestSupplementDialogStatus"
      @close-dialog="requestSupplementDialogStatus = false"
    />
  </div>
</template>

<script>
import AllSupplements from './AllSupplements.vue'
import RequestedSupplements from './RequestedSupplements.vue'
// import ConnectedSupplements from ''

import {
  createNamespacedHelpers as cnh,
  mapState as mapRootState,
  mapMutations as mapRootMutations,
  mapActions as mapRootActions
} from 'vuex'
const {
  mapActions,
  mapGetters,
  mapMutations,
  mapState: mapSupplState
} = cnh('supplementStore')
const {
  mapState: mapReportBuilderState,
  mapMutations: mapReportBuilderMutations,
  mapActions: mapReportBuilderActions
} = cnh('ReportBuilder')

export default {
  components: {
    AllSupplements,
    RequestedSupplements,
    RequestSupplementDialog: () => import('./RequestSupplementDialog.vue'),
    ConnectedSupplements: () => import('./ConnectedSupplements.vue')
  },
  data() {
    return {
      selectedTab: 1,
      requestSupplementDialogStatus: false,
      btnLoader: false,
      filterText: ''
    }
  },
  computed: {
    ...mapRootState(['report']),
    ...mapSupplState([
      'suppSelectMode',
      'selectedSupplements',
      'suppleAttachTo',
      'selectedLayoutForSupplAttachment',
      'badgeContent'
    ]),
    ...mapGetters(['listOfSupplements', 'suppPaginationConf']),
    ...mapReportBuilderState(['allAvailableLayoutsData', 'currentLayoutData']),
    allAvailableLayoutsItems() {
      return this.allAvailableLayoutsData.map((l) => {
        return {
          name: l.name,
          identity: l.identity
        }
      })
    },
    suppleAttachTo: {
      get() {
        return this.$store.state['supplementStore'].suppleAttachTo
      },
      set(value) {
        this.setSupplementAttachTo(value)
      }
    },
    selectedLayoutForSupplAttachment: {
      get() {
        return this.$store.state['supplementStore']
          .selectedLayoutForSupplAttachment
      },
      set(value) {
        this.setSelectedLayoutForSupplAttachment(value)
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllRequestedSupplements',
      'getAllSuplements',
      'saveAttachSupplements',
      'getSupplementsByIds'
    ]),
    ...mapMutations([
      'toggleAttachSupplementsMode',
      'setSupplementAttachTo',
      'setSelectedLayoutForSupplAttachment',
      'setSelectedSupplArray',
      'setShowOnlyConnectedSupplements',
      'emptyBadge',
      'setConnectedSupplementsIds',
      'setConnectedSupplement'
    ]),
    ...mapRootMutations(['openSnackbarWithTime', 'toggleLoader', 'setReport']),
    ...mapReportBuilderMutations([
      'setAllLayoutsData',
      'setAllLayoutDataExceptCurrent'
    ]),
    ...mapRootActions(['setReportSupplement']),
    ...mapReportBuilderActions(['setLayoutSupplement']),
    setFilterText(tabId) {
      console.log(tabId)
      if (tabId !== 0) {
        const tabMapping = { 1: 'allSupplements', 2: 'requestedSupplements' }
        this.$refs[tabMapping[tabId]].fetchFromServer({})
      }
      // console.log(`[this]: `, this);
      // let payload = {};
      // if (this.suppPaginationConf.filterText) {
      //   payload.match = {};
      //   payload.match["$or"] = [];
      //   ["name", "createNote", "type"].forEach((col) => {
      //     payload.match.$or.push({
      //       [col]: {
      //         $regex: this.suppPaginationConf.filterText,
      //         $options: "i",
      //       },
      //     });
      //   });
      // }
      // if (page)
      //   this.setSuppPaginationconf(
      //     Object.assign(this.suppPaginationConf, { page })
      //   );
      // this.isFetchingData = true;
      // this.getAllSuplements(payload)
    },
    openRequestDialog() {
      this.requestSupplementDialogStatus = true
      this.$refs['request-supplement-dialog'].openModal('create')
    },
    async getAllConnectedSupplements() {
      console.log(
        '%c _________________CONNE___________________',
        'color:red;font-size:20px'
      )
      const reportLevelAttachedSupplementsIds = this.report.supplements || []

      let layoutsLevelSupplementsIds = []
      Object.keys(this.report.layouts || {}).forEach((layoutId) => {
        let suppl =
          (this.report.layouts[layoutId].structure &&
            this.report.layouts[layoutId].structure.supRefs) ||
          []
        layoutsLevelSupplementsIds.push(...suppl)
      })

      const allIds = [
        ...reportLevelAttachedSupplementsIds,
        ...layoutsLevelSupplementsIds
      ]
      const uniqueIds = Array.from(new Set(allIds))
      this.setConnectedSupplementsIds(uniqueIds)
      let connSupp = await this.getSupplementsByIds(uniqueIds)
      this.setConnectedSupplement(connSupp.data)
    },
    async submitSuppleAttachments() {
      const payload = {
        reportId: this.report._id,
        supplements: this.selectedSupplements,
        supplementAttachTo: this.suppleAttachTo,
        layout: this.suppleAttachTo === 'Layout' ? this.selectedLayout : false
      }
      console.log('payload :>> ', payload)
      try {
        this.btnLoader = true
        this.toggleLoader()
        const resp = await this.saveAttachSupplements(payload)
        console.log(
          'resp[submitSuppleAttachments] :>> ',
          this.report.layouts[this.selectedLayout.identity],
          resp
        )
        if (resp.ok) {
          this.setReport(resp.result)
          if (this.suppleAttachTo == 'Dashboard Level')
            await this.setReportSupplement()

          if (this.selectedLayout && this.suppleAttachTo == 'Layout') {
            if (
              this.currentLayoutData.identity == this.selectedLayout.identity
            ) {
              this.currentLayoutData.structure.supRefs = JSON.parse(
                JSON.stringify(this.selectedSupplements)
              )
              await this.setLayoutSupplement(this.selectedSupplements)
            } else {
              this.setAllLayoutDataExceptCurrent(resp.result)
            }
          }
          await this.getAllConnectedSupplements()
        }
        this.openSnackbarWithTime({
          txt: resp.ok
            ? 'Supplements Attached Successfully'
            : 'Failed to Attach Supplements',
          time: 3500
        })
        this.emptyBadge()
        this.btnLoader = false
        this.toggleLoader()
      } catch (error) {
        this.btnLoader = false
        this.toggleLoader()
        console.log('error :>> ', error)
        this.openSnackbarWithTime({
          txt: 'Failed to Attach Supplements',
          time: 3500
        })
      }
    },
    prefillSelectedSupplementCheckBoxes() {
      console.log(
        '----- prefillSelectedSupplementCheckBoxes -----',
        this.selectedTab
      )
      // console.log(this.selectedSupplements)
      const selectedLayoutSupRefs =
        this.report.layouts &&
        this.report.layouts[this.selectedLayout.identity] &&
        this.report.layouts[this.selectedLayout.identity].structure &&
        this.report.layouts[this.selectedLayout.identity].structure.supRefs
          ? this.report.layouts[this.selectedLayout.identity].structure.supRefs
          : []
      if (this.suppleAttachTo === 'Dashboard Level') {
        console.log('this.report.supplements :>> ', this.report.supplements)
        this.setSelectedSupplArray(this.report.supplements)
      }
      if (this.suppleAttachTo === 'Layout') {
        console.log('selectedLayoutSupRefs :>> ', selectedLayoutSupRefs)
        this.setSelectedSupplArray(selectedLayoutSupRefs)
      }
      console.log('this.suppleAttachTo :>> ', this.suppleAttachTo)
      this.getAllConnectedSupplements()
    }
  },
  created() {
    this.getAllSuplements({ match: this.filterConf })
    this.getAllRequestedSupplements()
    this.selectedLayout = {
      name: this.currentLayoutData.name,
      identity: this.currentLayoutData.identity
    }
    this.prefillSelectedSupplementCheckBoxes()
  },
  watch: {
    filterText(nv) {
      // console.log(nv, this.selectedTab);
      this.$store.commit('supplementStore/setFilterText', {
        tabId: this.selectedTab,
        value: nv
      })
      if (!nv) this.setFilterText(this.selectedTab)
    },
    currentLayoutData(nv, old) {
      this.selectedLayout = { name: nv.name, identity: nv.identity }
    },
    suppleAttachTo(nv) {
      if (nv) {
        this.prefillSelectedSupplementCheckBoxes()
      }
    },
    selectedTab(nv) {
      this.filterText = ''
      if (nv === 0 || nv === 1) {
        this.setShowOnlyConnectedSupplements(nv === 1 ? false : true)
      }
      // if (nv == 0) this.getAllConnectedSupplements()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  transition: var(--transition);
  background-size: var(--background-size);
}
.btn:hover {
  background-position: var(--background-position);
}
.btn-1 {
  background-image: var(--background-image);
}
.supp-height {
  height: 90vh;
  max-height: 90vh;
}
.request-button {
  background-color: $royal-blue !important;
  color: white;
}
.v-cols-custom {
  height: 48px;
}
</style>

<style lang="scss">
.supp-search {
  max-width: 40%;
  .v-input__slot {
    min-height: 36px !important;
    width: 90%;
  }
  .v-input__prepend-inner {
    margin-top: 8px;
  }
  .v-text-field__slot .v-label {
    top: 8px;
  }
  .v-tabs-items {
    background-color: blue !important;
  }
}
</style>

<style>
.radio-group-custom .v-label {
  font-size: 15px;
}
.radio-group-custom .v-icon {
  font-size: 20px;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: #f8fafd !important;
}
</style>