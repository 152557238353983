<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12">
        <!-- <h4>Position</h4> -->
        <v-radio-group mandatory required column v-model="supplePosition" class="radio-group-custom">
          <v-row>
            <v-col cols="2">
              <v-radio color="#1B3EC4" label="Inline" value="inline"></v-radio>
            </v-col>
            <v-col cols="2">
              <v-radio color="#1B3EC4" label="Modal" value="modal"></v-radio>
            </v-col>
            <v-col cols="2">
              <v-radio color="#1B3EC4" label="Page" value="page"></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
        <v-btn color="#2a51e8" class="ma-2 btn btn-1" dark  @click="submitReportSupplements" :loading="btnLoader">Save</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers as cnh, mapState as mapRootState, mapMutations as mapRootMutations } from 'vuex'
export default {
  data() {
    return {
      supplePosition: '',
    }
  },
  computed: {
     ...mapRootState(["report", "btnLoader", 'reportSupplements']),
  },
  created(){
    this.mapData(this.report)
  },
  methods: {
    submitReportSupplements(){
      const payload = {
        reportId: this.report._id,
        props: {
          ...this.report.props,
          supplePosition: this.supplePosition,
        }
      }
      try {
        this.$emit("updateReportProps", payload)
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    mapData(data){
      this.supplePosition = data.props && data.props.supplePosition ? data.props.supplePosition : ""
    },
  },
}
</script>

<style>
.btn{
  transition: var(--transition);
  background-size: var(--background-size);
}
.btn:hover{
  background-position: var(--background-position);
}
.btn-1{
  background-image: var(--background-image);
}
.radio-group-custom .v-label{
  font-size: 14px !important;
}
</style>