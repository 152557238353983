<template>
  <div>
    <!-- :style="`height: ${window.innerHeight}`" -->
    <v-container fluid grid-list-xs pa-0>
      <v-row wrap no-gutters>
        <v-col :cols="isPreviewEnabled ? 8 : 12">
          <template v-if="filteredSupplements.length">
            <v-container fluid grid-list-xs>
              <v-row wrap>
                <template
                  v-for="(
                    {
                      name,
                      type,
                      creatorNote,
                      filterKeys,
                      _id,
                      configuration,
                      requestId
                    },
                    index
                  ) of filteredSupplements"
                >
                  <v-col
                    :cols="isPreviewEnabled ? 4 : 3"
                    :key="name"
                    v-if="showSupplement(type)"
                    style="min-width: 220px"
                  >
                    <template>
                      <SupplementCard
                        :name="name"
                        :type="type"
                        :requestId="requestId"
                        :filterKeys="filterKeys"
                        :creatorNote="creatorNote"
                        :configuration="configuration"
                      >
                        <template #action>
                          <template v-if="suppSelectMode || selectedTab === 0">
                            <v-checkbox
                              class="ml-3"
                              style="margin-top: 2px !important; height: 30px"
                              v-model="selectedSupplements"
                              @change="checkForIDP(type, $event, _id)"
                              color="indigo"
                              :value="_id"
                            ></v-checkbox>
                          </template>
                          <v-menu open-on-hover offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="menu-buttons-container mt-n1"
                                x-small
                                text
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="blue-grey darken-1">
                                  mdi-dots-vertical</v-icon
                                >
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                text
                                @click="openPreviewSupplement(index)"
                              >
                                <v-list-item-title>Preview</v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                text
                                color="orange darken-1"
                                @click="openAttachedSupStatusDialog(_id, index)"
                              >
                                <v-list-item-title>Info</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>
                        <!-- <template #description
                          ><div style="color: grey">
                            {{ creatorNote }}
                          </div></template
                        > -->
                      </SupplementCard>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-col>
              <p
                v-if="!isFetchingSupplements && !filteredSupplements.length"
                class="mt-2 display-1"
                style="text-align: center"
              >
                <!-- <icon src="assets/Data load.svg" alt="no data available"> -->
                <Icon name="dataloading" class="svgIcons" style="" />
                <br />
                Your Data Might Be Good At Hide And Seek
                <br>
                <v-btn @click="openRequestDialog" class="btn btn-1" depressed color="primary">
                  Request Data First
                </v-btn>
              </p>
              <p
                class="mt-2 loading display-1"
                style="text-align: center"
                v-else
              >
                <!-- <img src="assets/Fetching data.svg" alt="fetching data" //  class="mt-2 loading display-1"> -->
                <Icon name="fetching" class="svgIcons" style="" />
                <br />
                hang on...our robot likes playing fetch
              </p>
            </v-col>
          </template>
          <v-col :cols="isPreviewEnabled ? 8 : 12">
            <!-- {{ suppPaginationConf }} -->
            <v-pagination
              :length="suppPaginationConf.paginationLength"
              v-model="suppPaginationConf.page"
              @input="
                (pageNo) => {
                  fetchFromServer({ page: pageNo })
                }
              "
            />
          </v-col>
        </v-col>
        <v-col style="margin-top: 0.8%" cols="4" v-if="isPreviewEnabled">
          <v-card :style="`height: ${heightDiv}px`" outlined elevation="2">
            <div v-if="selectedSupplement" style="overflow-y: auto">
              <v-card-title style="padding: unset">
                <div title headline class="tabFont" style="padding-left: 10px">
                  {{ selectedSupplement.name }}
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="closePreviewSupplement">
                  <v-icon>mdi mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="overflow-y: auto">
                <div style="height: 70vh">
                  <v-card-text>
                    <SuplementRender :selectedSupplement="selectedSupplement" />
                  </v-card-text>
                </div>
              </v-card-text>
            </div>
            <div v-else style="text-align: center; margin-top: 20px">
              <h3>Select a Supplement for preview...</h3>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <!-- <div v-if="isPreviewEnabled">
        <v-navigation-drawer
          v-model="isPreviewEnabled"
          absolute
          temporary
          right
          class="GOT"
        >
          <template v-slot:prepend>
            <v-list-item two-line>
              <v-list-item-avatar>
                <img src="https://randomuser.me/api/portraits/women/81.jpg" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Jane Smith</v-list-item-title>
                <v-list-item-subtitle>Logged In</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon @click="closePreviewSupplement">x</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </template>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item
              v-for="item in [
                { title: 'Home', icon: 'mdi-home-city' },
                { title: 'My Account', icon: 'mdi-account' },
                { title: 'Users', icon: 'mdi-account-group-outline' }
              ]"
              :key="item.title"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div> -->
    </template>

    <!-- Supplement Attachment Staus Dialog START  -->
    <v-dialog v-model="attachmentStatusDialog" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedSupplement ? selectedSupplement.name : 'NA' }} Status
        </v-card-title>

        <v-card-text>
          <!-- {{ selectedSupplementAttachmentStatus }} -->
          Report:-
          <ul>
            <li
              v-if="
                !selectedSupplementAttachmentStatus ||
                !selectedSupplementAttachmentStatus.report ||
                !selectedSupplementAttachmentStatus.report.length
              "
            >
              NA
            </li>
            <li
              v-else
              v-for="(i, idx) in selectedSupplementAttachmentStatus.report"
              :key="idx"
            >
              {{ i }}
            </li>
          </ul>
          Layouts:-
          <ul>
            <li
              v-if="
                !selectedSupplementAttachmentStatus ||
                !selectedSupplementAttachmentStatus.layouts ||
                !selectedSupplementAttachmentStatus.layouts.length
              "
            >
              NA
            </li>
            <li
              v-else
              v-for="(i, idx) in selectedSupplementAttachmentStatus.layouts"
              :key="idx"
            >
              {{ i }}
            </li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-2"
            text
            @click="attachmentStatusDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Supplement Attachment Staus Dialog END  -->
    <RequestSupplementDialog
      ref="request-supplement-dialog"
      :requestSupplementDialogStatus="requestSupplementDialogStatus"
      @close-dialog="requestSupplementDialogStatus = false"
    />
  </div>
</template>

<script>
import SupplementCard from './SupplementCard.vue'
import SuplementRender from './SuplementRender'
import RequestedSupplements from './RequestedSupplements.vue'

import { createNamespacedHelpers as cnh, mapState as mapRootState } from 'vuex'
const { mapActions, mapGetters, mapState, mapMutations } =
  cnh('supplementStore')
export default {
  props: ['selectedTab'],
  components: {
    SupplementCard,
    SuplementRender,
    RequestedSupplements,
    RequestSupplementDialog: () => import('./RequestSupplementDialog.vue'),
  },
  computed: {
    ...mapGetters(['listOfSupplements', 'suppPaginationConf']),
    ...mapState([
      'isFetchingSupplements',
      'suppSelectMode',
      // 'selectedSupplements',
      'showOnlyConnectedSupplements',
      'suppleAttachTo',
      'initialDefaultParams'
    ]),
    ...mapRootState(['report']),
    filteredSupplements() {
      if (this.suppPaginationConf.filterText) {
        return this.listOfSupplements.filter((el) => {
          return (
            el.name
              .toLowerCase()
              .includes(this.suppPaginationConf.filterText.toLowerCase()) ||
            el.creatorNote
              .toLowerCase()
              .includes(this.suppPaginationConf.filterText.toLowerCase()) ||
            el.type
              .toLowerCase()
              .includes(this.suppPaginationConf.filterText.toLowerCase())
          )
        })
      } else {
        return this.listOfSupplements
      }
    },
    selectedSupplement() {
      return this.listOfSupplements[this.selectedSupplementIndex]
    },
    selectedSupplements: {
      get() {
        return this.$store.state.supplementStore.selectedSupplements
      },
      set(newValue) {
        this.setSelectedSupplArray(newValue)
      }
    },
    connectedSupplementsIds() {
      // report as well as all layouts
      const supplemntConnectedToMapping = {}
      const reportLevelAttachedSupplementsIds = this.report.supplements
        ? this.report.supplements
        : []
      let layoutsLevelSupplementsIds = []
      Object.keys(this.report.layouts || {}).forEach((layoutId) => {
        let suppl =
          this.report.layouts[layoutId].structure &&
          this.report.layouts[layoutId].structure.supRefs
            ? this.report.layouts[layoutId].structure.supRefs
            : []
        layoutsLevelSupplementsIds = [...layoutsLevelSupplementsIds, ...suppl]
      })

      const allIds = [
        ...reportLevelAttachedSupplementsIds,
        ...layoutsLevelSupplementsIds
      ]
      const allUniqueIds = [...new Set(allIds)]

      allUniqueIds.forEach((suppId) => {
        if (!supplemntConnectedToMapping[suppId]) {
          supplemntConnectedToMapping[suppId] = {
            report: [],
            layouts: []
          }
        }
        if (reportLevelAttachedSupplementsIds.includes(suppId)) {
          supplemntConnectedToMapping[suppId]['report'].push(this.report.name)
        }
        Object.keys(this.report.layouts).forEach((layoutId) => {
          let suppl =
            this.report.layouts[layoutId].structure &&
            this.report.layouts[layoutId].structure.supRefs
              ? this.report.layouts[layoutId].structure.supRefs
              : []
          // // NOTE: temporary for junk data @db
          // if (!Array.isArray(suppl)) {
          //   suppl = []
          // }
          if (suppl.includes(suppId)) {
            supplemntConnectedToMapping[suppId]['layouts'].push(
              this.report.layouts[layoutId].name
            )
          }
        })
      })
      this.supplemntConnectedToMapping = supplemntConnectedToMapping
      return allUniqueIds
    }
  },
  data() {
    return {
      isPreviewEnabled: false,
      selectedSupplementIndex: -1,
      supplementsPerPage: 12,
      heightDiv: '80vh',
      testH: 90,
      attachmentStatusDialog: false,
      selectedSupplementAttachmentStatus: {},
      supplemntConnectedToMapping: {},
      requestSupplementDialogStatus: false,
    }
  },
  methods: {
    ...mapActions(['getAllSuplements']),
    ...mapMutations([
      'setSelectedSupplArray',
      'setInitailDefaultParams',
      'setSuppPaginationconf',
      'incrementBadge',
      'decrementBadge'
    ]),
    fetchFromServer({ page }) {
      let payload = {}
      if (this.suppPaginationConf.filterText) {
        payload.match = {}
        payload.match['$or'] = []
        ;['name', 'createNote', 'type'].forEach((col) => {
          payload.match.$or.push({
            [col]: {
              $regex: this.suppPaginationConf.filterText,
              $options: 'i'
            }
          })
        })
      }
      if (page)
        this.setSuppPaginationconf(
          Object.assign(this.suppPaginationConf, { page })
        )
      this.getAllSuplements(payload)
    },
    openPreviewSupplement(index) {
      this.isPreviewEnabled = true
      this.selectedSupplementIndex = index
    },
    closePreviewSupplement() {
      this.isPreviewEnabled = false
      this.selectedSupplementIndex = -1
    },
    openRequestDialog() {
      this.requestSupplementDialogStatus = true
      this.$refs['request-supplement-dialog'].openModal('create')
    },
    openAttachedSupStatusDialog(_id, selectedSupplementIndex = -1) {
      this.selectedSupplementIndex = selectedSupplementIndex
      this.attachmentStatusDialog = true
      this.selectedSupplementAttachmentStatus =
        this.supplemntConnectedToMapping[_id]
    },
    checkForIDP(type, value, val) {
      console.log(val, value)
      if (value.includes(val) && !this.connectedSupplementsIds.includes(val)) {
        console.log('checked')
        this.incrementBadge()
      } else if (
        !value.includes(val) &&
        !this.connectedSupplementsIds.includes(val)
      ) {
        console.log('unchecked')
        this.decrementBadge()
      } else if (
        !value.includes(val) &&
        this.connectedSupplementsIds.includes(val)
      ) {
        console.log('this supplement was attached you are detaching it')
      } else if (
        value.includes(val) &&
        this.connectedSupplementsIds.includes(val)
      ) {
        console.log('this supplement was attached already')
      }
      if (type == 'initialDefaultParams' && value.includes(val)) {
        let IDP = this.listOfSupplements.find((el) => el._id == val)
        if (IDP && IDP.type == 'initialDefaultParams') {
          console.log(
            { type, value, val },
            this.listOfSupplements.find((el) => el._id == val)
          )
          let initialDefaultParamsClone = JSON.parse(
            JSON.stringify(this.initialDefaultParams)
          )
          initialDefaultParamsClone.push(val)
          this.setInitailDefaultParams(initialDefaultParamsClone)
        }
      }
    },
    showSupplement(type) {
      if (this.suppleAttachTo == 'Layout' && type == 'initialDefaultParams') {
        return false
      }
      return true
    }
  },
  created() {
    console.log(
      'this.connectedSupplementsIds :>> ',
      this.connectedSupplementsIds
    )
    this.heightDiv =
      document.documentElement.clientHeight * 0.8 -
      (window.devicePixelRatio % 1) * 100

    this.testH = {
      pixel: window.devicePixelRatio,
      H: document.documentElement.clientHeight
    }
    window.onresize = function (e) {
      var event = window.event || e
      if (typeof window.onzoom === 'function') {
        return window.onzoom.call(window, event)
      }
    }
    window.onzoom = (e) => {
      console.log('zoom')
      this.heightDiv =
        document.documentElement.clientHeight * 0.8 -
        (window.devicePixelRatio % 1) * 100

      this.testH = {
        pixel: window.devicePixelRatio,
        H: document.documentElement.clientHeight
      }
    }

    // 1vh = 19.2
  },
  watch: {
    report(nv) {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.display-1{
  color: gray;
}
.btn {
  transition: var(--transition);
  background-size: var(--background-size);
}
.btn:hover {
  background-position: var(--background-position);
}
.btn-1 {
  background-image: var(--background-image);
}
.menu-buttons-container {
  height: 28px !important;
  width: 27px !important;
  color: $mine-shaft;
}
</style>

</style>