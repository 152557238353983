<template>
  <div>
    <organization-chart
      v-if="Object.keys(layout).length !== 0 && isRootAvailable"
      style="height: 80vh !important"
      :datasource="layout"
    >
      <template slot-scope="{ nodeData }">
        <!-- {{ nodeData.layoutData }} -->
        <div class="container">
        <slot :layout="nodeData.layoutData"></slot>
        <div v-if="a" class="self-drill-arrow">
          <div class="box arrow-left">
            {{ a }}
          </div>
        </div>
        </div>
      </template>
    </organization-chart>
  </div>
</template>

<script>
let id = 1

import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import LayoutCard from './LayoutCard.vue'
import {
  createNamespacedHelpers as cnh,
  mapState as mapRootState,
  mapMutations as mapRootMutations,
  mapActions as mapRootActions
} from 'vuex'

export default {
  components: {
    LayoutCard,
    OrganizationChart
  },
  data() {
    return {
      layout: {},
      isRootAvailable: false,
      a: ''
      // layout: {
      //   id: ++id,
      //   name: 'Monthly Sales Dashboard',
      //   displayName: 'Sales Report',
      //   record: {
      //     updatedOn: new Date()
      //   },
      //   children: [
      //     {
      //       id: ++id,
      //       name: 'Monthly Sales Dashboard',
      //       displayName: 'Sales Report',
      //       record: {
      //         updatedOn: new Date()
      //       },
      //       children: [
      //         {
      //           id: ++id,
      //           name: 'Monthly Sales Dashboard',
      //           displayName: 'Sales Report',
      //           record: {
      //             updatedOn: new Date()
      //           },
      //           description:
      //             'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //         },
      //         {
      //           id: ++id,
      //           name: 'Monthly Sales Dashboard',
      //           displayName: 'Sales Report',
      //           record: {
      //             updatedOn: new Date()
      //           },
      //           description:
      //             'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //         }
      //       ],
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //     },
      //     {
      //       id: ++id,
      //       name: 'Monthly Sales Dashboard',
      //       displayName: 'Sales Report',
      //       record: {
      //         updatedOn: new Date()
      //       },
      //       children: [
      //         {
      //           id: ++id,
      //           name: 'Monthly Sales Dashboard',
      //           displayName: 'Sales Report',
      //           record: {
      //             updatedOn: new Date()
      //           },
      //           description:
      //             'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //         },
      //         {
      //           id: ++id,
      //           name: 'Monthly Sales Dashboard',
      //           displayName: 'Sales Report',
      //           record: {
      //             updatedOn: new Date()
      //           },
      //           description:
      //             'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //         }
      //       ],
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //     },
      //     {
      //       id: ++id,
      //       name: 'Monthly Sales Dashboard',
      //       displayName: 'Sales Report',
      //       record: {
      //         updatedOn: new Date()
      //       },
      //       children: [
      //         {
      //           id: ++id,
      //           name: 'Monthly Sales Dashboard',
      //           displayName: 'Sales Report',
      //           record: {
      //             updatedOn: new Date()
      //           },
      //           description:
      //             'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //         },
      //         {
      //           id: ++id,
      //           name: 'Monthly Sales Dashboard',
      //           displayName: 'Sales Report',
      //           record: {
      //             updatedOn: new Date()
      //           },
      //           description:
      //             'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //         }
      //       ],
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //     },
      //     {
      //       id: ++id,
      //       name: 'Monthly Sales Dashboard',
      //       displayName: 'Sales Report',
      //       record: {
      //         updatedOn: new Date()
      //       },
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //     },
      //     {
      //       id: ++id,
      //       name: 'Monthly Sales Dashboard',
      //       displayName: 'Sales Report',
      //       record: {
      //         updatedOn: new Date()
      //       },
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      //     }
      //   ],
      //   description:
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
      // },
      // ds: {
      //   id: '1',
      //   name: 'Lao Lao',
      //   title: 'general manager',
      //   children: [
      //     { id: '2', name: 'Bo Miao', title: 'department manager' },
      //     {
      //       id: '3',
      //       name: 'Su Miao',
      //       title: 'department manager',
      //       children: [
      //         { id: '4', name: 'Tie Hua', title: 'senior engineer' },
      //         {
      //           id: '5',
      //           name: 'Hei Hei',
      //           title: 'senior engineer',
      //           children: [
      //             { id: '6', name: 'Pang Pang', title: 'engineer' },
      //             { id: '7', name: 'Xiang Xiang', title: 'UE engineer' }
      //           ]
      //         }
      //       ]
      //     },
      //     { id: '8', name: 'Hong Miao', title: 'department manager' },
      //     { id: '9', name: 'Chun Miao', title: 'department manager' }
      //   ]
      // }
    }
  },
  computed: {
    ...mapRootState(['report'])
  },
  created() {
    console.log('Created of Tree Structure', this.report)
    console.log(typeof this.report.layouts)
    // this.layout = this.report.layouts
  },
  methods: {
    // populateLayout() {
    //   const allLayouts =
    //     this.report && this.report.layouts ? this.report.layouts : {}
    //   console.log('allLayouts :>> ', allLayouts)
    //   let payload = {
    //     children: [],
    //     record: {}
    //   }
    //   Object.values(allLayouts).forEach((val) => {
    //     if (val.props && val.props.isRoot) {
    //       payload['id'] = val.identity
    //       payload['name'] = val.name
    //       payload['record'] = { updatedOn: val.updatedOn }
    //       this.isRootAvailable = true
    //     } else {
    //       payload['children'].push({
    //         id: val.identity,
    //         name: val.name,
    //         record: {
    //           updatedOn: val.updatedOn || new Date()
    //         },
    //         children: []
    //       })
    //     }
    //   })
    //   console.log('[payload] :>> ', payload)
    //   if (payload.children.length) {
    //     payload.children.forEach((child) => {
    //       Object.values(allLayouts).forEach((val) => {
    //         if (
    //           val &&
    //           val.identity !== payload.id &&
    //           // val.identity !== child.id &&
    //           val.structure &&
    //           val.structure.containers &&
    //           val.structure.containers instanceof Array &&
    //           val.structure.containers.length !== 0
    //         ) {
    //           val.structure.containers.forEach((c) => {
    //             c.elements.forEach((e) => {
    //               console.log('e :>> ', e)
    //               if (
    //                 e.drill &&
    //                 e.drill.layoutId &&
    //                 e.drill.layoutId === child.id
    //               ) {
    //                 child.children.push({
    //                   id: val.identity,
    //                   name: val.name,
    //                   record: {
    //                     updatedOn: val.updatedOn || new Date()
    //                   },
    //                   children: []
    //                 })
    //               }
    //             })
    //           })
    //         }
    //       })
    //     })
    //     console.log('payload :>> ', payload)
    //   } else {
    //     Object.values(allLayouts).forEach((val) => {
    //       if (
    //         val &&
    //         // val.identity !== payload.id &&
    //         // // val.identity !== child.id &&
    //         val.structure &&
    //         val.structure.containers &&
    //         val.structure.containers instanceof Array &&
    //         val.structure.containers.length !== 0
    //       ) {
    //         val.structure.containers.forEach((c) => {
    //           c.elements.forEach((e) => {
    //             console.log('e :>> ', e)
    //             if (
    //               e.drill &&
    //               e.drill.layoutId &&
    //               e.drill.layoutId === val.identity
    //             ) {
    //               this.a = 'Self Drill'
    //             }
    //           })
    //         })
    //       }
    //     })
    //   }
    //   this.layout = payload
    // },
    populateLayout() {
      const allLayouts =
        this.report && this.report.layouts ? this.report.layouts : {}
      console.log('allLayouts :>> ', allLayouts)
      let payload = {
        children: [],
        record: {}
      }
      Object.values(allLayouts).forEach((val) => {
        if (val.props && val.props.isRoot) {
          payload['id'] = val.identity
          payload['name'] = val.name
          payload['record'] = { updatedOn: val.updatedOn }
          payload['layoutData'] = val
          this.isRootAvailable = true
        } else {
          payload['children'].push({
            id: val.identity,
            name: val.name,
            record: {
              updatedOn: val.updatedOn || new Date()
            },
            children: [],
            layoutData: val
          })
        }
      })
      console.log('[INITIAL PAYLOAD] :>> ', JSON.parse(JSON.stringify(payload)))
      const finalPayload = {}
      if (payload.children.length) {
        payload.children.forEach((child) => {
          finalPayload[child.id] = {
            children: []
          }
          console.log('child.name :>> ', child.name);
          const val = allLayouts[child.id]
          console.log('val :>> ', val);
          // Object.values(allLayouts).forEach((val) => {
            if (
              val &&
              val.identity !== payload.id && // not parent
              // val.identity !== child.id && // not self
              // val.identity !== child.id &&
              val.structure &&
              val.structure.containers &&
              val.structure.containers instanceof Array &&
              val.structure.containers.length !== 0
            ) {

              val.structure.containers.forEach((c) => {
                c.elements.forEach((e) => {
                  // console.log('e :>> ', e);
                  if (
                    e.drill &&
                    e.drill.layoutId
                    //  && e.drill.layoutId === child.id
                  ) {
                    // console.log('e.drill.layoutId :>> ', e.drill.layoutId);
                    // console.log('allLayouts[e.drill.layoutId].name :>> ', allLayouts[e.drill.layoutId].name)
                    console.log('layout :>> ', allLayouts[e.drill.layoutId].name);
                    // console.log('allLayouts[e.drill.layoutId].name :>> ', allLayouts[e.drill.layoutId].name);
                    child.children.push({
                      id: allLayouts[e.drill.layoutId].identity,
                      name: allLayouts[e.drill.layoutId].name,
                      record: {
                        updatedOn: val.updatedOn || new Date()
                      },
                      children: []
                    })
                  }
                })
              })
            }
          // })
        })
        console.log('payload :>> ', payload)
      } else {
        Object.values(allLayouts).forEach((val) => {
          if (
            val &&
            // val.identity !== payload.id &&
            // // val.identity !== child.id &&
            val.structure &&
            val.structure.containers &&
            val.structure.containers instanceof Array &&
            val.structure.containers.length !== 0
          ) {
            val.structure.containers.forEach((c) => {
              c.elements.forEach((e) => {
                console.log('e :>> ', e)
                if (
                  e.drill &&
                  e.drill.layoutId &&
                  e.drill.layoutId === val.identity
                ) {
                  this.a = 'Self Drill'
                }
              })
            })
          }
        })
      }
      this.layout = payload
    }
  },
  watch: {
    report: {
       handler() {
        this.populateLayout();
        this.$forceUpdate();
      },
      immediate: true,
    },
  },
  mounted() {
    console.log('000000000', this.populateLayout())
  }
}
</script>

<style scoped>
.container {
  display: flex;
}
.self-drill-arrow {
    margin-top: 4em;
    height: 75px;
    width: 75px;
    border: 1px solid black;
    border-left: none;
    position: relative;
}
.self-drill-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 8px solid black;
    border-bottom: 4px solid transparent;
    position: absolute;
    left: 0;
    bottom: -4px;
}
.box {
  width: 150px;
  height: 75px;
  background-color: black;
  color: #fff;
  padding: 20px;
  position: relative;
  margin-left: 5.6em;
  float: left;
}
.box.arrow-left:after {
  content: " ";
  position: absolute;
  left: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: 15px solid black;
  border-left: none;
  border-bottom: 15px solid transparent;
}
</style>
