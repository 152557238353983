<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" md="12">
        <v-form ref="form">
           <v-row>
          <v-subheader style="margin-top:10px;">Max Drill Level :</v-subheader>
           <v-col cols="8">
          <v-text-field
            solo
            type="number"
            min="0"
            max="7"
            class="report-max-drill "
            label=""
            v-model="drillLevel"
            onkeyup="if(this.value > 10) this.value = 7;"
          >
          </v-text-field>
           </v-col>
           </v-row>
          <v-checkbox class="mt-0 pt-0 report-checkbox" v-model="allowSharing" label="Allow Sharing"></v-checkbox>
          <v-checkbox
            class="mt-0 pt-0 report-checkbox"
            v-model="allowDownload"
            label="Allow Download"
  
          ></v-checkbox>
          <v-container fluid v-if="allowDownload">
            <v-row class="pa-1 mt-n8">
              <v-col cols="12" sm="2" md="2" class="downloadGrp">
                <v-checkbox
                  v-model="downloads['pdf']"
                  label="PDF"
                  color="red"
                  class="report-checkbox"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="downloadGrp">
                <v-checkbox
                  v-model="downloads['excel']"
                  label="Excel"
                  color="indigo darken-3"
                  class="report-checkbox"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="downloadGrp">
                <v-checkbox
                  v-model="downloads['snapshot']"
                  label="Snapshots"
                  color="orange darken-3"
                  class="report-checkbox"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <v-btn color="#2a51e8" style="text-transform: capitalize;" class="ma-2 btn btn-1" dark  @click="submitReportProperties" :loading="btnLoader">Save</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers as cnh, mapState as mapRootState, mapMutations as mapRootMutations } from 'vuex'

export default {
  components: {  },
  data() {
    return {
      drillLevel: 3,
      allowSharing: false,
      allowDownload: false,
      downloads: {
        pdf: false,
        excel: false,
        snapshot: false
      },
    }
  },
  computed: {
     ...mapRootState(["report", "btnLoader"]),
  },
  created(){
    this.mapData(this.report)
  },
  watch: {
    // report: {
    //   handler: 'mapData',
    //   deep: true,
    // }
  },
  methods: {
    ...mapRootMutations(["openSnackbarWithTime", "toggleLoader"]),
     async submitReportProperties(){
      const payload = {
        reportId: this.report._id,
        props: {
          maxDrillLevel: this.drillLevel,
          allowSharing: this.allowSharing,
          allowDownload: this.allowDownload,
          downloads: this.downloads,
        }
      }
      try {
        this.$emit("updateReportProps", payload)
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    mapData(data){
      this.drillLevel = data.props && data.props.drillLevel ? data.props.drillLevel : 3,
      this.allowSharing = data.props && data.props.allowSharing ? data.props.allowSharing : false,
      this.allowDownload = data.props && data.props.allowDownload ? data.props.allowDownload : false,
      this.downloads = data.props && data.props.downloads ? data.props.downloads : {  pdf: false, excel: false, snapshot: false }
    },
  }
}
</script>

<style scoped>
.btn{
  transition: var(--transition);
  background-size: var(--background-size);
}
.btn:hover{
  background-position: var(--background-position);
}
.btn-1{
  background-image: var(--background-image);
}
.downloadGrp{
  padding: unset !important;
}
</style>
<style>
.report-checkbox .v-label{
  font-size: 14px;
}
.report-max-drill .v-input__slot{
  max-width: 30%;
}
.v-text-field{
      width: 250px;
}
.v-input__slot{
  min-height: 0;
  margin-top: 4px;
}
</style>