<template>
  <v-container class="setting-panel" grid-list-xs fluid>
    <v-row wrap style="overflow: scroll;">
      <v-col cols="12">
        <div class="sticky-bar">
          <!-- tool bar -->
          <v-row class="mt-2 mx-2 mx-2">
            <p style="" class="selectedSettingText">
              {{ selectedSetting }}
            </p>
            <v-spacer />
            <!-- <v-btn
              color="#1B3EC4"
              outlined
              @click="submitElement"
              v-show="isElementSelected"
              >Save</v-btn
            > -->
            <v-tooltip
              bottom
              v-if="isElementSelected && selectedElementData.subType"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  small
                  class="button-size"
                  v-on="on"
                  @click="toggleChartPreview"
                >
                    <v-icon>{{
                      previewData.visibility && chartPreview
                        ? 'mdi-eye-off'
                        : 'mdi-eye'
                    }}</v-icon>
                </v-btn>
              </template>
              <span>Preview</span>
            </v-tooltip>
          </v-row>
          <!-- setting icons -->
          <v-container grid-list-xs fluid >
            <v-row wrap class="elementicon">
              <v-col
                cols="2"
                v-for="(set, setId) of panelSettingOptions"
                :key="set.type + setId"
              >
                <v-tooltip bottom v-if="set.isShow">
                  <template v-slot:activator="{ on, attrs }">
                    <v-sheet
                      v-bind="attrs"
                      v-on="on"
                      v-if="set.isShow"
                      class="pointerClass"
                      :class="
                        selectedSetting == set.type
                          ? 'selected-setting-icon'
                          : ''
                      "
                      color="white"
                      elevation="0"
                      height="42"
                      outlined
                      width="50"
                      rounded
                      @click="validateSettingPanel(set.type)"
                    >
                      <Icon
                        :name="set.iconName"
                        class= "svgIcons"
                        :class="
                          selectedSetting == set.type ? set.className : ''
                        "
                      />
                    </v-sheet>
                  </template>
                  <span>{{ set.text }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
          <v-divider style="margin:10px"></v-divider>
        </div>
        <!--  CONTAINER SETTING -->
        <template v-if="selectedSetting">
          <div
            class="element-setting-container"
            style="height: 65vh; overflow-y: auto"
          >
            <div class="main-content">
              <component v-bind:is="currentTabComponent" />
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { eventBus } from '../../main'
import {
  mapMutations as mapRootMutations,
  createNamespacedHelpers as cnh
} from 'vuex'

const {
  mapState: mapReportBuilderState,
  mapMutations: mapReportBuilderMutations
} = cnh('ReportBuilder')
const {
  mapGetters: ElmentBuilderMapGetters,
  mapState: ElementBuilderMapState
} = cnh('ElmentBuilderStore')

export default {
  components: {
    Container: () => import('./ContainerSetting.vue'),
    Element: () => import('../ElementBuilder/PreElementConfig.vue'),
    Data: () => import('../ElementBuilder//DataSources.vue'),
    Config: () => import('../ElementBuilder/ConfigurationBuilder.vue'),
    Drill: () => import('../ElementBuilder/Drill.vue'),
    chartConfig: () => import('../../elements-components/Graphs/ChartMaker.vue')
  },
  data() {
    return {
      selectedSettingVal: '',
      panelSettingOptions: [],
      previewData: {},
      chartPreview: false
    }
  },
  computed: {
    ...mapReportBuilderState([
      'selectedContainerIValue',
      'selectedContainerElementIndex',
      'currentLayoutData',
      'selectedElementData',
      'selectedElementComponentPreview'
    ]),
    ...ElementBuilderMapState(['isDisplayName']),
    ...ElmentBuilderMapGetters(['selectedElement']),
    isContainerSelected() {
      return (
        this.selectedContainerIValue > -1 &&
        this.selectedContainerElementIndex == -1
      )
    },
    isElementSelected() {
      return (
        this.selectedContainerIValue > -1 &&
        this.selectedContainerElementIndex > -1
      )
    },
    selectedSetting() {
      // console.log(
      //   this.selectedContainerIValue,
      //   this.selectedContainerElementIndex
      // )
      this.setPanelSettingOptions()
      if (this.isElementSelected && !this.selectedSettingVal) {
        return 'Element'
      } else if (this.isContainerSelected) {
        return 'Container'
      }
      if (this.isElementSelected) return this.selectedSettingVal
    },
    isDataSourcesAdded() {
      return (
        !!this.selectedElement.dataSource &&
        !!this.selectedElement.dataSource.headers &&
        !!this.selectedElement.dataSource.headers.length
      )
    },
    currentTabComponent() {
      // if (
      //   this.selectedElement.type == 'graph' &&
      //   this.selectedSetting == 'Config'
      // ) {
      //   return 'chartConfig'
      // } else {

      // }
      return this.selectedSetting
    }
  },
  methods: {
    ...mapRootMutations(['openSnackbarWithTime', 'toggleLoader']),
    ...mapReportBuilderMutations([
      'updateElementDataInAllElementsInAReport',
      'updateElemDataInsideContainerArraysElem',
      'setSelectedElementComponentPreview'
    ]),
    validateSettingPanel(value) {
      try {
        this.selectedSettingVal = value
        // if (!this.selectedElement.name) {
        //   // console.log("*************************");
        //   eventBus.$emit('checkDisplayName')
        //   this.selectedSettingVal = value
        // } else {
        // }
        //  if(!this.isDisplayName) eventBus.$emit('checkDisplayName')
        // if(!this.isDisplayName) return
        // this.selectedSettingVal = value
      } catch (error) {
        console.log(error)
      }
    },
    resetElementComponentPreview() {
      this.setSelectedElementComponentPreview({ visibility: false })
    },
    async previewComponent() {
      try {
        // require("../../elements-components")
        let compPath = `../../elements-components/${this.selectedElement.type.toLowerCase()}/${
          this.selectedElement.subType
        }.vue`
        console.log('@@@@', compPath)
        // await import('../../elements-components/number/multiple_number.vue')
        // return () =>
        //   import('../../elements-components/number/multiple_number.vue')
        if (this.selectedElement.type == 'graph') {
          return () =>
            import(`../../elements-components/Graphs/ChartPreview.vue`)
        }
        await import(
          `../../elements-components/${this.selectedElement.type.toLowerCase()}/${
            this.selectedElement.subType
          }.vue`
        )
        return () =>
          import(
            `../../elements-components/${this.selectedElement.type.toLowerCase()}/${
              this.selectedElement.subType
            }.vue`
          )
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async toggleChartPreview() {
      console.log('preview', this.selectedElement)
      this.chartPreview = !this.chartPreview
      if (
        this.selectedElement._id == this.previewData.id &&
        !this.chartPreview
      ) {
        this.resetElementComponentPreview()
      } else {
        this.resetElementComponentPreview()
        // let this.previewData = {}
        this.previewData.id = this.selectedElement._id.toString()
        if (this.selectedElement.configuration) {
          // let comp = await this.previewComponent()
          // if (comp) {
          // this.previewData.componentFn = comp
          this.previewData.visibility = true
          this.previewData.configuration = JSON.parse(
            JSON.stringify(this.selectedElementData.configuration)
          )
          // }
        } else {
          this.previewData.message = 'No Configuration Provided'
        }
        this.setSelectedElementComponentPreview(this.previewData)
      }
    },
    setPanelSettingOptions() {
      if (this.isContainerSelected) {
        this.panelSettingOptions = [
          { type: 'Container', iconName: 'Add container' }
        ]
      } else if (this.isElementSelected) {
        this.panelSettingOptions = [
          {
            type: 'Element',
            text: 'Select Element',
            iconName: 'Side panel Add Element Icon',
            isShow: true,
            className: 'selected-setting-icon',
  
          },
          {
            type: 'Data',
            text: 'Add Data',
            iconName: 'folder-with-rounded-sides',
            isShow: !!this.selectedElement.type,
            className: 'selected-setting-icon'
            // && !!this.selectedElement.name
          },
          {
            type: 'Config',
            text: 'Edit Element Configuration',
            iconName: 'edit',
            isShow:
              (!!this.selectedElement.dataSource &&
                !!this.selectedElement.dataSource.headers &&
                !!this.selectedElement.dataSource.headers.length &&
                !!this.selectedElement.dataSource.headers[0].header) ||
              this.selectedElement.type == 'html',
            className: 'selected-setting-icon-stroke'
            // && this.selectedElement.type != 'graph'
          },
          {
            type: 'Drill',
            text: 'Drill',
            iconName: 'Drill',
            isShow: this.selectedElement.type == 'table',
            className: 'selected-setting-icon'
          }
        ]
      } else {
        this.panelSettingOptions = []
      }

      // console.log(this.panelSettingOptions, this.selectedElement)
      // this.$forceUpdate()
    },
    async submitElement_discarded() {
      console.log('submitted', this.selectedContainerElementIndex)
      let selectedElement = this.$store.state.ElmentBuilderStore.selectedElement
      let report = this.$store.state.report
      // console.log({ selectedElement, report })
      const payload = {
        ...selectedElement
      }
      console.log(`[selectedElement]: `, selectedElement)
      let res = { ok: true, issave: false, message: '' }
      if (this.selectedElementData.isNewElem) {
        delete payload.isNewElem
        delete payload.isSaved
        payload.owner_report_info = {
          reportId: report._id,
          name: report.name,
          displayName: report.displayName,
          layoutId: this.currentLayoutData.identity
        }
        this.toggleLoader()
        res = await this.$store.dispatch(
          'ElmentBuilderStore/createElement',
          payload
        )
        if (res.ok) {
          res.issave = true
          res.message = 'Created Successfully'
        }
      } else if (
        this.selectedElementData.hasOwnProperty('isSaved') &&
        !this.selectedElementData.isSaved
      ) {
        this.toggleLoader()
        res = await this.$store.dispatch(
          'ElmentBuilderStore/updateElement',
          payload
        )
        if (res.ok) {
          res.issave = true
          res.message = 'Updated Successfully'
        }
      }
      console.log(' Response :>> ', res)
      if (!res.issave && res.ok) {
        this.openSnackbarWithTime({
          txt: 'Nothing changed',
          time: 3500
        })
        // this.toggleLoader()
        return
      }
      if (!res.ok) {
        console.log('some error')
        this.openSnackbarWithTime({
          txt: res.message,
          time: 3500
        })
        this.toggleLoader()
        return
      }
      this.updateElementDataInAllElementsInAReport({
        element: res.element,
        selectedElementDataIndex: this.selectedContainerElementIndex,
        containerIValue: this.selectedContainerIValue
      })
      this.updateElemDataInsideContainerArraysElem({
        element: res.element,
        selectedContainerIndex: this.selectedContainerIValue,
        selectedElementIndex: this.selectedContainerElementIndex
      })
      this.openSnackbarWithTime({
        txt: res.message || '',
        time: 3500
      })
      this.$store.commit('ElmentBuilderStore/setSelectedElementsProps', {
        prop: 'isNewElem',
        value: false
      })
      this.$store.commit('ElmentBuilderStore/setSelectedElementsProps', {
        prop: 'isSaved',
        value: true
      })
      this.toggleLoader()
    }
  },
  watch: {
    selectedContainerElementIndex(NV) {
      console.log('%c selectedContainerElementIndex', 'color:cyan;')
      this.resetElementComponentPreview()
      if (this.selectedSettingVal) {
        if (!this.panelSettingOptions.includes(this.selectedSettingVal))
          this.selectedSettingVal = ''
      }
      if (this.isElementSelected) {
        // this.$store.commit('ElmentBuilderStore/setDisplayNameBoolean', false)
        // console.log(this.isDisplayName)
        this.$store.commit(
          'ElmentBuilderStore/setSelectedElement',
          this.$store.state.ReportBuilder.selectedElementData
        )
      }
    },
    selectedElementData() {
      console.log('setting-panel')
      this.previewData = {}
    }
  }
}
</script>

<style scoped lang="scss">
.setting-panel {
  background-color: $white;
  max-height: 80vh;
  min-height: 80vh;
  // overflow-y: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pointerClass {
  // border: 1px solid rgb(211 211 211 / 98%) !important;
}

.selectedSettingText {
  color: $royal-blue;
}
.selected-setting-icon {
  // background-color: $royal-blue !important;
  fill: $royal-blue;
}
.selected-setting-icon-stroke {
  // background-color: $royal-blue !important;
  fill: $royal-blue;
  
}

.svgIcons {
 margin:8px;
}

.pointerClass {
  cursor: pointer;
}
.sticky-bar {
  position: sticky;
  top: -8px;
  background: #fff;
  z-index: 99;
}
.elementicon{
  display: flex;
  justify-content: center;
}
</style>