var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MAIN-COMPOSER"},[_c('v-toolbar',{staticClass:"toolbarWrapper",attrs:{"elevation":"0","dense":""}},[_c('v-toolbar-title',{staticClass:"overrideTitle"},[_vm._v(" "+_vm._s(_vm.report && Object.keys(_vm.report).length ? _vm.report.displayName : 'Fetching...')+" ")]),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5 mr-2",attrs:{"outlined":"","fab":"","small":"","color":"royal-blue"}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"20px"}},[_vm._v("mdi-file-document-outline")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('div',{staticClass:"modalHeader"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.report.displayName)+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"pink","text":""},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-tabs',{staticClass:"v-tab-color",model:{value:(_vm.currentReportTab),callback:function ($$v) {_vm.currentReportTab=$$v},expression:"currentReportTab"}},[_c('v-tab',{staticClass:"ml-2"},[_vm._v(" Report ")]),_c('v-tab',[_vm._v(" Supplements ")]),_c('v-tab',[_vm._v(" Themes ")])],1),_c('v-tabs-items',{model:{value:(_vm.currentReportTab),callback:function ($$v) {_vm.currentReportTab=$$v},expression:"currentReportTab"}},[_c('v-tab-item',[_c('report',{on:{"updateReportProps":_vm.updateReportProps}})],1),_c('v-tab-item',[_c('report-supplements',{on:{"updateReportProps":_vm.updateReportProps}})],1),_c('v-tab-item',[_c('report-themes',{on:{"updateReportProps":_vm.updateReportProps}})],1)],1)],1)]}}])}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","fab":"","color":"royal-blue","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"20px"}},[_vm._v("mdi-format-list-bulleted-square")])],1)]}}])},[_c('v-list',[_vm._l((_vm.reportMenuItems),function(item,i){return _c('v-list-item',{key:i + item.key,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.submitReportMenuOption(item.key)}}},[_vm._v(_vm._s(item.title))])],1)}),_c('div',{staticClass:"text-xs-center"},[_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.showPublishedHistoryDialog),callback:function ($$v) {_vm.showPublishedHistoryDialog=$$v},expression:"showPublishedHistoryDialog"}},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":"","text":"","color":"pink"},on:{"click":function($event){_vm.showPublishedHistoryDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.showToolBarTitle))])],1),_c('v-card',[_c('v-card-text',[(_vm.reportPublishUnPublishHistory.length === 0)?_c('v-alert',{staticStyle:{"margin":"0 10px 0 10px"},attrs:{"dense":"","outlined":"","type":"error"}},[_c('strong',[_vm._v(_vm._s(_vm.errorText))])]):_vm._e(),(_vm.reportPublishUnPublishHistory.length !== 0)?[_c('v-timeline',{attrs:{"dense":"","align-top":""}},_vm._l((_vm.reportPublishUnPublishHistory),function(history,i){return _c('v-timeline-item',{key:i,attrs:{"color":("" + (history.type == 'publish'
                          ? 'indigo'
                          : history.type === 'unpublish'
                          ? 'red lighten-1'
                          : 'purple lighten-1')),"icon":("" + (history.type == 'publish'
                          ? 'mdi-buffer'
                          : history.type === 'unpublish'
                          ? 'mdi-book-variant'
                          : 'mdi-buffer')),"fill-dot":""}},[_c('v-card',{attrs:{"color":("" + (history.type == 'publish'
                            ? 'indigo'
                            : history.type === 'unpublish'
                            ? 'red lighten-1'
                            : 'purple lighten-1')),"dark":""}},[_c('v-card-title',[_vm._v(_vm._s(new Date(history.record.createdOn)))]),_c('v-card-text',{staticClass:"white",staticStyle:{"color":"black"}},[_c('span',[_vm._v("Revision: "+_vm._s(history.revision))]),_vm._v(" "),_c('br'),_c('span',{staticClass:"text-capitalize"},[_vm._v("Type: "+_vm._s(history.type))])])],1)],1)}),1)]:_vm._e()],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showPublishedHistoryDialog = false}}},[_vm._v(" Ok ")])],1)],1)],1)],1)],2)],1),_c('v-tabs',{staticClass:"ml-5 pl-5 v-tab-ovverride",attrs:{"active-class":"active-tab","slider-size":"0"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',[_vm._v(" Composer ")]),_c('v-tab',[_vm._v(" Layouts ")]),_c('v-tab',[_vm._v(" Supplement ")])],1),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"#1B3EC4","small":""},on:{"click":_vm.saveLayout}},[_c('div',{staticClass:"round-btn-wrapper"},[_c('span',{staticClass:"dot",style:(("color: " + (_vm.isAllElemsAndCurrentLayoutSaved &&
            _vm.$store.state.ReportBuilder.isLayoutSave
              ? '#4af30e'
              : '#f52222')))},[_vm._v("●")]),_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.isAllElemsAndCurrentLayoutSaved && _vm.$store.state.ReportBuilder.isLayoutSave ? 'Saved' : 'Unsaved')+" ")])])])],1),_c('v-divider'),_c('v-tabs-items',{staticStyle:{"background-color":"#f8fafd"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{staticStyle:{"background-color":"#f8fafd"}},[(!_vm.layoutSelection)?[_c('ReportBuilder',{ref:"reportBuilder",on:{"previewSelectedLayout":_vm.previewSelectedLayout}})]:_vm._e(),(_vm.layoutSelection)?[_c('PredefinedLayouts',{on:{"manageLayout":_vm.manageLayout,"cancelLayoutSelection":_vm.cancelLayoutSelection}})]:_vm._e()],2),_c('v-tab-item',[_c('Layouts',{on:{"openPredefinedLayouts":_vm.openPredefinedLayouts,"manageLayout":_vm.manageLayout,"previewSelectedLayout":_vm.previewSelectedLayout}})],1),_c('v-tab-item',[_c('Supplements')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }