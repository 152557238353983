var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":"","grid-list-xs":"","pa-0":""}},[_c('v-row',{attrs:{"wrap":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.isPreviewEnabled ? 8 : 12}},[(_vm.filteredSupplements.length)?[_c('v-container',{attrs:{"fluid":"","grid-list-xs":""}},[_c('v-row',{attrs:{"wrap":""}},[_vm._l((_vm.filteredSupplements),function(ref,index){
                  var name = ref.name;
                  var type = ref.type;
                  var creatorNote = ref.creatorNote;
                  var filterKeys = ref.filterKeys;
                  var _id = ref._id;
                  var configuration = ref.configuration;
                  var requestId = ref.requestId;
return [(_vm.showSupplement(type))?_c('v-col',{key:name,staticStyle:{"min-width":"220px"},attrs:{"cols":_vm.isPreviewEnabled ? 4 : 3}},[[_c('SupplementCard',{attrs:{"name":name,"type":type,"requestId":requestId,"filterKeys":filterKeys,"creatorNote":creatorNote,"configuration":configuration},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.suppSelectMode || _vm.selectedTab === 0)?[_c('v-checkbox',{staticClass:"ml-3",staticStyle:{"margin-top":"2px !important","height":"30px"},attrs:{"color":"indigo","value":_id},on:{"change":function($event){return _vm.checkForIDP(type, $event, _id)}},model:{value:(_vm.selectedSupplements),callback:function ($$v) {_vm.selectedSupplements=$$v},expression:"selectedSupplements"}})]:_vm._e(),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-buttons-container mt-n1",attrs:{"x-small":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue-grey darken-1"}},[_vm._v(" mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"text":""},on:{"click":function($event){return _vm.openPreviewSupplement(index)}}},[_c('v-list-item-title',[_vm._v("Preview")])],1),_c('v-list-item',{attrs:{"text":"","color":"orange darken-1"},on:{"click":function($event){return _vm.openAttachedSupStatusDialog(_id, index)}}},[_c('v-list-item-title',[_vm._v("Info")])],1)],1)],1)]},proxy:true}],null,true)})]],2):_vm._e()]})],2)],1)]:[_c('v-col',[(!_vm.isFetchingSupplements && !_vm.filteredSupplements.length)?_c('p',{staticClass:"mt-2 display-1",staticStyle:{"text-align":"center"}},[_c('Icon',{staticClass:"svgIcons",attrs:{"name":"dataloading"}}),_c('br'),_vm._v(" Your Data Might Be Good At Hide And Seek "),_c('br'),_c('v-btn',{staticClass:"btn btn-1",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.openRequestDialog}},[_vm._v(" Request Data First ")])],1):_c('p',{staticClass:"mt-2 loading display-1",staticStyle:{"text-align":"center"}},[_c('Icon',{staticClass:"svgIcons",attrs:{"name":"fetching"}}),_c('br'),_vm._v(" hang on...our robot likes playing fetch ")],1)])],_c('v-col',{attrs:{"cols":_vm.isPreviewEnabled ? 8 : 12}},[_c('v-pagination',{attrs:{"length":_vm.suppPaginationConf.paginationLength},on:{"input":function (pageNo) {
                _vm.fetchFromServer({ page: pageNo })
              }},model:{value:(_vm.suppPaginationConf.page),callback:function ($$v) {_vm.$set(_vm.suppPaginationConf, "page", $$v)},expression:"suppPaginationConf.page"}})],1)],2),(_vm.isPreviewEnabled)?_c('v-col',{staticStyle:{"margin-top":"0.8%"},attrs:{"cols":"4"}},[_c('v-card',{style:(("height: " + _vm.heightDiv + "px")),attrs:{"outlined":"","elevation":"2"}},[(_vm.selectedSupplement)?_c('div',{staticStyle:{"overflow-y":"auto"}},[_c('v-card-title',{staticStyle:{"padding":"unset"}},[_c('div',{staticClass:"tabFont",staticStyle:{"padding-left":"10px"},attrs:{"title":"","headline":""}},[_vm._v(" "+_vm._s(_vm.selectedSupplement.name)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closePreviewSupplement}},[_c('v-icon',[_vm._v("mdi mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"overflow-y":"auto"}},[_c('div',{staticStyle:{"height":"70vh"}},[_c('v-card-text',[_c('SuplementRender',{attrs:{"selectedSupplement":_vm.selectedSupplement}})],1)],1)])],1):_c('div',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('h3',[_vm._v("Select a Supplement for preview...")])])])],1):_vm._e()],1)],1),void 0,_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.attachmentStatusDialog),callback:function ($$v) {_vm.attachmentStatusDialog=$$v},expression:"attachmentStatusDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.selectedSupplement ? _vm.selectedSupplement.name : 'NA')+" Status ")]),_c('v-card-text',[_vm._v(" Report:- "),_c('ul',[(
              !_vm.selectedSupplementAttachmentStatus ||
              !_vm.selectedSupplementAttachmentStatus.report ||
              !_vm.selectedSupplementAttachmentStatus.report.length
            )?_c('li',[_vm._v(" NA ")]):_vm._l((_vm.selectedSupplementAttachmentStatus.report),function(i,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(i)+" ")])})],2),_vm._v(" Layouts:- "),_c('ul',[(
              !_vm.selectedSupplementAttachmentStatus ||
              !_vm.selectedSupplementAttachmentStatus.layouts ||
              !_vm.selectedSupplementAttachmentStatus.layouts.length
            )?_c('li',[_vm._v(" NA ")]):_vm._l((_vm.selectedSupplementAttachmentStatus.layouts),function(i,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(i)+" ")])})],2)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-2","text":""},on:{"click":function($event){_vm.attachmentStatusDialog = false}}},[_vm._v(" Ok ")])],1)],1)],1),_c('RequestSupplementDialog',{ref:"request-supplement-dialog",attrs:{"requestSupplementDialogStatus":_vm.requestSupplementDialogStatus},on:{"close-dialog":function($event){_vm.requestSupplementDialogStatus = false}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }