<template>
  <v-container grid-list-xs fluid class="pa-3 action-bar container-shadow">
    <v-row wrap dense>
      <v-col cols="2">
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-if="layoutNameEdit"
            style="background-color: #fff"
            outlined
            label="Layout Name"
            hide-details="auto"
            dense
            class="MNUYTR"
            ref="layoutNameValidation"
            :rules="layoutNameRules"
            v-model="layoutName"
            @change="onLayoutNameChange"
          ></v-text-field>
          <label v-else class="layoutTitle" @click="layoutNameEdit = true">
            {{ layoutName }}
            <v-btn style="margin: 5px" fab x-small class="my-2" elevation="0">
              <v-icon style="color: grey">mdi-pencil</v-icon>
            </v-btn>
          </label>
        </v-form>
      </v-col>
      <v-col cols="1">
        <!-- <v-btn color="#2a51e8" :disabled="isDummyEcEFSMapperError"  style="color: #fff; margin-top: 1px" @click="saveLayout" -->
         <v-btn
            class="btn btn-1"
            v-if="layoutNameEdit"
            color="#2a51e8"
            :disabled="isDummyEcEFSMapperError"
            small
            style="color: #fff; margin-top: 10px"
            @click="saveLayout"
            >Save</v-btn>
        <!-- <v-icon @click="saveLayout" style="font-size: 40px; color: rgb(65, 105, 225, 0.8)">mdi-content-save-outline</v-icon> -->
      </v-col>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title primary-title>INFO</v-card-title>
          <v-card-text>Please specify name for the Layout</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">Okay</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="3">
        <div class="d-flex">
          <div
            class="text-center"
            wrap
            style="height: 40px; overflow: auto"
            v-if="supplementsParams.length"
          >
            <v-chip
              v-for="(supplParam, idx) of new Set(supplementsParams)"
              :key="idx"
              class="ma-1"
              :color="`${getSupplementParamColor(idx)}`"
              text-color="white"
            >
              {{ supplParam }}
            </v-chip>
          </div>
          <div
            class="text-center"
            v-if="
              supplementsParams.length ||
              (outBoundParams && Object.keys(outBoundParams).length)
            "
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="400"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon color="indigo" v-bind="attrs" v-on="on">
                  <v-icon>mdi-arrow-down-thick</v-icon>
                </v-btn>
              </template>

              <v-card width="500">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>Supplement Management</span>
                        <v-btn
                          small
                          text
                          @click="menu = false"
                          style="float: right"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list class="in-bound-params">
                  <!-- <v-list-item-title style="padding-left: 10px">
                    In Bound Params
                  </v-list-item-title> -->
                  <v-list-item v-if="reportSupplements.length">
                    Report Params:
                    <template v-for="(reportSupp, rptIdx) of reportSupplements">
                      <v-chip
                        small
                        :key="rptIdx + fIdx"
                        v-for="(filterKey, fIdx) of reportSupp.filterKeys"
                        >{{ filterKey }}</v-chip
                      >
                    </template>
                  </v-list-item>
                  <div v-if="inBoundParams.length">
                    <v-list-item
                      style=""
                      v-for="(supp, idx) in inBoundParams"
                      :key="supp.name + idx"
                    >
                      <!-- <v-list-item-content> -->

                      <!-- <template> -->
                      <v-list-group
                        :value="true"
                        no-action
                        class="sub-group"
                        color=""
                        style="width: 100%; color: black"
                      >
                        <template v-slot:activator>
                          <v-list-item-title
                            style="text-transform: capitalize"
                            >{{
                              supp.name.replace(/_/g, ' ')
                            }}</v-list-item-title
                          >
                        </template>
                        <v-list-item
                          v-for="(filterkey, i) in supp.filterKeys"
                          :key="i"
                          link
                        >
                          <v-list-item-title>
                            <span>{{ filterkey }} </span
                            ><v-icon
                              v-if="getDepenedentParamsList(supp, filterkey)"
                              >mdi-keyboard-backspace</v-icon
                            >
                            <template
                              v-for="(depen, idx) of getDepenedentParamsList(
                                supp,
                                filterkey
                              )"
                            >
                              <v-chip
                                :key="idx + depen"
                                style="margin-right: 2px"
                                >{{ depen }}</v-chip
                              >
                            </template>
                          </v-list-item-title>

                          <!-- <v-list-item-icon>
                                <v-icon v-text="icon"></v-icon>
                              </v-list-item-icon> -->
                        </v-list-item>
                      </v-list-group>
                      <!-- </template> -->
                      <!-- <v-list-item-title
                        v-for="(supp, idx) in inBoundParams"
                        :key="idx"
                      >
                        {{ supp }}
                      </v-list-item-title> -->
                      <!-- </v-list-item-content> -->
                      <!-- <v-list-item-title
                      v-for="(supp, idx) in reportSupplements"
                      :key="idx + supp._id"
                    >
                      {{ supp }}
                    </v-list-item-title> -->
                    </v-list-item>
                  </div>
                </v-list>
                <v-divider></v-divider>
                <v-list
                  class="out-bound-params"
                  v-if="outBoundParams && Object.keys(outBoundParams).length"
                >
                  <v-list-item-title style="padding-left: 10px">
                    <span> Drill </span>
                  </v-list-item-title>
                  <v-list-item
                    style="padding: unset"
                    v-for="(outsupp, layoutId) in outBoundParams"
                    :key="outsupp.name + layoutId"
                  >
                    <v-list-group
                      v-if="outsupp.length"
                      :value="true"
                      no-action
                      class="sub-group"
                      color=""
                      style="width: 100%; color: black"
                    >
                      <template v-slot:activator>
                        <v-list-item-content style="">
                          <v-list-item-title>
                            {{ allAvailableLayoutsItems[layoutId] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="(drill, dIdx) of outsupp"
                        :key="dIdx + drill.elementId"
                        style="padding-left: 30px"
                      >
                        <OutBoundParamsMapper
                          :drillConf="drill"
                          :drillLayoutId="layoutId"
                          :dIdx="dIdx"
                        />
                      </v-list-item>
                    </v-list-group>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </v-col>
      <v-spacer></v-spacer>

      <div style="float: right">
        <v-col cols="3" class="d-flex flex-row" style="padding: unset">
          <template name="undo-redo">
            <div class="undo-redo d-flex flex-row mr-2 pt-2">
              <!-- undo -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      :disabled="!isUndoAllow || isDummyEcEFSMapperError"
                      class="tool-sheet"
                      height="30"
                      min-width="30"
                      text
                      @click="undoLayoutState"
                    >
                      <Icon
                        name="undo"
                        class="tool-icon"
                        :style="!isUndoAllow ? 'fill: #c4c4c4' : ''"
                      />
                    </v-btn>
                  </div>
                </template>
                <span>Undo</span>
              </v-tooltip>

              <!-- redo -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      :disabled="!isRedoAllowed || isDummyEcEFSMapperError"
                      class="tool-sheet"
                      height="30"
                      min-width="30"
                      text
                      @click="redoLayoutState"
                    >
                      <Icon
                        name="redo"
                        class="tool-icon"
                        :style="!isRedoAllowed ? 'fill: #c4c4c4' : ''"
                      />
                    </v-btn>
                  </div>
                </template>
                <span>Redo</span>
              </v-tooltip>
            </div>
          </template>

          <template name="combination-actions">
            <v-btn-toggle
              class="combine-actions d-flex flex-row"
              color="primary"
            >
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="isDummyEcEFSMapperError"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      $emit(
                        'previewLayout',
                        (!!$store.state.ReportBuilder.layout.length &&
                          $store.state.ReportBuilder.layout) ||
                          (currentLayoutData.structure &&
                            currentLayoutData.structure.containers) ||
                          []
                      )
                    "
                  >
                    <Icon
                      name="Preview"
                      tooltipText="Preview"
                      class="tool-icon"
                    />
                  </v-btn>
                </template>
                <span>Preview</span>
              </v-tooltip> -->

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!-- addItem -->
                  <v-btn
                    :disabled="isDummyEcEFSMapperError"
                    v-bind="attrs"
                    v-on="on"
                    @click="addItem"
                  >
                    <Icon
                      name="Add container"
                      tooltipText="Add Container/Box"
                      class="tool-icon"
                    />
                  </v-btn>
                </template>
                <span>Add container</span>
              </v-tooltip>
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="isDummyEcEFSMapperError"
                    v-bind="attrs"
                    v-on="on"
                    @click="discardChanges"
                  >
                    <Icon
                      name="discard"
                      style="width: 50px !important"
                      tooltipText="Discard changes"
                      class="tool-icon"
                    />
                  </v-btn>
                </template>
                <span>Discard changes</span>
              </v-tooltip> -->

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!-- addElement -->
                  <v-btn
                    :disabled="isDummyEcEFSMapperError"
                    v-bind="attrs"
                    v-on="on"
                    @click="addElement"
                    :class="[!isContainerSelected ? 'tool-sheet-disabled' : '']"
                  >
                    <Icon
                      name="Add Element"
                      tooltipText="Add Element"
                      class="tool-icon"
                    />
                  </v-btn>
                </template>
                <span>Add Element</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!-- cloneContainer -->
                  <v-btn
                    :disabled="isDummyEcEFSMapperError"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="cloneContainer"
                    :class="[!isContainerSelected ? 'tool-sheet-disabled' : '']"
                  >
                    <Icon name="copy" class="tool-icon" />
                  </v-btn>
                </template>
                <span>Clone Container/Box</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!-- openArrangeChildrenDialog -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="openArrangeChildrenDialog"
                    :class="[!isContainerSelected ? 'tool-sheet-disabled' : '']"
                  >
                    <Icon
                      name="Increment Height"
                      tooltipText="Re-arrange Elements"
                      class="tool-icon"
                    />
                  </v-btn>
                </template>
                <span>Re-arrange Elements</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!-- removeItem -->
                  <v-btn
                    :disabled="isDummyEcEFSMapperError"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="removeItem"
                    :class="[!isContainerSelected ? 'tool-sheet-disabled' : '']"
                  >
                    <Icon
                      name="trash-bin"
                      tooltipText="Delete Container/Box"
                      class="tool-icon"
                    />
                  </v-btn>
                </template>
                <span>Delete Container/Box</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!-- toggleInContainerElementPreview -->
                  <v-btn
                    :disabled="isDummyEcEFSMapperError"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleInContainerElementPreview"
                  >
                    <v-icon>{{
                      inContainerElementPreview ? 'mdi-eye-off' : 'mdi-eye'
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>show Elements Preview</span>
              </v-tooltip>
            </v-btn-toggle>
          </template>
        </v-col>
      </div>
    </v-row>
    <ArrangeChildrenDialog
      :arrangeChildrenDialogStatus="arrangeChildrenDialogStatus"
      @close-arrange-children-dialog="arrangeChildrenDialogStatus = false"
    />
  </v-container>
</template>

<script>
import ArrangeChildrenDialog from './ArrangeChildrenDialog.vue'
import { cloneDeep, isEqual } from 'lodash'
import { ObjectID } from 'bson'
import {
  createNamespacedHelpers as cnh,
  mapState as mapRootState,
  mapMutations as mapRootMutations,
  mapActions as mapRootActions
} from 'vuex'
const {
  mapState: mapReportBuilderState,
  mapGetters: mapReportBuilderGetters,
  mapMutations: mapReportBuilderMutations,
  mapActions: mapReportBuilderActions
} = cnh('ReportBuilder')
const { mapActions: mapElemBuilderActions, mapState: mapElemBuilderState } =
  cnh('ElmentBuilderStore')
export default {
  components: {
    ArrangeChildrenDialog,
    OutBoundParamsMapper: () => import('./OutBoundParamsMapper.vue')
  },
  created() {
    // If prefilled with layout, set index value accordingly
    // if (this.layout.length) {
    //   let max = 0
    //   this.layout.forEach((l) => {
    //     if (l.i > max) max = l.i
    //   })
    //   this.index = max + 1
    // }
    this.setSupplParams()
  },
  computed: {
    ...mapElemBuilderState(['isDummyEcEFSMapperError']),
    ...mapRootState(['report', 'reportSupplements']),
    ...mapReportBuilderState([
      'isAllElemsAndCurrentLayoutSaved',
      'layout',
      'selectedContainerIValue',
      'selectedContainerElementIndex',
      'currentLayoutData',
      'layoutName',
      'currentLayoutsElementRefs',
      'allElementsOfACurrentLayoutInObj',
      'localVersionOfElementsData',
      'selectedElementData',
      'inBoundParams',
      'inContainerElementPreview',
      'isRedoAllowed',
      'isUndoAllow',
      'allAvailableLayoutsData',
      'drillUpdateInfo'
    ]),
    ...mapReportBuilderGetters(['selectedContainerIndex']),
    selectedElementLocal() {
      return this.selectedElement
    },
    layoutName: {
      get() {
        return this.$store.state.ReportBuilder.layoutName
      },
      set(value) {
        // this.setIsAllElemsAndCurrentLayoutSaved(false)
        this.updateLayoutName(value)
      }
    },
    isContainerSelected() {
      return this.selectedContainerIValue !== -1
    },
    hasContainerMoreThanOneElement() {
      return true
      return this.layout[this.selectedContainerIndex].elements.length > 1
    },
    w() {
      return 12
      // return 4
    },
    h() {
      return 4
      // return 3
    },
    allAvailableLayoutsItems() {
      let layoutIdNameMapper = {}
      this.allAvailableLayoutsData.forEach((l) => {
        layoutIdNameMapper[l.identity] = l.name
      })
      return layoutIdNameMapper
    }
  },
  data() {
    return {
      dupLayoutName: '',
      name: '',
      isActive1: false,
      index: 0, //NOTE: previously it was set to 1
      arrangeChildrenDialogStatus: false,
      // layoutName: "defaultText",
      supplementsParams: [],
      supplementsParamsInDetails: [],
      reportLevelSupplement: [],
      outBoundParams: [],
      menu: false,
      dialog: false,
      layoutNameRules: [
        (V) => !!V || 'Required',
        (V) => !(V == 'Unititled Name') || 'Required',
        (v) => !(v == this.dupLayoutName) || 'Layout Name already exists'
      ],
      layoutNameEdit: false
    }
  },
  methods: {
    toggle() {
      this.opened = true
    },

    hide() {
      this.opened = false
    },
    ...mapRootMutations([
      'openSnackbarWithTime',
      'toggleLoader',
      'loader',
      'setAllElementsForImport',
      'setReport'
    ]),
    ...mapReportBuilderMutations([
      'setSelectedContainerIValue',
      'setSelectedElementIndex',
      'resetSelectedElementIdx',
      'undoLayoutState',
      'redoLayoutState',
      'updateLayoutName',
      'updateElemDataInsideContainerArraysElem',
      'updateElementDataInAllElementsInAReport',
      // 'updateLocalVersionOfElementsData',
      'toggleInContainerElementPreview',
      'setIsAllElemsAndCurrentLayoutSaved',
      'setListAllLayoutData',
      'resetDrillUpdateInfo', 'setCurrentLayoutNameInAllLayouts'
    ]),
    ...mapReportBuilderActions([
      'setLayoutStates',
      'updateBIReport',
      'onLayoutSave',
      'markUnMarkLayoutAsRoot',
      'updateLocalVersionOfElementsData',
      'detachDrillInfo',
      'attachDrillInfo'
    ]),
    ...mapRootActions(['getAllElements', 'openConfirmDialog']),
    ...mapElemBuilderActions(['createElement', 'updateElement']),
    getDepenedentParamsList(suppConf, filterkey) {
      // console.log(`[suppConf]: `, suppConf)
      let returnable = []
      if (suppConf.type == 'hrcy') {
      }
      if (suppConf.type == 'other_survey') {
        let configuration = JSON.parse(
          suppConf.configuration || JSON.stringify({})
        )
        if (configuration.masterDataMap && configuration.masterDataMap.length) {
          let filterMap = configuration.masterDataMap.find(
            (el) => el.target == filterkey
          )
          if (filterMap) {
            if (filterMap.sourceKey) {
              returnable.push(filterMap.sourceKey)
            }
            if (
              filterMap.sourceKeysMap &&
              typeof filterMap.sourceKeysMap == 'object'
            ) {
              returnable.push(...Object.values(filterMap.sourceKeysMap))
            }
          }
        }
      }
      if (suppConf.type == 'periodic') {
      }
      // console.log(`[returnable]: `, returnable)
      return returnable
    },
    openArrangeChildrenDialog() {
      if (!this.isContainerSelected || !this.hasContainerMoreThanOneElement) {
        return
      }
      this.arrangeChildrenDialogStatus = true
    },
    addItem: function () {
      let x = 0
      let y = 0
      const _id = new ObjectID().toString()
      const layout = cloneDeep(this.layout)
      if (layout.length !== 0) {
        let layouts = {}
        /** Grouping layouts that are in same row */
        layout.forEach((l) => {
          let point = l.y
          if (!layouts[point]) {
            layouts[point] = []
          }
          layouts[point].push(l)
        })
        let keys = Object.keys(layouts).sort()
        let lastRow = layouts[keys[keys.length - 1]]
        let firstElementOfLastRow = lastRow[0]
        y = firstElementOfLastRow.y + firstElementOfLastRow.h
      }
      const newElement = {
        x,
        y,
        w: this.w,
        h: this.h,
        i: layout.length,
        elements: [{ size: 12, elemLinkTo: _id, name: '' }], // [{ width: 12 }] NOTE: Verify this with Omkar OR Darakshan about props: { drill and supll }
        props: {
          direction: 'horizontal',
          type: 'simple'
        }
      }
      // Max only 20 containers are allowed to create...
      if (layout.length < 20) {
        layout.push(newElement)
      } else {
        this.openSnackbarWithTime({
          txt: 'Max only 20 containers are allowed to be created',
          time: 3500
        })
      }
      // console.log('layout :>> ', layout.length);
      // TODO: undo layout bugs (when add new layout it does not save)
      this.setLayoutStates(layout)
      let groundArea = document.getElementById('ground-area')
      this.setIsAllElemsAndCurrentLayoutSaved(false)
      this.resetSelectedElementIdx()
      setTimeout(() => {
        this.setSelectedElementIndex({
          elementIndex: 0,
          containerIValue: newElement.i,
          elemLinkTo: _id
        })
        groundArea.scrollTo(0, groundArea.scrollHeight)
      }, 100)
    },
    cloneContainer: function () {
      if (!this.isContainerSelected) return
      const layout = cloneDeep(this.layout)
      let clone = JSON.parse(
        JSON.stringify({
          ...layout[this.selectedContainerIndex],
          i: layout.length
        })
      )
      let lastContainer = layout[layout.length - 1]
      clone.y = lastContainer.y || clone.y
      // Clear elemLinkTo and name for cloned container's elements
      let zerothElementId
      clone.elements.map((e, idx) => {
        e.elemLinkTo = new ObjectID().toString()
        e.name = ''
        e.drill = {}
        if (idx == 0) zerothElementId = e.elemLinkTo
      })
      layout.push(clone)
      this.setLayoutStates(layout)
      let groundArea = document.getElementById('ground-area')
      this.setIsAllElemsAndCurrentLayoutSaved(false)
      this.resetSelectedElementIdx()
      setTimeout(() => {
        this.setSelectedElementIndex({
          elementIndex: 0,
          containerIValue: clone.i,
          elemLinkTo: zerothElementId
        })
        groundArea.scrollTo(0, groundArea.scrollHeight)
      }, 100)
    },
    removeItem: async function () {
      let isOk = await this.openConfirmDialog({
        header: 'INFO',
        message: 'Are you sure, You want to Delete this Container ?'
      })
      if (isOk) {
        if (!this.isContainerSelected) return
        const keysToDelete = []
        const layout = cloneDeep(this.layout)
        const removedContainer = layout.splice(this.selectedContainerIndex, 1)
        // console.log('removedContainer[0] :>> ', removedContainer[0])
        removedContainer[0].elements.forEach((e, idx) => {
          keysToDelete.push(e.elemLinkTo)
        })
        console.log('keysToDelete :>> ', keysToDelete)
        if (keysToDelete.length) {
          for (let key of keysToDelete) {
            console.log('key>>>>>', key)
            this.detachDrillInfo({
              elementId: key,
              prop: 'isContainer'
            })
          }
        }
        layout.forEach((el, idx) => {
          el.i = idx
        })
        this.setSelectedContainerIValue(-1)
        this.setLayoutStates(layout)
        this.setIsAllElemsAndCurrentLayoutSaved(false)
        // TODO: update indvidual element index and also update container index
        this.updateLocalVersionOfElementsData({
          op: 'delete',
          _id: keysToDelete
        })
      }
    },
    addElement() {
      const _id = new ObjectID().toString()
      if (!this.isContainerSelected) return
      const layout = cloneDeep(this.layout)
      let container = layout[this.selectedContainerIndex]
      if (!container.elements) {
        container.elements = []
      }
      container.elements.push({
        size: 4,
        elemLinkTo: _id,
        name: ''
      })
      this.setLayoutStates(layout)
      console.log('ADDD ELEM++++++++++++')
      this.setIsAllElemsAndCurrentLayoutSaved(false)
    },

    async saveLayoutName(){
      try {
        console.log(this.layoutName)
        if (this.$refs['form'] && !this.$refs['form'].validate()) {
          return
        }
        if (this.$refs['form']) this.$refs['form'].resetValidation()
        const payload = {
          reportId: this.report._id,
          name: this.layoutName,
          layoutId: this.currentLayoutData.identity
        }
        console.log('payload [saveLayout] :>> ', payload)
        const result = await this.updateBIReport(payload)
          console.log('result [saveLayout] :>> ', result)
          if(result.ok){
          this.setCurrentLayoutNameInAllLayouts(result.result)
          this.layoutNameEdit = false
          }
          this.openSnackbarWithTime({
            txt: result.ok
              ? 'Layout Name Saved Successfully'
              : 'Failed to Save Layout Name',
            time: 3500
          })
      } catch (error) {
        console.log(error)
        this.openSnackbarWithTime({
            txt: 'Failed to Save Layout Name',
            time: 3500
          })
      }
    },
    async discardChanges() {},
    async saveLayout() {
      // attach drill API (child)
      // set drill config to child layout
      try {
        console.log(this.layoutName)
        console.log(this.isAllElemsAndCurrentLayoutSaved)
        if (this.isAllElemsAndCurrentLayoutSaved) {
          console.log(this.isAllElemsAndCurrentLayoutSaved)
          return
        }
        // console.log(this.localVersionOfElementsData)
        if (this.$refs['form'] && !this.$refs['form'].validate()) {
          return
        }
        if (this.$refs['form']) this.$refs['form'].resetValidation()
        if (this.layoutName === 'Unititled Name') {
          this.openSnackbarWithTime({
            txt: 'Please Specify Layout Name',
            time: 3500
          })
          return
        }
        this.loader(true)
        const elementChangeMap = {
          create: [],
          update: []
        }
        let isTableDeleted = false,
          elementIdsAfterTableDeletion = []
        Object.keys(this.localVersionOfElementsData).forEach((key) => {
          if (
            this.localVersionOfElementsData[key].type &&
            this.localVersionOfElementsData[key].subType
          ) {
            const localVersion = JSON.parse(
              JSON.stringify(this.localVersionOfElementsData[key])
            )
            // ?@Darakshan
            delete localVersion.drill
            delete localVersion.isNewElem
            delete localVersion.isSaved
            if (this.localVersionOfElementsData[key].isNewElem) {
              elementChangeMap.create.push({
                ...localVersion,
                owner_report_info: {
                  reportId: this.report._id,
                  name: this.report.name,
                  displayName: this.report.displayName,
                  layoutId: this.currentLayoutData.identity
                }
              })
            } else if (!this.localVersionOfElementsData[key].isSaved) {
              // const localVersion = JSON.parse(
              //   JSON.stringify(this.localVersionOfElementsData[key])
              // )
              // delete localVersion.isNewElem
              // delete localVersion.isSaved
              if (
                !isEqual(
                  this.localVersionOfElementsData[key],
                  this.allElementsOfACurrentLayoutInObj[
                    this.localVersionOfElementsData[key]._id
                  ]
                )
              ) {
                delete this.localVersionOfElementsData[key].drill
                // if (
                //   !isEqual(
                //     this.localVersionOfElementsData[key].type,
                //     this.allElementsOfACurrentLayoutInObj[
                //       this.localVersionOfElementsData[key]._id
                //     ].type
                //   ) &&
                //   this.allElementsOfACurrentLayoutInObj[
                //     this.localVersionOfElementsData[key]._id
                //   ].type == 'table'
                // ) {
                //   isTableDeleted = true
                //   elementIdsAfterTableDeletion.push(
                //     this.localVersionOfElementsData[key]._id
                //   )
                // }
                elementChangeMap.update.push({
                  ...localVersion, //@darakshan
                  owner_report_info: {
                    reportId: this.report._id,
                    name: this.report.name,
                    displayName: this.report.displayName,
                    layoutId: this.currentLayoutData.identity
                  }
                })
              }
            }
          }
        })

        try {
          if (elementChangeMap.create.length) {
            let res = await this.createElement(elementChangeMap.create)
            console.log(res)
            if (!res.ok) {
              this.loader()
              this.openSnackbarWithTime({
                txt: res.message,
                time: 3500
              })
              return
            }
          }
        } catch (error) {
          console.log('error [create] :>> ', error)
          this.loader()
          this.openSnackbarWithTime({
            txt: 'Failed to Save Elements',
            time: 3500
          })
          return
        }
        // console.log(`[elementChan------------------geMap]: `, elementChangeMap)
        try {
          if (elementChangeMap.update.length) {
            let res = await this.updateElement(elementChangeMap.update)
            console.log(res)
            // if (res.ok && isTableDeleted && elementIdsAfterTableDeletion.length) {
            //   console.log("elementIdsAfterTableDeletion", elementIdsAfterTableDeletion)
            //   for (let id of elementIdsAfterTableDeletion){
            //     console.log("element Id>>")
            //     this.detachDrillInfo({
            //       elementId: id,
            //       prop: 'isElement'
            //     })
            //   }
            // }
            if (!res.ok) {
              this.loader()
              this.openSnackbarWithTime({
                txt: res.message,
                time: 3500
              })
              return
            }
          }
        } catch (error) {
          console.log('error [update] :>> ', error)
          this.loader()
          this.openSnackbarWithTime({
            txt: 'Failed to update Element',
            time: 3500
          })
          return
        }
        // console.log(`[elementChangeMa-------------p]: `, elementChangeMap)
        this.getAllElements({
          reportId: this.report._id || false,
          allElems: true
        }).then((resp) => {
          if (resp.ok) {
            this.setAllElementsForImport(resp.result)
          }
        })

        this.attachDrillInfo()
        console.log('drillUpdateInfo', this.drillUpdateInfo)
        const payload = {
          reportId: this.report._id,
          layouts: {
            [this.currentLayoutData.identity]: {
              ...this.currentLayoutData,
              type: 'layout',
              name: this.layoutName,
              // props: {
              //   isRoot: false,

              //   // Object.keys(this.report.layouts).length == 0 ? true : false
              // },
              structure: {
                containers: this.layout,
                supRefs:
                  (this.currentLayoutData.structure &&
                    this.currentLayoutData.structure.supRefs) ||
                  [],
                elemRefs: this.currentLayoutsElementRefs
              }
            }
          },
          drillUpdateInfo: this.drillUpdateInfo
        }
        console.log('payload [saveLayout] :>> ', payload)
        // console.log('this.currentLayoutData :>> ', this.currentLayoutData)
        try {
          const result = await this.updateBIReport(payload)
          console.log('result [saveLayout] :>> ', result)
          await this.onLayoutSave()
          if (result.ok) {
            this.setListAllLayoutData(result.result)
            this.setReport(result.result)
            this.layoutNameEdit = false
            // this.setIsAllElemsAndCurrentLayoutSaved(true)
            this.resetDrillUpdateInfo()
            console.log('drillUpdateInfo', this.drillUpdateInfo)
            // this.setIsAllElemsAndCurrentLayoutSaved(true)
          } else {
            this.loader()
            this.openSnackbarWithTime({
              txt: res.message,
              time: 3500
            })
            return
          }
          this.openSnackbarWithTime({
            txt: result.ok
              ? 'Layout Saved Successfully'
              : 'Failed to Save Layout',
            time: 3500
          })
          // TODO: LOOK FOR THIS IT CAN CAUSE CONFLICT IN ERROR OCCUR DURING LAYOUT SAVE
          this.setIsAllElemsAndCurrentLayoutSaved(true)
        } catch (error) {
          console.log('error :>> ', error)
          this.openSnackbarWithTime({
            txt: 'Failed to Save Layout',
            time: 3500
          })
        }
        this.loader()
      } catch (error) {
        console.log(error)
        this.loader()
      }
    },
    getSupplementParamColor(idx) {
      const colors = [
        'primary',
        'red',
        'green',
        'orange',
        'pink',
        'indigo',
        'teal',
        'cyan'
      ]
      return colors[idx]
        ? colors[idx]
        : colors[Math.floor(Math.random() * colors.length)]
    },
    getElementPositionIdx(elemId) {
      let result = {}
      const currentLayoutContainers = this.layout
      currentLayoutContainers.every((layout, layoutIdx) => {
        layout.elements.every((el, elemIdx) => {
          if (elemId === el.elemLinkTo) {
            result = { containerIdx: layoutIdx, elementIdx: elemIdx }
            return false
          }
          return true
        })
      })
      return result
    },

    onLayoutNameChange() {
      for (
        var index = 0;
        index < this.allAvailableLayoutsData.length;
        index++
      ) {
        if (this.allAvailableLayoutsData[index].name === this.layoutName) {
          console.log(this.allAvailableLayoutsData[index].name)
          this.dupLayoutName = this.allAvailableLayoutsData[index].name
        }
      }
      // this.setIsAllElemsAndCurrentLayoutSaved(false)
    },
    setSupplParams() {
      this.supplementsParams = []
      this.inBoundParams.forEach((el) => {
        this.supplementsParams.push(...el.filterKeys)
      })
      this.reportSupplements.forEach((el) => {
        this.supplementsParams.push(...el.filterKeys)
      })
    }
  },
  watch: {
    currentLayoutData(nv, old) {
      console.log('*********************************************')
      this.layoutName = nv.name
      this.setSupplParams()
      this.outBoundParams = (nv.props && nv.props.drillParent) || []
    },
    layout(nv, old) {
      // console.log('nv :>> ', nv, this.index)

      if (nv.length) {
        let max = 0
        nv.forEach((l) => {
          if (l.i > max) max = l.i
        })
        this.index = max + 1
      } else {
        this.index = 0
      }
      // console.log('nv :>> ', nv, this.index);
    },
    menu() {
      if (this.menu) {
        // this.inBoundParams.forEach((el) => {
        //   console.log(`[el]: `, el)
        // })
      }
      console.log(this.currentLayoutData, this.menu)
    },
    inBoundParams() {
      this.setSupplParams()
      // console.log(`[this.report]: `, this.report)
      // console.log("++++++++++++++++ibound+++++++++++",this.inBoundParams)
      // this.inBoundParams.forEach((el) => {
      //   this.supplementsParams.push(...el.filterKeys)
      // })
      // console.log("+++++++++++++suppl++++++++++++++++",this.supplementsParams)
    },
    reportSupplements() {
      this.setSupplParams()
      // this.supplementsParams = []
      // this.reportSupplements.forEach((el) => {
      //   this.supplementsParams.push(...el.filterKeys)
      // console.log("*********aftersuppl****************",this.supplementsParams)
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  transition: var(--transition);
  background-size: var(--background-size);
}
.btn:hover {
  background-position: var(--background-position);
}
.btn-1 {
  background-image: var(--background-image);
}
.action-bar {
  background-color: $link-water;
}
.tool-sheet {
  display: inline-block;
  background-color: $link-water;
  // margin-top: 1%;
  // margin-bottom: 1%;
  margin-right: 0.5em;
  height: 100%;
  padding: unset !important;
  // margin: 1%;
}

.tool-sheet-disabled {
  cursor: no-drop;
  // border: 1px solid #999999;
  opacity: 0.3 !important;
  color: #666666;
}

.tool-icon {
  max-width: 25px;
  height: 100%;
  display: block;
  margin: auto;
  // margin-top: 10%;
}
// .undoRedo{
//   margin-top: 0.1%;
// }
.v-chip {
  cursor: pointer;
}
.v-menu__content {
  // OVERRIDING DEFAULT CSS
  top: 8rem !important;
  left: 28rem !important;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: $link-water;
}

.v-application .primary--text {
  /* caret-color: #1976d2 !important; */
  color: $link-water !important;
}
.container-shadow {
  // box-shadow: 0px 2px 1px -1px rgba(210, 225, 243, 0.12), 0px 1px 1px 0px rgba(210, 225, 243, 0.12),
  //   0px 1px 3px 0px rgba(210, 225, 243, 0.12) !important;
  // color: rgba(210, 225, 243, 0.12);
  // padding-left: 10px;
}
.container-shadow {
  // box-shadow: 0px 2px 1px -1px #9ec1e8d7, 0px 1px 1px 0px #9ec1e8d7,
  //   0px 1px 3px 0px #9ec1e8d7 !important;
  color: #9ec1e8d7;
  padding-left: 10px;
  border-bottom: 2px solid;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  /* padding-left: 32px; */
  padding-left: unset;
}
// .combine-actions {
//   // border: 1px solid rgba(128, 128, 128, 0.566);
//   // padding: 2px;
// }
// .combine-actions-btn {
//   border: 1px solid rgba(128, 128, 128, 0.566);
// }
</style>

<style>
.MNUYTR fieldset {
  border-collapse: collapse;
  border-color: lightgrey !important;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: 0.3s;
  transition-property: color, border-width;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
</style>
<style lang="scss" scoped>
.layoutTitle {
  overflow: unset;
  text-overflow: unset;
  color: $royal-blue;
  // padding-right: 1rem;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  padding: 10px;
}
</style>
