<template>
  <div>
    <v-container fluid grid-list-xs>
      <v-row wrap no-gutters>
        <v-col :cols="isPreviewEnabled ? 8 : 12">
          <template v-if="filteredSupplementsRequest.length">
            <v-container grid-list-xs fluid>
              <v-row wrap>
                <v-col
                  cols="3"
                  v-for="req of filteredSupplementsRequest"
                  :key="req.requestId"
                >
                  <SupplementCard
                    :borderColor="statusColorMapping[req.status]"
                    :name="req.requestId"
                    :type="req.type"
                    :filterKeys="req.filterKeys || []"
                  >
                    <template #action>
                      <v-btn
                        icon
                        @click="openRequestEditor(req)"
                        v-if="['pending', 'inProgress'].includes(req.status)"
                      >
                        <v-icon>mdi-pencil </v-icon></v-btn
                      >
                      <v-btn
                        icon
                        @click="onPreview(req)"
                        v-if="req.status == 'completed'"
                      >
                        <v-icon>mdi-eye </v-icon></v-btn
                      >
                    </template>
                  </SupplementCard>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-col>
              <p
                v-if="!isFetchingRequest && !filteredSupplementsRequest.length"
                class="mt-2 display-1"
                style="text-align: center"
              >
                No Data available.
              </p>
              <p
                class="mt-2 loading display-1"
                style="text-align: center"
                v-else
              >
                Fetching Request
              </p>
            </v-col>
          </template>
          <v-col :cols="isPreviewEnabled ? 8 : 12">
            <!-- {{ requestedPaginationConf }} -->
            <template v-if="requestedPaginationConf.paginationLength">
              <v-pagination
                :length="requestedPaginationConf.paginationLength"
                v-model="requestedPaginationConf.page"
                @input="
            (pageNo) => {
              fetchFromServer({ page: pageNo });
            }
          "
              />
            </template>
          </v-col>
        </v-col>
        <v-col style="margin-top:0.9%" cols="4" v-if="isPreviewEnabled">
          <v-card style="height:100%" outlined elevation="2">
            <div
              v-if="Object.keys(selectedRequested).length"
              style="overflow-y: auto"
            >
              <v-card-title style="padding: unset">
                <div title headline class="tabFont" style="padding-left: 10px">
                  {{ selectedRequested.requestId }}
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="closePreviewSupplement">
                  <v-icon>mdi mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="overflow-y: auto">
                <!-- <div :style="`height: ${heightDiv - 10 * 19.2}px`"> -->
                <div style="height: 70vh">
                  <!-- <h4>HERE Supplement will render....</h4> -->
                  <v-card-text>
                    <SuplementRender
                      :selectedSupplement="selectedRequested.configuration"
                    />
                  </v-card-text>
                </div>
              </v-card-text>
            </div>
            <div v-else style="text-align: center; margin-top: 20px">
              <h3>Select a Supplement for preview...</h3>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <RequestSupplementDialog
      ref="request-supplement-dialog"
      :requestSupplementDialogStatus="requestSupplementDialogStatus"
      @close-dialog="requestSupplementDialogStatus = false"
      :selectedRequested="selectedRequested"
    />
  </div>
</template>

<script>
import SupplementCard from './SupplementCard.vue'
import RequestSupplementDialog from './RequestSupplementDialog.vue'
import SuplementRender from './SuplementRender'
import { createNamespacedHelpers as cnh } from 'vuex'
const { mapActions, mapGetters, mapState, mapMutations } = cnh('supplementStore')
export default {
  components: {
    SupplementCard,
    RequestSupplementDialog,
    SuplementRender
  },
  computed: {
    ...mapGetters(['listOfRequestedSupplements', 'requestedPaginationConf']),
    ...mapState(['isFetchingRequest']),
    filteredSupplementsRequest() {
      if (this.requestedPaginationConf.filterText) {
        return this.listOfRequestedSupplements.filter((el) => {
          return (
            el.requestId
              .toLowerCase()
              .includes(
                this.requestedPaginationConf.filterText.toLowerCase()
              ) ||
            el.type
              .toLowerCase()
              .includes(
                this.requestedPaginationConf.filterText.toLowerCase()
              ) ||
            el.status
              .toLowerCase()
              .includes(this.requestedPaginationConf.filterText.toLowerCase())
          )
        })
      } else {
        return this.listOfRequestedSupplements
      }
    }
  },
  data() {
    return {
      isPreviewEnabled: false,
      requestSupplementDialogStatus: false,
      selectedRequested: {},
      // supplementsPerPage: 12,
      statusColorMapping: {
        pending: 'red',
        inProgress: 'orange',
        completed: 'green'
      }
    }
  },

  methods: {
    ...mapActions(['getAllRequestedSupplements']),
    ...mapMutations(['setRequestPaginationconf']),
    fetchFromServer({ page }) {
      let payload = {}
      if (this.requestedPaginationConf.filterText) {
        payload.match = {}
        payload.match['$or'] = []
        ;[
          'requestId',
          'owner_report_info.reportName',
          'owner_report_info.requestorUsername',
          'records.creator',
          'type',
          'status'
        ].forEach((col) => {
          payload.match.$or.push({
            [col]: {
              $regex: this.requestedPaginationConf.filterText,
              $options: 'i'
            }
          })
        })
      }
      if (page)
        this.setRequestPaginationconf(
          Object.assign(this.requestedPaginationConf, { page })
        )
      this.getAllRequestedSupplements(payload)
    },
    openRequestEditor(data) {
      console.log(`[data]: `, data)
      this.selectedRequested = data
      this.$forceUpdate()
      this.requestSupplementDialogStatus = true
      this.$refs['request-supplement-dialog'].openModal('edit', data)
    },
    closePreviewSupplement() {
      this.isPreviewEnabled = false
      this.selectedRequested = {}
    },
    onPreview(data) {
      this.selectedRequested = data
      this.isPreviewEnabled = true
    }
  }
}
</script>

<style></style>