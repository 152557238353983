<template>
  <div>
    <v-card
      elevation="0"
      min-width="300"
      max-width="300"
      min-height="180"
      max-height="180"
      v-ripple
      style="padding: 5px; border: 2px solid rgba(0, 0, 0, 0.1)"
    >
      <div
        class="ribbon ribbon-top-left"
        v-if="layout && layout.props && layout.props.isRoot"
      >
        <span style="background-color: #10b71f" darken-2> Root Layout</span>
      </div>
      <div
        style="height: 180px; cursor: pointer"
        class="d-flex flex-column justify-between"
      >
        <div style="height: 180px; text-align: center" class="mt-5">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!hide"
                v-on="on"
                v-bind="attrs"
                icon
                text
                x-small
                absolute
                top
                right
                @click="submitOption('duplicate', layout)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Duplicate</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                text
                x-small
                absolute
                top
                right
                class="mx-6"
                @click="submitOption('edit', layout)"
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="hide"
                v-on="on"
                v-bind="attrs"
                icon
                text
                x-small
                absolute
                top
                right
                @click="submitOption('preview', layout)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Preview</span>
          </v-tooltip>

          <v-card-title> {{ layout.name }} </v-card-title>
          <v-card-subtitle
            style="margin-top: -16px; text-align: initial;"
            >
            <!-- Updated on: -->
            {{
              layout.record && layout.record.updatedOn
                ? formatDate((layout.record.updatedOn))
                : formatDate((layout.updatedOn))
            }}
          </v-card-subtitle>
        </div>
        <div v-if="!hide">
          <v-card-actions>
            <!-- <v-btn color="red darken-2" text> Explore </v-btn> -->
            <v-spacer></v-spacer>
            <v-menu bottom origin="center center">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class="v-list-custom">
                <v-list-item v-for="(item, i) in items" :key="i" link>
                  <v-list-item-title @click="submitOption(item.key, layout)">{{
                    item.key !== 'rootLayout'
                      ? item.title
                      : `${
                          layout && layout.props && layout.props.isRoot
                            ? 'UnMark as Root Layout'
                            : 'Mark as Root Layout'
                        }`
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import '@common-ui/common/css/ribbon.css'
import {
  createNamespacedHelpers as cnh,
  mapMutations as mapRootMutations,
  mapState as mapRootState,
  mapActions as mapRootActions
} from 'vuex'
const {
  mapState: mapReportBuilderState,
  mapActions: mapReportBuilderActions,
  mapMutations: mapReportBuilderMutations
} = cnh('ReportBuilder')
export default {
  props: ['layout', "hide"],
  created() {
    console.log('Created of Layout card', this.layout)
    // console.log(typeof this.report.layouts)
  },
  data() {
    return {
      show: false,
      items: [
        // { title: 'Edit', key: 'edit' },
        // { title: 'Duplicate', key: 'duplicate' },
        { title: 'Mark/UnMark as Root Layout', key: 'rootLayout' },
        // { title: 'Preview', key: 'preview' },
        { title: 'Delete', key: 'delete' }
      ]
    }
  },
  computed: {
    ...mapRootState(['report']),
    ...mapReportBuilderState(['isAllElemsAndCurrentLayoutSaved'])
  },
  methods: {
    ...mapRootMutations(['openSnackbarWithTime', 'toggleLoader', 'setReport']),
    ...mapRootActions(['openConfirmDialog']),
    ...mapReportBuilderMutations(['setAllLayoutsData', 'setCurrentTab']),
    ...mapReportBuilderActions([
      'deleteLayout',
      'duplicateLayout',
      'markUnMarkLayoutAsRoot'
    ]),
    async submitOption(action, layout) {
      try {
        let isOk = false
        let result = {}
        switch (action) {
          case 'edit':
            console.log(
              '----------edit: ',
              layout,
              this.isAllElemsAndCurrentLayoutSaved
            )
            if (!this.isAllElemsAndCurrentLayoutSaved) {
              this.openSnackbarWithTime({
                txt: 'Current Edited layout is unsaved. First Save it.',
                time: 5000
              })
              setTimeout(() => {
                this.setCurrentTab(0)
              }, 2000)
              return
            }
            this.$emit('editLayout', layout)
            break
          case 'preview':
            console.log('----------preview: ', layout)
            this.$emit(
              'previewLayout',
              (layout.structure && layout.structure.containers) || []
            )
            break
          case 'duplicate':
            if (!this.isAllElemsAndCurrentLayoutSaved) {
              this.openSnackbarWithTime({
                txt: 'Current Edited layout is unsaved. First Save it.',
                time: 5000
              })
              setTimeout(() => {
                this.setCurrentTab(0)
              }, 2000)
              return
            }
            isOk = await this.openConfirmDialog({
              header: 'INFO',
              message:
                'Are you sure, You want to Clone this Layout :- "' +
                layout.name +
                '" ?'
            })
            if (isOk) {
              this.toggleLoader()
              const payload = {
                reportId: this.report._id,
                layoutId: layout.identity
              }
              result = await this.duplicateLayout(payload)
              console.log('result [duplicate layout] :>> ', result)
              if (result.ok) {
                this.setAllLayoutsData({
                  mode: 'listAllLayouts',
                  payload: result.result
                })
              }
              this.toggleLoader()
              this.openSnackbarWithTime({
                txt: result.ok
                  ? 'Layout Duplicated Successfully'
                  : 'Failed to Duplicate Layout',
                time: 3500
              })
            }
            break
          case 'delete':
            console.log('----------delete: ', layout)
            isOk = await this.openConfirmDialog({
              header: 'WARNING',
              message:
                'Are you sure, You want to delete this Layout :- "' +
                layout.name +
                '" ?'
            })
            // TODO: DO NOT CHANGED CURRENT LAYOUT DATA
            if (isOk) {
              this.toggleLoader()
              const payload = {
                reportId: this.report._id,
                layoutId: layout.identity
              }
              result = await this.deleteLayout(payload)
              console.log('result [delete layout] :>> ', result)
              if (result.ok) {
                this.setAllLayoutsData({
                  mode: 'listAllLayouts',
                  payload: result.result
                })
              }
              this.toggleLoader()
              this.openSnackbarWithTime({
                txt: result.ok
                  ? 'Layout Deleted Successfully'
                  : 'Failed to Delete Layout',
                time: 3500
              })
            }
            break
          case 'rootLayout':
            isOk = await this.openConfirmDialog({
              header: 'INFO',
              message:
                'Are you sure, You want to ' +
                (layout && layout.props && layout.props.isRoot
                  ? 'UnMark'
                  : 'Mark') +
                ` this "${layout.name}" Layout as Root Layout.` +
                '" ?'
            })
            if (isOk) {
              this.toggleLoader()
              const payload = {
                reportId: this.report._id,
                layoutId: layout.identity,
                isRoot:
                  layout && layout.props && layout.props.isRoot
                    ? !layout.props.isRoot
                    : true
              }
              result = await this.markUnMarkLayoutAsRoot(payload)
              console.log('result [rootLayout layout] :>> ', result)
              // TODO: DO NOT CHANGED CURRENT LAYOUT DATA
              if (result.ok) {
                this.setAllLayoutsData({
                  mode: 'listAllLayouts',
                  payload: result.result
                })
                this.setReport(result.result)
              }
              this.toggleLoader()
              this.openSnackbarWithTime({
                txt: result.ok
                  ? 'Layout Updated Successfully'
                  : 'Failed to Update Layout',
                time: 3500
              })
            }
            break
          default:
            console.log('Wrong Layout action')
            break
        }
      } catch (error) {
        this.toggleLoader()
        console.log('error :>> ', error)
        this.openSnackbarWithTime({
          txt: 'Failed to Delete Layout. Error: ' + error.message,
          time: 3500
        })
      }
    },
    formatDate(date) {
      console.log(`[date]: `, date);
      let DATE = new Date(date)
      // const dateArr = date.toString().slice(4, 15).split(' ')
      return DATE.toDateString() + " " + DATE.toLocaleTimeString()
    }
  }
}
</script>
<style lang="scss">
.v-list-custom {
  .v-list-item {
    min-height: 34px;
  }
  .v-list-item__title {
    font-size: 0.9rem;
  }
}
</style>
