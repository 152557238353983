<template>
  <v-hover v-slot="{ hover }" open-delay="200">
    <v-card
      :elevation="hover ? 5 : 0"
      class="mx-auto predefinedLayoutCard"
      max-width="220"
      max-height="220"
      style="padding: 5px; border: 2px solid rgba(0, 0, 0, 0.1)"
    >
      <v-img
        class="white--text align-end"
        height="150px"
        :src="require(`@assets/images/${layout.src}.png`)"
      >
      </v-img>
      <div class="d-flex justify-space-between pl-2 align-center">
        <span style="font-size: 14px">{{ layout.name }}</span>
        <!-- <v-menu bottom origin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon style="font-size: 16px">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, i) in items" :key="i" link>
              <v-list-item-title
                @click="$emit('submitOption', 'preview', layout.payload)"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu> -->
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: ['layout'],
  data() {
    return {
      items: []
      // items: [{ title: 'Preview', key: 'preview' }]
    }
  }
}
</script>

<style>
</style>