<template>
  <v-dialog
    v-if="requestSupplementDialogStatus"
    :value="requestSupplementDialogStatus"
    persistent
    :overlay="false"
    scrollable
    width="65%"
    transition="dialog-transition"
  >
    <!-- fullscreen -->
    <v-card>
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0 text-color">Request supplement</h3>
        </div>
        <v-spacer></v-spacer>
          <v-btn
            icon
            color="pink"
            @click="resetData"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="requestForm" v-model="valid" class="v-dialog-custom" lazy-validation>
          <v-radio-group
            row
            v-model="selectedRequestedLocal.type"
            :rules="REQUIRED"
            required
          >
            <v-radio
              v-for="n in [
                { text: 'filter', value: 'other_survey' },
                { text: 'Hierarchy', value: 'hrcy' },
                { text: 'Periodic', value: 'periodic' },
                {
                  text: 'Initial Default Params',
                  value: 'initialDefaultParams'
                }
              ]"
              :key="n.value"
              :label="n.text"
              :value="n.value"
            ></v-radio>
          </v-radio-group>
          <v-text-field
           v-for="(
                supplement, index
              ) of selectedRequestedLocal.requestedList"
              :key="index"
           v-model="supplement.Name"
           :value="supplement.Name"
            style="width:440px; margin-left:20px;"
            outlined
            dense
            label="Enter Name"
          ></v-text-field>
          <!-- <v-radio :label="fieldType.text" :value="fieldType.value"></v-radio> -->
          <v-container grid-list-xs fluid>
            <v-row
              wrap
              class="ma-1"
              style="border: 1px solid #e4dddd"
              v-for="(
                supplement, index
              ) of selectedRequestedLocal.requestedList"
              :key="index"
            >
              <v-col cols="6">
                <!-- {{ supplement }} -->
                <v-text-field
                 style="width:900px"
                  v-model="supplement.filterkey"
                  :value="supplement.filterkey"
                  outlined
                  dense
                  label="Enter field name *"
                  :rules="REQUIRED"
                  required
                ></v-text-field>
                <template v-if="fieldTypes.length">
                  <p class="mb-1">Select Field Type *</p>
                  <v-radio-group
                    class="mt-1"
                    row
                    v-model="supplement.type"
                    :rules="REQUIRED"
                  >
                    <v-container grid-list-xs fluid>
                      <v-row wrap no-gutters>
                        <v-col
                          cols="4"
                          v-for="fieldType of fieldTypes"
                          :key="fieldType.text"
                        >
                          <v-radio
                            :key="fieldType.text"
                            :label="fieldType.text"
                            :value="fieldType.value"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-radio-group>
                </template>
                <v-text-field
                  style="width:900px"
                  v-model="supplement.defaultValue"
                  :value="supplement.defaultValue"
                  outlined
                  dense
                  label="Enter default value"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="descfix">
                <v-textarea
                  v-model="supplement.description"
                  :value="supplement.description"
                  outlined
                  dense
                  rows="9"
                  label="Enter description *"
                  :rules="REQUIRED"
                  required
                ></v-textarea>
              </v-col>
                <template
                  v-if="selectedRequestedLocal.requestedList.length > 1"
                >
                  <!-- <v-col offset-sm="10" cols="2" style="padding-top: unset"> -->
                  <v-btn
                    @click="removeSupplement(index)"
                    color="red"
                    text
                    style="margin-bottom: 30px;margin-left:700px;"
                    >Remove Supplement</v-btn
                  >
                  <!-- </v-col> -->
                </template>
            </v-row>
            <v-row wrap class="justify-center">
              <!-- <v-spacer></v-spacer> -->
              <template v-if="showAddBtn">
                <!-- <v-col cols="2"> -->
                  <v-btn @click="addSupplement" class="btn2 btn-2 black--text" dark
                    >Add supplement</v-btn
                  > 
                <!-- </v-col> -->
              </template>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="resetData" text>Close</v-btn> -->
        <v-btn color="#2a51e8" dark class="btn btn-1" @click="saverequest">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
let index = -1
import { createNamespacedHelpers as cnh, mapState as mapRootState } from 'vuex'
const { mapActions, mapGetters, mapState } = cnh('supplementStore')

import { v4 as uuidv4 } from 'uuid'
import { create } from '@amcharts/amcharts4/core'
const getSupplement = () => {
  return {
    filterkey: '',
    type: 'dropdown',
    defaultValue: '',
    Name: '',
    description: ''
  }
}

export default {
  props: {
    requestSupplementDialogStatus: Boolean,
    selectedRequested: {
      type: Object,
      default: () => ({
        type: 'other_survey',
        requestedList: [getSupplement()]
      })
    }
  },
  computed: {
    ...mapRootState(['mortal', 'report']),
    fieldTypes() {
      console.log(this.selectedRequestedLocal.type)
      if (this.selectedRequestedLocal.type == 'other_survey') {
        return [
          { text: 'Text', value: 'text' },
          { text: 'Number', value: 'number' },
          { text: 'Dropdown', value: 'dropdown' },
          { text: 'Checkbox', value: 'checkbox' },
          { text: 'Radio Group', value: 'radioGroup' },
          { text: 'Calendar', value: 'calendar' }
        ]
      }
      return []
      // if(this.selectedRequested.type == 'periodic') {

      // }
    },
    showAddBtn() {
      if (this.selectedRequestedLocal.type == 'hrcy') {
        return false
      }
      if (
        this.selectedRequestedLocal.type == 'periodic' &&
        this.selectedRequestedLocal.requestedList.length == 2
      ) {
        return false
      }
      return true
    }
  },
  data() {
    return {
      selectedRequestedLocal: JSON.parse(
        JSON.stringify(this.selectedRequested)
      ),
      valid: true,
      mode: 'create',
      REQUIRED: [(V) => !!V || 'Required']
    }
  },
  methods: {
    ...mapActions(['createRequest', 'updateRequest']),
    openModal(mode, data) {
      console.log(`[this.selectedRequested]: `, data)
      this.$refs.requestForm && this.$refs.requestForm.resetValidation()
      this.mode = mode
      if (mode == 'create') {
        this.selectedRequestedLocal = {
          type: 'other_survey',
          requestedList: [getSupplement()]
        }
      } else {
        this.selectedRequestedLocal = JSON.parse(JSON.stringify(data))
      }
      console.log(`mode: `, mode, this.selectedRequestedLocal)

      this.$forceUpdate()
    },
    addSupplement() {
      this.selectedRequestedLocal.requestedList.push(getSupplement())
    },
    removeSupplement(index) {
      this.selectedRequestedLocal.requestedList.splice(index, 1)
    },
    getRequestId() {
      let ll = uuidv4().split('-')
      return ll[ll.length - 1].toUpperCase()
    },
    async saverequest() {
      try {
        if (!this.$refs.requestForm.validate()) return
        this.selectedRequestedLocal.filterKeys =
          this.selectedRequestedLocal.requestedList.map((el) => el.filterkey) ||
          []
        if (this.mode == 'create') {
          let payload = {
            requestId: this.getRequestId(),
            type: this.selectedRequestedLocal.type,
            requestedList: this.selectedRequestedLocal.requestedList,
            filterKeys: this.selectedRequestedLocal.filterKeys,
            status: 'pending',
            owner_report_info: {
              report_id: this.report._id,
              reportName: this.report.name,
              requestorUsername: this.mortal.username
            }
          }
          let res = await this.createRequest(payload)
        }
        if (this.mode == 'edit') {
          console.log('update')
          let payload = {
            ...this.selectedRequestedLocal,
            reportId: this.report._id
          }
          let res = await this.updateRequest(payload)
        }
        this.resetData()
      } catch (error) {
        console.error(error)
      }
    },
    resetData() {
      this.selectedRequestedLocal = {
        type: 'other_survey',
        requestedList: [getSupplement()]
      }
      this.$emit('close-dialog')
    }
  },
  watch: {
    selectedRequestedLocal: {
      handler: function () {},
      deep: true
    }
  }
}
</script>

<style lang="scss">
.btn{
  transition: var(--transition);
  background-size: var(--background-size);
}
.btn:hover{
  background-position: var(--background-position);
}
.btn-1{
  background-image: var(--background-image);
}
.btn2{
  transition: var(--transition);
  background-size: var(--background-size);
  border: var(--border);
}
.btn2:hover{
   background-position: var(--background-position);
}
.btn-2{
  background-image: var(--background-imagee);
  color: red;
}
.v-dialog-custom .v-label,
.v-input input,
.v-text-field__slot textarea{
  font-size: 14px !important;
}
.text-color {
  color: $deep-sapphire;
}
.v-radio{
  padding:5px;
}
.descfix{
  display: flex;
  align-items: center;
}
</style>