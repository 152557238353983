<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12">
        <!-- <h4>Position</h4> -->
        <v-radio-group required column v-model="themeSelected" class="radio-group-custom">
          <v-row>
            <v-col cols="3">
              <v-radio color="#1B3EC4" label="Theme 1" value="theme_1"></v-radio>
            </v-col>
            <v-col cols="3">
              <v-radio color="#1B3EC4" label="Theme 2" value="theme_3"></v-radio>
            </v-col>
            <v-col cols="3">
              <v-radio color="#1B3EC4" label="Theme 3" value="theme_4"></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
        <v-btn color="#2a51e8" class="ma-2" dark small @click="submitReportTheme" :loading="btnLoader">Save</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers as cnh, mapState as mapRootState, mapMutations as mapRootMutations } from 'vuex'
export default {
  data() {
    return {
      themeSelected: ''
    }
  },
  computed: {
     ...mapRootState(["report", "btnLoader", 'reportSupplements']),
  },
  created(){
    this.mapData(this.report)
    console.log("this.report>>>>>", this.report)
  },
  methods: {
    submitReportTheme(){
      const payload = {
        reportId: this.report._id,
        props: {
          ...this.report.props,
          themeSelected: this.themeSelected,
        }
      }
      try {
        this.$emit("updateReportProps", payload)
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    mapData(data){
      this.themeSelected = data.props && data.props.themeSelected ? data.props.themeSelected : ""
    },
  },
}
</script>

<style>
.radio-group-custom .v-label{
  font-size: 14px !important;
}
</style>