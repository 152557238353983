<template>
  <div id="ground-area">
    <GridLayout
      id="layout"
      ref="layout"
      :layout="layout"
      @layout-mounted="setMouseEventListeners"
      :col-num="colNum"
      :row-height="idealGridRowHt"
      :margin="[10, 10]"
      preserve-aspect-ratio
    >
      <GridItem
        v-for="(item, idx) in layout"
        :minH="1"
        :minW="2"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="idx"
        :key="idx"
        @resize="resizeEvent"
        @moved="movedEvent"
        :class="[
          'container',
          selectedContainerIValue === item.i ? 'selected-container' : ''
        ]"
      >
        <div
          class="container-box"
          @click.stop="setSelectedContainerIValueMethod(item.i)"
        >
          <v-tooltip
            bottom
            v-if="
              selectedContainerIValue === item.i &&
              !!selectedElementAction &&
              selectedElementAction.containerIValue !== item.i
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- selected-cut-element -->
              <v-btn
                class="PASTE_BTN"
                @click.stop="pasteElements(item)"
                fab
                color="primary"
                v-bind="attrs"
                small
                class1=""
                v-on="on"
              >
                <v-icon color="white">mdi-content-paste</v-icon>
              </v-btn>
            </template>
            <span>Paste</span>
          </v-tooltip>

          <div
            :class="`element-list ${
              item.props && item.props.direction === 'vertical'
                ? 'element-list-for-vertical'
                : ''
            }`"
            v-if="item.elements"
          >
            <div
              v-for="(element, i) of item.elements"
              :key="i + 'container-element'"
              :style="
                getCSSAccordingToContainerDirection(
                  item.props && item.props.direction
                    ? item.props.direction
                    : '',
                  element
                )
              "
              style="padding: 5px"
            >
              <!-- START: ELEMENT-CARD -->
              <div
                :style="`${
                  inContainerElementPreview ? 'background-color:white' : ''
                }`"
                @click.stop="setSelectedElementIndexConf(i, item, element)"
                :class="[
                  'd-flex flex-column align-center element',
                  selectedContainerIValue === item.i &&
                  selectedContainerElementIndex === i
                    ? 'selected-element'
                    : '',
                  !!selectedElementAction &&
                  selectedElementAction.elementIndex == i &&
                  selectedElementAction.containerIValue == item.i
                    ? 'selected-cut-element'
                    : ''
                ]"
              >
                <!-- :style="`width:100%; padding-right: 1%; position: ${
                    isContainerDirectionIsVertical(item)
                      ? 'absolute'
                      : 'relative'
                  };`" -->
                <!-- ###############START: element Action bar#################### -->
                <div
                  class="d-flex flex-row-reverse my-1"
                  :style="`width:100%; padding-right: 1%; position: ${
                    isContainerDirectionIsVertical(item)
                      ? 'absolute'
                      : 'relative'
                  }; ${
                    isContainerDirectionIsVertical(item) ? 'z-index:123' : ''
                  }`"
                  v-if="
                    selectedContainerIValue === item.i &&
                    selectedContainerElementIndex === i &&
                    !inContainerElementPreview
                  "
                >
                  <v-tooltip bottom v-if="item.elements.length > 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.stop="
                          elemActionClicked('delete', {
                            element,
                            elementIndex: i,
                            item
                          })
                        "
                        icon
                        v-bind="attrs"
                        small-xs
                        class="button-size"
                        v-on="on"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        @click.stop="
                          elemActionClicked('duplicate', {
                            element,
                            elementIndex: i,
                            item
                          })
                        "
                        icon
                        v-bind="attrs"
                        small-xs
                        class="button-size"
                        v-on="on"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicate</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        :disabled="!!selectedElementAction"
                        @click.stop="cutElements(element, i, item)"
                        icon
                        v-bind="attrs"
                        small-xs
                        class="button-size"
                        v-on="on"
                      >
                        <v-icon>mdi-content-cut</v-icon>
                      </v-btn>
                    </template>
                    <span>Cut</span>
                  </v-tooltip>
                </div>
                <!-- ###############END: element Action bar############### -->
                <div
                  class="parentDiv"
                  v-if="
                    !inContainerElementPreview ||
                    showSelectedElement(i, item.i, element.elemLinkTo)
                  "
                >
                  <div class="leftBtn">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="i === 0"
                          @click="
                            elemActionClicked(
                              isContainerDirectionIsVertical(item)
                                ? 'up'
                                : 'left',
                              { element, elementIndex: i, item }
                            )
                          "
                          icon
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{
                            isContainerDirectionIsVertical(item)
                              ? 'mdi-menu-up'
                              : 'mdi-menu-left'
                          }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        isContainerDirectionIsVertical(item)
                          ? 'Move Up'
                          : 'Move Left'
                      }}</span>
                    </v-tooltip>
                  </div>
                  <div class="mainContent">
                    <!-- Element single preview -->
                    <div
                      class="elementPreview"
                      style="width: 100%; height: 100%"
                      v-if="
                        selectedElementData._id == element.elemLinkTo &&
                        selectedElementComponentPreview.id == element.elemLinkTo
                      "
                    >
                      <template
                        v-if="selectedElementComponentPreview.visibility"
                      >
                        <div
                          class="elementrender"
                          style="width: 100%; height: 100%"
                        >
                          <BiElementBox
                            :key="
                              element.elemLinkTo + 'elementComponentPreview'
                            "
                            :elemConf="
                              localVersionOfElementsData[element.elemLinkTo]
                            "
                            :containerSpec="item"
                          />
                          <!-- <component
                            :key="
                              element.elemLinkTo + 'elementComponentPreview'
                            "
                            :is="selectedElementComponentPreview.componentFn"
                            v-bind="
                              localVersionOfElementsData[element.elemLinkTo]
                            "
                          /> -->
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="noPreview"
                          style="
                            transform: translate(-50%, -50%);
                            position: absolute;
                            top: 50%;
                            left: 50%;
                          "
                        >
                          <h4>
                            {{
                              selectedElementComponentPreview.message ||
                              'No Preview'
                            }}
                          </h4>
                        </div>
                      </template>
                    </div>
                    <!-- Element Setting -->
                    <div
                      class="elementContent"
                      style="font-size: 10px; font-weight: 500"
                      v-else-if="
                        inContainerElementPreview
                          ? selectedElementData._id == element.elemLinkTo
                          : true
                      "
                    >
                      <div class="d-flex align-center justify-center">
                        <div>Size:</div>
                        <input
                          type="text"
                          class="txtSizeInput"
                          v-model.number="element.size"
                          @change="
                            element.size =
                              element.size > 12
                                ? 12
                                : element.size <= 0
                                ? 1
                                : Number(element.size)
                            elementPropChangesTracker(element.size)
                          "
                        />
                      </div>
                      <div>Name: {{ element.name }}</div>
                      <!-- <div>ID: {{ selectedElementData._id }}</div>
                      <div>LinkTo: {{ element.elemLinkTo }}</div> -->
                      <!-- <div>
                        ID:
                        {{
                          element.elemLinkTo.substring(
                            element.elemLinkTo.length - 7
                          )
                        }}
                      </div> -->
                    </div>
                  </div>
                  <div class="rightBtn">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="i === item.elements.length - 1"
                          @click="
                            elemActionClicked(
                              isContainerDirectionIsVertical(item)
                                ? 'down'
                                : 'right',
                              { element, elementIndex: i, item }
                            )
                          "
                          icon
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{
                            isContainerDirectionIsVertical(item)
                              ? 'mdi-menu-down'
                              : 'mdi-menu-right'
                          }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        isContainerDirectionIsVertical(item)
                          ? 'Move Down'
                          : 'Move Right'
                      }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <!-- IF ROOT PRIVIEW IS SELECTED -->
                <div
                  class="containerPreview parentDiv"
                  v-if="
                    inContainerElementPreview &&
                    !showSelectedElement(i, item.i, element.elemLinkTo)
                  "
                >
                  <template
                    v-if="
                      bindStyle[element.elemLinkTo] &&
                      bindStyle[element.elemLinkTo].visibility
                    "
                  >
                    <!-- && bindStyle[element.elemLinkTo].componentFn -->
                    <div
                      class="elementrender"
                      style="width: 100%; height: 100%"
                    >
                      <!-- <component
                        :key="element.elemLinkTo + 'elementrender'"
                        :is="bindStyle[element.elemLinkTo].componentFn"
                        v-bind="localVersionOfElementsData[element.elemLinkTo]"
                      /> -->
                      <BiElementBox
                        :elemConf="
                          localVersionOfElementsData[element.elemLinkTo]
                        "
                        :containerSpec="item"
                        :drill="element.drill"
                      />
                    </div>
                  </template>
                  <template
                    v-if="
                      !bindStyle[element.elemLinkTo] ||
                      !bindStyle[element.elemLinkTo].visibility
                    "
                  >
                    <!-- || !bindStyle[element.elemLinkTo].componentFn -->
                    <div class="noPreview">
                      <h4>
                        {{
                          (bindStyle[element.elemLinkTo] &&
                            bindStyle[element.elemLinkTo].message) ||
                          'No Preview'
                        }}
                      </h4>
                    </div>
                  </template>
                </div>
                <!-- <===============END-ELEMENT-CARD=================== -->
              </div>
            </div>
          </div>
        </div>
      </GridItem>
    </GridLayout>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import BiElementBox from '../../elements-components/bi-elem-box.vue'
import {
  createNamespacedHelpers as cnh,
  mapState as mapRootState,
  mapActions as mapRootActions
} from 'vuex'
const {
  mapState: mapReportBuilderState,
  mapMutations: mapReportBuilderMutations,
  mapActions: mapReportBuilderActions,
  mapGetters: mapReportBuilderGetters
} = cnh('ReportBuilder')
// const { mapMutations: mapNumberMutations } = cnh('NumbersConfig')

import { cloneDeep, isEmpty } from 'lodash'
import { ObjectID } from 'bson'
export default {
  components: {
    GridLayout,
    GridItem,
    BiElementBox
  },
  computed: {
    ...mapRootState(['report']),
    ...mapReportBuilderState([
      'layout',
      'selectedContainerIValue',
      'selectedContainerElementIndex',
      'selectedElementData',
      'inContainerElementPreview',
      'isAllElemsAndCurrentLayoutSaved',
      'localVersionOfElementsData'
    ]),
    ...mapReportBuilderGetters([
      'localVersionOfElementsDataGetter',
      'selectedElementComponentPreview'
    ]),
    cloneLayoutData: {
      get() {
        return cloneDeep(this.$store.state.ReportBuilder.layout)
      },
      set(newVala) {
        console.log({ newVala })
      }
    },
    // groundHeight(){
    idealGridRowHt() {
      let ht = (window.document.body.clientWidth * 2) / 3 / 12
      // console.log('window.d-b-width = ',window.document.body.clientWidth,' ht= ',ht)
      return ht
    }
  },
  data() {
    return {
      colNum: 12,
      rowHeight: 100,
      layoutBeforeMouseDown: null,
      layoutAfterMouseDown: null,
      bindStyle: {},
      editElemSize: false,
      selectedElementAction: false,
      selectedElementConfigChanges: 0,
      elementComponentPreview: {
        visibility: false,
        component: false
      }
      // cloneLayoutData: []
    }
  },
  methods: {
    ...mapReportBuilderMutations([
      'setSelectedContainerIValue',
      'setPreviousLayoutState',
      'setSelectedElementIndex',
      'addDataInLocalVersionOfElementsData',
      'toggleInContainerElementPreview',
      'setIsAllElemsAndCurrentLayoutSaved'
    ]),
    ...mapReportBuilderActions([
      'setLayoutStates',
      'updateLocalVersionOfElementsData',
      'detachDrillInfo'
    ]),
    ...mapRootActions(['openConfirmDialog']),
    // ...mapNumberMutations(['prefillSelectedElementData']),
    showSelectedElement(elemeidx, containterId, elemId) {
      if (this.inContainerElementPreview) {
        return (
          this.selectedContainerElementIndex == elemeidx &&
          this.selectedContainerIValue == containterId &&
          this.selectedElementData._id == elemId
        )
      } else {
        return true
      }
    },

    mouseDownEventHandler() {
      // this.layoutBeforeMouseDown = JSON.parse(
      //   JSON.stringify(this.layout, null, 4)
      // )
      // this.setPreviousLayoutState(cloneDeep(this.layoutBeforeMouseDown))
      // this.setIsAllElemsAndCurrentLayoutSaved(false)
    },

    mouseUpEventHandler() {
      // this.layoutAfterMouseDown = JSON.parse(
      //   JSON.stringify(this.layout, null, 4)
      // )
      // this.setPreviousLayoutState(cloneDeep(this.layoutBeforeMouseDown))
      this.layoutBeforeMouseDown = null
      this.layoutAfterMouseDown = null
    },
    setMouseEventListeners() {
      // console.log('---------------------------')
      this.$refs.layout.$el.addEventListener(
        'mousedown',
        this.mouseDownEventHandler
      )
      this.$refs.layout.$el.addEventListener(
        'mouseup',
        this.mouseUpEventHandler
      )
    },
    movedEvent: function (i, newX, newY) {
      console.log('MOVED i=' + i + ', X=' + newX + ', Y=' + newY, this)
      const clonedLayout = cloneDeep(this.layout)
      clonedLayout.forEach((el, idx) => {
        el.i = idx
      })
      this.setLayoutStates(clonedLayout)
      this.setPreviousLayoutState(cloneDeep(clonedLayout))
      this.setIsAllElemsAndCurrentLayoutSaved(false)
    },
    resizeEvent: function (i, newH, newW, newHPx, newWPx) {
      console.log(
        'RESIZE i=' +
          i +
          ', H=' +
          newH +
          ', W=' +
          newW +
          ', H(px)=' +
          newHPx +
          ', W(px)=' +
          newWPx
      )
      const clonedLayout = cloneDeep(this.layout)
      this.setPreviousLayoutState(cloneDeep(clonedLayout))
      this.setIsAllElemsAndCurrentLayoutSaved(false)
    },
    converter: (layout, breakpoint) => {
      let index = 0
      let layoutToReturn = []
      // pendingBreakpoint specifies the width of element that can be stored on same line
      let pendingBreakpoint = breakpoint
      for (let l of layout) {
        let x = 0
        let y = 0
        const elementGreaterThanPendingWidth = l.w > pendingBreakpoint
        if (elementGreaterThanPendingWidth) {
          if (index > 0) {
            // Calculate Height of upperElements and set y as the sum of height
            layoutToReturn
              .filter((l) => l.x === 0)
              .forEach((l) => {
                y = y + l.h
              })
          }
          pendingBreakpoint = breakpoint
        } else {
          // Here we are getting remainder to get element's position on new line
          x = l.x % breakpoint
          if (index > 0) {
            // Getting y of previous element for positioning element on same line
            y = layoutToReturn[index - 1].y
          }
        }
        pendingBreakpoint -= l.w
        layoutToReturn.push({
          ...l,
          x,
          y,
          i: index + 1,
          // Giving max width of breakpoint to elements with width greater than breakpoint
          w: l.w > breakpoint ? breakpoint : l.w
        })
        index++
      }
      return layoutToReturn
    },
    setSelectedElementIndexConf(i, item, element, elemRef) {
      console.log(
        '%c <=====SELECTED ELEEMENT=====>',
        'color:blue',
        'elementIndex:',
        i,
        this.selectedContainerElementIndex,
        item
      )
      this.setSelectedContainerIValue(item.i) //NOTE: do not change a position of calling this method
      this.setSelectedElementIndex({
        elementIndex: i,
        containerIValue: item.i,
        elemLinkTo: element.elemLinkTo
      })
      if (this.inContainerElementPreview) {
        // this.toggleInContainerElementPreview()
      }
      // this.prefillSelectedElementData(this.selectedElementData)
      if (i !== 0) this.selectedElementAction = false
    },
    getCSSAccordingToContainerDirection(direction, element) {
      if (element) {
        // console.log('direction, element :>> ', direction, element);
        return direction === 'vertical'
          ? {
              'min-height': `calc(100% / ${this.colNum} * ${element.size}`,
              height: `calc(100% / ${this.colNum} * ${element.size}`
            }
          : {
              'min-width': `calc(100% / ${this.colNum} * ${element.size}`,
              width: `calc(100% / ${this.colNum} * ${element.size}`
            }
        // console.log('t :>> ', t);
        // return t
      }
    },
    setSelectedContainerIValueMethod(i) {
      console.log('%c CONTAINER', 'color:red')
      this.setSelectedContainerIValue(i)
    },
    async getPriviewComponent(elementIndex, element) {
      this.resetElementComponentPreview()
      if (
        this.selectedContainerElementIndex > -1 &&
        elementIndex == this.selectedContainerElementIndex
      ) {
        this.elementComponentPreview.id = element.elemLinkTo
        if (this.selectedElementData.subType) {
          try {
            if (this.selectedElementData.configuration) {
              let comp = await this.previewComponent(this.selectedElementData)
              if (comp) {
                this.elementComponentPreview.componentFn = comp
                this.elementComponentPreview.visibility = true
                this.elementComponentPreview.configuration =
                  this.selectedElementData.configuration
              }
            } else {
              this.elementComponentPreview.message = 'No Configuration Provided'
            }
          } catch (error) {
            console.error(error)
          }
        }
      } else {
        console.log('priviewElsePart')
      }
      console.log('SINGLE COMPONENT PREVIEW', this.selectedElementData)
    },
    getBackgroundOLd(index = null, elem = null) {
      // console.log('elem, index :>> ', elem, index);
      if (index || (index === 0 && elem.elemLinkTo)) {
        if (this.inContainerElementPreview) {
          this.toggleInContainerElementPreview()
        }
        if (this.bindStyle[elem.elemLinkTo]) {
          delete this.bindStyle[elem.elemLinkTo]
          return
        }
        this.bindStyle = {
          [elem.elemLinkTo]: {}
        }
        const subType =
          this.localVersionOfElementsData[elem.elemLinkTo] &&
          this.localVersionOfElementsData[elem.elemLinkTo].subType
            ? this.localVersionOfElementsData[elem.elemLinkTo].subType
            : ''
        if (subType) {
          try {
            this.bindStyle[elem.elemLinkTo]['bgSrc'] =
              'background: url(' +
              require(`@/assets/previewImages/${subType}.png`) +
              ') no-repeat center; background-size: cover; opacity: 0.9; background-repeat: no-repeat;height: 100%;width: 100%;'
            this.bindStyle[elem.elemLinkTo]['visibility'] = true
          } catch (error) {
            this.bindStyle[elem.elemLinkTo]['visibility'] = true
            this.bindStyle[elem.elemLinkTo]['bgSrc'] =
              'background: url(' +
              require(`@/assets/previewImages/no_preview_available.png`) +
              '); background-size: contain; opacity: 0.9; background-repeat: no-repeat; background-position: center;'
            console.log('error :>> ', error)
          }
        }
        console.log('this.bindStyle :>> ', this.bindStyle)
        return this.bindStyle
      }
      console.log(
        'this.localVersionOfElementsData :>> ',
        this.localVersionOfElementsData
      )
      this.layout.forEach((item) => {
        item.elements.forEach((elem) => {
          if (!this.bindStyle[elem.elemLinkTo]) {
            this.bindStyle[elem.elemLinkTo] = {}
          }
          const subType =
            this.localVersionOfElementsData[elem.elemLinkTo] &&
            this.localVersionOfElementsData[elem.elemLinkTo].subType
              ? this.localVersionOfElementsData[elem.elemLinkTo].subType
              : ''
          if (subType) {
            try {
              this.bindStyle[elem.elemLinkTo]['bgSrc'] =
                'background: url(' +
                require(`@/assets/previewImages/${subType}.png`) +
                ')  no-repeat center; background-size: cover; opacity: 0.9; background-repeat: no-repeat;height: 100%;width: 100%;'
              this.bindStyle[elem.elemLinkTo]['visibility'] = true
            } catch (error) {
              this.bindStyle[elem.elemLinkTo]['visibility'] = true
              this.bindStyle[elem.elemLinkTo]['bgSrc'] =
                'background: url(' +
                require(`@/assets/previewImages/no_preview_available.png`) +
                ')  no-repeat center; background-size: contain; opacity: 0.9; background-repeat: no-repeat; background-position: center;'
              console.log('error :>> ', error)
            }
          } else {
            this.bindStyle[elem.elemLinkTo]['visibility'] = false
          }
        })
      })
      console.log('this.bindStyle :>> ', this.bindStyle)
      return this.bindStyle
    },
    getBackground() {
      // this.bindStyle = {}
      for (let item of this.layout) {
        for (let elem of item.elements || []) {
          if (!this.bindStyle[elem.elemLinkTo]) {
            this.bindStyle[elem.elemLinkTo] = {}
          }
          const elemConf = cloneDeep(
            this.localVersionOfElementsData[elem.elemLinkTo]
          )
          this.bindStyle[elem.elemLinkTo]['visibility'] = false
          if (elemConf && elemConf.subType) {
            try {
              if (elemConf.configuration) {
                // // let comp = await this.previewComponent(elemConf)
                // if (comp) {
                // this.bindStyle[elem.elemLinkTo].componentFn = comp

                this.bindStyle[elem.elemLinkTo]['visibility'] = true
                // }
              } else {
                this.bindStyle[elem.elemLinkTo]['message'] =
                  'No Configuration Provided'
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      }
      if (this.report && this.report.props && this.report.props.themeSelected) {
        if (this.report.props.themeSelected !== this.$biCtx.theme.name) {
          this.$biCtx.setTheme(this.report.props.themeSelected)
        }
      }
      console.log('computation complete')
      return this.bindStyle
    },
    cutElements(element, elementIndex, item) {
      this.selectedElementAction = {
        action: 'cut',
        elementIndex: elementIndex,
        containerIValue: item.i
      }
      console.log('CUT CUT')
      console.log(element, elementIndex, item)
    },
    pasteElements(selectedCont) {
      // console.log(`[selectedCont]: `, selectedCont);
      console.log('PASTING ELEMENT')
      const clonedLayout = cloneDeep(this.layout)
      this.setPreviousLayoutState(cloneDeep(clonedLayout))
      this.setIsAllElemsAndCurrentLayoutSaved(false)
      // const selectedContainerToPaste =  cloneDeep(clonedLayout[selectedCont.i])
      const selectedContainerForCut = cloneDeep(
        clonedLayout[this.selectedElementAction.containerIValue]
      )
      const selectedContainersElements = selectedContainerForCut.elements
      let elementCut =
        selectedContainersElements[this.selectedElementAction.elementIndex]
      console.log(
        `[elementCut]: `,
        elementCut,
        this.selectedElementAction.elementIndex
      )
      clonedLayout[selectedCont.i].elements.push(elementCut)
      selectedContainersElements.splice(
        this.selectedElementAction.elementIndex,
        1
      )
      clonedLayout[this.selectedElementAction.containerIValue].elements =
        selectedContainersElements
      this.setLayoutStates(clonedLayout)
      this.selectedElementAction = false
    },
    async elemActionClicked(
      actionType = '',
      { element = {}, elementIndex = null, item = {} }
    ) {
      let isOk = false
      let setLayout = false
      console.log('actionType:>> ', actionType)
      if (
        !isEmpty(element) &&
        !isEmpty(item) &&
        (elementIndex === 0 || elementIndex)
      ) {
        const clonedLayout = cloneDeep(this.layout)
        const selectedContainer = clonedLayout[item.i]
        const selectedContainersElements = selectedContainer.elements
        const selectedContainersElementsCopy = [...selectedContainersElements]
        let result = []
        // console.log('clonedLayout :>> ', clonedLayout);
        // console.log('selectedContainer :>> ', JSON.parse(JSON.stringify(selectedContainer)));
        // console.log('selectedContainersElements :>> ', selectedContainersElements);
        switch (actionType) {
          case 'delete':
            isOk = await this.openConfirmDialog({
              header: 'INFO',
              message:
                'Are you sure, You want to Delete this Element :- "' +
                element.name +
                '" ?'
            })
            if (isOk) {
              console.log('DELETE ELEMENT')
              selectedContainersElementsCopy.splice(elementIndex, 1)
              clonedLayout[item.i].elements = selectedContainersElementsCopy
              console.log(
                'selectedContainersElementAfter Delete :>> ',
                selectedContainersElementsCopy,
                element
              )
              this.updateLocalVersionOfElementsData({
                op: 'delete',
                _id: this.selectedElementData._id
              })
              this.detachDrillInfo({
                elementId: this.selectedElementData._id,
                prop: 'isElement'
              })
              setLayout = true
            }
            break
          case 'duplicate':
            console.log('DUPLICATE ELEMENT')
            const duplicateThisElement =
              selectedContainersElementsCopy[elementIndex]

            const _id = new ObjectID().toString()
            selectedContainersElementsCopy.push({
              size: duplicateThisElement.size,
              elemLinkTo: _id,
              name: duplicateThisElement.name
                ? duplicateThisElement.name + '_copy'
                : '',
              drill: duplicateThisElement.drill || {}
            })
            let elementConfig = cloneDeep(
              this.localVersionOfElementsData[
                duplicateThisElement.elemLinkTo
              ] || {}
            )

            elementConfig.id = _id
            elementConfig._id = _id
            elementConfig.isNewElem = true
            elementConfig.isSaved = false
            clonedLayout[item.i].elements = selectedContainersElementsCopy
            this.addDataInLocalVersionOfElementsData(elementConfig)
            setLayout = true
            console.log(
              ' duplicate :>> ',
              'new : ' + _id,
              'from : ' + duplicateThisElement.elemLinkTo,
              'selected : ' + this.selectedElementData._id,
              // this.selectedElementData,
              // this.selectedElementData+++++++++++++++++++++++++++++
              elementConfig
            )
            break
          case 'preview':
            console.log('PREVIEW ELEMENT')
            if (
              this.selectedElementData._id == this.elementComponentPreview.id
            ) {
              this.resetElementComponentPreview()
            } else {
              this.getPriviewComponent(elementIndex, element)
              console.log(
                'this.elementComponentPreview :>> ',
                this.elementComponentPreview
              )
            }
            this.$forceUpdate()
            break
          case 'down':
            console.log('SHIFT ELEMENT DOWN')
            result = this.swapArrayElement(
              selectedContainersElementsCopy,
              elementIndex,
              elementIndex + 1
            )
            if (result.length) {
              clonedLayout[item.i].elements = result
              setLayout = true
            }
            break
          case 'left':
            console.log('SHIFT ELEMENT LEFT')
            result = this.swapArrayElement(
              selectedContainersElementsCopy,
              elementIndex,
              elementIndex - 1
            )
            if (result.length) {
              clonedLayout[item.i].elements = result
              setLayout = true
            }
            break
          case 'up':
            console.log('SHIFT ELEMENT UP')
            result = this.swapArrayElement(
              selectedContainersElementsCopy,
              elementIndex,
              elementIndex - 1
            )
            if (result.length) {
              clonedLayout[item.i].elements = result
              setLayout = true
            }
            break
          case 'right':
            console.log('SHIFT ELEMENT RIGHT')
            result = this.swapArrayElement(
              selectedContainersElementsCopy,
              elementIndex,
              elementIndex + 1
            )
            if (result.length) {
              clonedLayout[item.i].elements = result
              setLayout = true
            }
            break
          default:
            console.log('INVALID ELEMENT ACTION TYPE')
            break
        }
        this.selectedElementAction = false
        // this.setSelectedElementIndexConf(elementIndex, item, element)
        if (setLayout) {
          console.log(
            'clonedLayout[item.i].elements :>> ',
            clonedLayout[item.i].elements
          )
          this.setLayoutStates(clonedLayout)
          this.setIsAllElemsAndCurrentLayoutSaved(false)
        }
      }
    },
    isContainerDirectionIsVertical(container = {}) {
      return (
        container.props &&
        container.props.direction &&
        container.props.direction === 'vertical'
      )
    },
    swapArrayElement(arr = [], idx1, idx2) {
      console.log('idx1, idx2 :>> ', idx1, idx2)
      if (idx1 <= arr.length && idx2 <= arr.length) {
        console.log('----------- SWAPPED ----------')
        const copy = [...arr]
        console.log('copy :>> ', copy)
        ;[copy[idx1], copy[idx2]] = [copy[idx2], copy[idx1]]
        return copy
      }
      return arr //original array
    },
    async previewComponent(selected) {
      try {
        let compPath = `../../elements-components/${selected.type.toLowerCase()}/${
          selected.subType
        }.vue`
        console.log('@@@@', compPath)
        // await import('../../elements-components/number/multiple_number.vue')
        // return () =>
        //   import('../../elements-components/number/multiple_number.vue')
        if (selected.type == 'graph') {
          return () =>
            import(`../../elements-components/Graphs/ChartPreview.vue`)
        }
        await import(
          `../../elements-components/${selected.type.toLowerCase()}/${
            selected.subType
          }.vue`
        )
        return () =>
          import(
            `../../elements-components/${selected.type.toLowerCase()}/${
              selected.subType
            }.vue`
          )
      } catch (error) {
        console.error(error)
        return false
      }
    },
    resetElementComponentPreview() {
      this.elementComponentPreview = {
        visibility: false,
        component: false
      }
    },
    elementPropChangesTracker(val) {
      console.log(`[val]: `, val, this.layout, 'curr', this.cloneLayoutData)
      this.setLayoutStates(cloneDeep(this.layout))
      this.setIsAllElemsAndCurrentLayoutSaved(false)
      // this.cloneLayoutData = false
    }
  },
  watch: {
    async inContainerElementPreview(nv) {
      console.log('inContainerElementPreview: ', nv)
      if (nv) this.resetElementComponentPreview()
      this.bindStyle = nv ? await this.getBackground() : {}
      console.log('this.bindStyle: ', this.bindStyle)
      this.$forceUpdate()
    },
    layout: {
      handler() {
        console.log(`%c LAYOUT `, 'color:purple;font-size:16px;', this.layout)
        // this.cloneLayoutData = cloneDeep(this.layout)
        // console.log(JSON.stringify(this.layout, '', 1))
        this.$forceUpdate()
        // console.log('$refs.layout.$el -> ',this.$refs.layout.$el);
      },
      deep: true
    },
    selectedContainerElementIndex(nv) {
      this.resetElementComponentPreview()
    }
    // async currentTab(nv, old){
    //   if(!this.isAllElemsAndCurrentLayoutSaved){
    //     const isOk = await this.openConfirmDialog({
    //       header: "Warning",
    //       message: 'Changes are Unsaved. Are you sure, you want to Navigate to other sectoion ?. Changes will be discarded.',
    //     });
    //     if (isOk) {

    //     }
    //   }
    // },
  },
  beforeDestroy() {
    this.$refs.layout.$el.removeEventListener(
      'mousedown',
      this.mouseDownEventHandler
    )
    this.$refs.layout.$el.removeEventListener(
      'mouseup',
      this.mouseUpEventHandler
    )
  }
}
</script>

<style scoped lang="scss">
$black-border: 1px solid grey;
.PASTE_BTN {
  position: absolute;
  // padding-left: 10px;
  // padding-top: 10px;
}
.parentDiv {
  display: flex;
  // border: 1px solid blue;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.button-size {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
// .leftBtn{
//    border: 1px solid;
//   // margin-top: 5%;
//   // background: red
// }
.mainContent {
  // background-color: rgb(231, 31, 31);
  text-align: center;
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
}
.elementContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// mainContent:hover .element-action-bar {
//  transform: scale(1);
//   background-color: whitesmoke;
// }
.element-action-bar {
  transform: scale(1);
}
// .element-action-bar:focus {
// }
// .rightBtn{
//   border: 1px solid;
//   // margin-bottom: 5%;
//   // background: rgb(47, 0, 255)
// }

#ground-area {
  max-width: 100%;
  max-height: 80vh; // previously it was 560px
  min-width: 100%;
  max-width: 1266px;
  min-height: 80vh; // previously it was 560px
  overflow-x: auto;
  margin-left: 10px;
  // background-color: $link-water;
  background-color: $white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // border: $black-border;
}
.container {
  border: $silver;
  background-color: #f2f7ff;
}
div.selected-container {
  border: 2px solid $blue;
}

div.selected-element {
  border: 1px solid $royal-blue;
  background-color: $white;
  background: linear-gradient(to right, $royal-blue 1px, transparent 4px) 0 0,
    linear-gradient(to right, $royal-blue 1px, transparent 4px) 0 100%,
    linear-gradient(to left, $royal-blue 1px, transparent 4px) 100% 0,
    linear-gradient(to left, $royal-blue 1px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, $royal-blue 1px, transparent 4px) 0 0,
    linear-gradient(to bottom, $royal-blue 1px, transparent 4px) 100% 0,
    linear-gradient(to top, $royal-blue 1px, transparent 4px) 0 100%,
    // linear-gradient(to center, $royal-blue 1px, transparent 4px) 100% 100%,
    linear-gradient(to top, $royal-blue 1px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
}
div.selected-cut-element {
  border: 2px dashed rgb(224, 77, 18);
}

.tool-sheet {
  display: inline-block;
  background-color: $link-water;
}

.tool-icon {
  max-width: 25px;
  display: inline-block;
  margin: auto;
  margin-top: 0.5rem;
}

.container-box {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: calc(100% - 20px);
  position: absolute;
  left: 0;
  top: 0;
}

.element-list {
  display: flex;
  margin: 0.25rem;
  height: 100%;
  overflow: auto;
}
.element-list-for-vertical {
  flex-direction: column;
}
.element {
  // border: $black-border;
  height: 100%;
  background-color: $light-purple;
}
.elemInfo {
  width: 100%;
  margin-top: 2.5em;
}
.txtSizeInput {
  border: 1px solid;
  width: 20px;
  text-align: center;
  margin-left: 0.2rem;
}
</style>
