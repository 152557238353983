<template>
  <div>
    <v-container grid-list-xs fluid style="padding:unset">
      <v-row wrap no-gutters>
        <v-col :cols="showSidePanel ? 9 : 11">
          <preview-area />
        </v-col>
        <v-col :cols="showSidePanel ? 3 : 1">
          <div class="panelContainer">
            <div class="toolbar" style="padding: 5px">
              <v-btn
                elevation="2"
                icon
                small
                @click="toggleSidePanel"
                :style="!showSidePanel ? 'box-shadow:unset' : ''"
                ><v-icon>{{
                  showSidePanel ? 'mdi-chevron-right' : 'mdi-chevron-left'
                }}</v-icon></v-btn
              >
              <v-btn
                v-if="selectedDevice != 'desktop'"
                style="float: right"
                depressed
                icon
                @click="setRotation()"
                ><v-icon> mdi-phone-rotate-portrait</v-icon></v-btn
              >
              <v-divider v-if="showSidePanel" class="mt-2"></v-divider>
            </div>
            <preview-panel v-if="showSidePanel" />
          </div>
          <!-- <ToolPanel /> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { createNamespacedHelpers as cnh } from 'vuex'
const { mapState: mapReportBuilderState } = cnh('ReportBuilder')
export default {
  components: {
    PreviewArea: () => import('./PreviewArea.vue'),
    PreviewPanel: () => import('./PreviewPanel.vue')
  },
  data() {
    return {
      showSidePanel: true
    }
  },
  computed: {
    ...mapReportBuilderState(['currentLayoutData']),
    selectedDevice() {
      return this.$store.state.PreviewStore.selectedDevice
    }
  },
  methods: {
    setRotation() {
      this.$store.commit('PreviewStore/setRotation')
    },
    toggleSidePanel() {
      this.showSidePanel = !this.showSidePanel
    }
  }
}
</script>

<style lang="scss" scoped>
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 92.6666666667% !important;
}
.panelContainer {
  // padding-left: 9%;
  //   border: 1px solid grey;
  max-height: 90vh;
  min-height: 90vh;
  width: 100%;
  overflow-y: auto;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
</style>
