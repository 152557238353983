<template>
  <div id="report-builder">
    <v-container fluid pa-5 grid-list-xs class="layouts-height">
      <v-row wrap>
        <v-col cols="6" class="pa-0">
          <v-tabs v-model="currentTab">
            <v-tab> All Layouts </v-tab>
            <v-tab> Tree Structure </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="6" md="3" offset-md="3" v-if="currentTab == 0" class="pa-0 searchbar-col">
          <v-text-field
            class="v-text-custom"
            prepend-inner-icon="mdi-magnify"
            outlined  
            dense
            label="Search by Layout name here"
            clearable
            v-model="searchText"
            @click:prepend-inner="searchLayout()"
          ></v-text-field>
          <!-- <v-text-field
            outlined
            label="Search by Layout name here"
          ></v-text-field> -->
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-divider style=""></v-divider>
        </v-col>
        <v-col cols="12">
          <v-tabs-items v-model="currentTab" style="background-color: #f8fafd">
            <v-tab-item>
              <v-container fluid grid-list-xs>
                <v-row wrap>
                  <div class="ma-2">
                    <v-card
                      elevation="0"
                      min-width="300"
                      max-width="300"
                      min-height="180"
                      max-height="180"
                      v-ripple
                      @click="showPredefinedLayouts"
                    >
                      <div
                        class="d-flex flex-column justify-center align-center"
                        style="
                          height: 180px;
                          cursor: pointer;
                          background: #d9e9f9;
                        "
                      >
                        <v-btn icon color="#2196f3">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <span style="color: #2196f3">Create New Layout</span>
                      </div>
                      <!-- <v-card-title> Top western road trips </v-card-title>
                      <v-card-subtitle> 1,000 miles of wonder </v-card-subtitle>
                      <v-card-actions>
                        <v-btn color="red darken-2" text> Explore </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions> -->
                    </v-card>
                  </div>
                  <div
                    class="ma-2"
                    v-for="layout of cloneItems"
                    :key="layout.id"
                  >
                    <LayoutCard
                      :layout="layout"
                      @editLayout="editLayout"
                      @previewLayout="previewLayout"
                    />
                  </div>
                </v-row>
              </v-container>
              <v-col cols="12">
                <v-pagination
                  v-model="pagination.page"
                  :length="pages"
                  @input="onPageChange"
                ></v-pagination>
              </v-col>
            </v-tab-item>
            <v-tab-item>
              <TreeStructure>
                <template #default="{ layout }">
                  <!-- {{ layout }} -->
                  <LayoutCard
                    :hide="true"
                    :layout="layout"
                    @editLayout="editLayout"
                    @previewLayout="previewLayout"
                  />
                </template>
              </TreeStructure>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
let id = 0
import { createNamespacedHelpers as cnh } from 'vuex'
const {
  mapState: mapReportBuilderState,
  mapMutations: mapReportBuilderMutations
} = cnh('ReportBuilder')
import LayoutCard from './LayoutCard.vue'
import TreeStructure from './TreeStructure.vue'
export default {
  components: {
    LayoutCard,
    TreeStructure
  },
  computed: {
    ...mapReportBuilderState([
      'allAvailableLayoutsData',
      'isAllElemsAndCurrentLayoutSaved'
    ]),
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.rowsPerPage)
    },
    cloneItems() {
      var clone = JSON.parse(JSON.stringify(this.allAvailableLayoutsDataCopy))
      var startFrom =
        (this.pagination.page * this.pagination.rowsPerPage) -
        this.pagination.rowsPerPage
      return clone.splice(startFrom, this.pagination.rowsPerPage)
    }
  },
  created() {
    // console.log('this.allAvailableLayoutsData :>> ', this.allAvailableLayoutsData);
    this.allAvailableLayoutsDataCopy = this.allAvailableLayoutsData
    // console.log('this.allAvailableLayoutsData.length :>> ', this.allAvailableLayoutsData.length);
    this.pagination.total = this.allAvailableLayoutsDataCopy.length
  },
  data() {
    return {
      pagination: {
        page: 1,
        total: 0,
        rowsPerPage: 7
      },
      allAvailableLayoutsDataCopy: [],
      searchText: '',
      page: 1,
      currentTab: 0,
      layouts: [
        {
          identity: ++id,
          name: 'Monthly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Quarterly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Yearly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Half Yearly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Incentive 20k Report',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Consolidated Report',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Monthly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Monthly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Monthly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        },
        {
          identity: ++id,
          name: 'Monthly Sales Dashboard',
          displayName: 'Sales Report',
          record: {
            updatedOn: new Date()
          },
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper at felis ac malesuada. In finibus porttitor velit eget scelerisque. Mauris metus felis, pulvinar vitae venenatis eu, luctus a nibh. Integer venenatis tellus a pulvinar suscipit. In a tortor ex. Curabitur quis ullamcorper tellus. Aenean ut ultricies elit, sit amet lacinia dolor. Cras nec feugiat odio.'
        }
      ]
    }
  },
  methods: {
    ...mapReportBuilderMutations(['setCurrentTab']),
    onPageChange(event) {
      console.log(event)
    },
    editLayout(layout) {
      console.log('layout edit :>> ', layout)
      this.$emit('manageLayout', { mode: 'edit', payload: layout })
    },
    showPredefinedLayouts() {
      if (!this.isAllElemsAndCurrentLayoutSaved) {
        this.$store.commit(
          'openSnackbarWithTime',
          {
            txt: 'Current Edited layout is unsaved. First Save it.',
            time: 5000
          },
          { root: true }
        )
        setTimeout(() => {
          this.setCurrentTab(0)
        }, 2000)
        return
      }
      this.$emit('openPredefinedLayouts')
    },
    previewLayout(layout) {
      this.$emit('previewSelectedLayout', layout)
    },
    searchLayout() {
      if (this.searchText) {
        let searchedlayoutData = this.allAvailableLayoutsData.filter(
          ({ name }) => name.match(this.searchText)
        )
        this.allAvailableLayoutsDataCopy = []
        this.allAvailableLayoutsDataCopy = [...searchedlayoutData]
        this.pagination.total = this.allAvailableLayoutsDataCopy.length
        this.pagination.page = 1
      }
    }
  },
  watch: {
    searchText(nv) {
      if (!nv) {
        this.allAvailableLayoutsDataCopy = this.allAvailableLayoutsData
        this.pagination.total = this.allAvailableLayoutsDataCopy.length
      }
      // this.searchLayout()
    },
    currentTab(nv) {
      this.searchText = ''
    },
    allAvailableLayoutsData() {
      this.allAvailableLayoutsDataCopy = this.allAvailableLayoutsData
      this.pagination.total = this.allAvailableLayoutsDataCopy.length
    }
  }
}
</script>
<style lang="scss" scoped>
.layouts-height {
  height: 90vh;
  max-height: 90vh;
}
.request-button {
  background-color: $persian-blue !important;
  color: white;
}
</style>

<style lang="scss">
.v-text-custom {
  .v-input__slot {
    min-height: 40px !important;
    width: 90%;
  }
  .v-input__prepend-inner {
    margin-top: 10px;
  }
  .v-text-field__slot .v-label {
    top: 11px;
  }
  .v-tabs-items{
    background-color: blue !important;
  }
}
.v-application .primary {
    background-color: blue !important;
    border-color: blue !important;
}
.searchbar-col{
  height: 40px;
}
</style>