<template>
  <v-dialog
    :value="arrangeChildrenDialogStatus"
    persistent
    :overlay="false"
    max-width="800px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0">Arrange children</h3>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <draggable
          v-model="modifiedElements"
          group="people"
          @start="drag = true"
          @end="drag = false"
          class="element-list"
        >
          <div
            class="element-box"
            :style="`min-width: calc(100% / 12 * ${element.size});width: calc(100% / 12 * ${element.size});`"
            v-for="(element, idx) in modifiedElements"
            :key="element.i"
          >
            <div class="element">
              <div>
                <v-btn icon @click="removeItem(idx)">
                  <Icon name="trash-bin" class="tool-icon" />
                </v-btn>
                <span class="">{{ element.name }}</span>
              </div>
              <v-text-field
                class="ma-2"
                outlined
                v-model.number="element.size"
              />
            </div>
          </div>
        </draggable>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeArrangeChildrenDialog">Close</v-btn>
        <v-btn text color="primary" @click="saveNewLayout">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import { cloneDeep, isEqual } from 'lodash'
import { createNamespacedHelpers as cnh } from 'vuex'
const {
  mapState: mapReportBuilderState,
  mapGetters: mapReportBuilderGetters,
  mapActions: mapReportBuilderActions,
  mapMutations: mapReportBuilderMutations
} = cnh('ReportBuilder')

export default {
  props: {
    arrangeChildrenDialogStatus: Boolean
  },
  components: {
    draggable
  },
  computed: {
    ...mapReportBuilderState(['layout']),
    ...mapReportBuilderGetters(['selectedContainerIndex']),
    elements() {
      const container = this.layout[this.selectedContainerIndex]
      return container ? cloneDeep(container.elements) : []
    }
  },
  data() {
    return {
      modifiedElements: []
    }
  },
  watch: {
    elements: {
      handler: 'setModifiedElements',
      immediate: true
    }
  },
  methods: {
    ...mapReportBuilderActions(['setLayoutStates', 'updateLocalVersionOfElementsData']),
    ...mapReportBuilderMutations([
      // 'updateLocalVersionOfElementsData',
      'setIsAllElemsAndCurrentLayoutSaved'
    ]),
    removeItem(idx) {
      this.modifiedElements.splice(idx, 1)
    },
    setModifiedElements() {
      let i = 0
      this.modifiedElements = this.elements.map((e) => ({ ...e, i: i++ }))
    },
    saveNewLayout() {
      // const copy_SelectedContainerIndex = this.selectedContainerIndex
      const newElements = cloneDeep(this.modifiedElements).map((e) => {
        delete e.i
        return e
      })
      const oldElements = [...this.layout[this.selectedContainerIndex].elements]
      // console.log('New Elements :>> ', newElements);
      // console.log('Old Elements :>> ', oldElements);

      // const final1 = {}
      const final2 = {}
      // oldElements.forEach((el, idx) => {
      //   final1[`${this.selectedContainerIndex}_${idx}`] = el
      // })
      let changed = false
      oldElements.forEach((oldEl, oldIdx) => {
        if (!isEqual(oldEl, newElements[oldIdx])) {
          // console.log("----------------------------");
          // console.log('oldIdx :>> ', oldIdx);
          // console.log('oldEl :>> ', oldEl);
          // console.log('newEl :>> ', newElements[oldIdx]);
          // console.log("----------------------------");
          if (!newElements[oldIdx]) {
            //  this.updateLocalVersionOfElementsData({ op: "delete", key: `${this.selectedContainerIndex}_${oldIdx}` })
            return
          }
          if (!changed) changed = true
          // final2[`${this.selectedContainerIndex}_${oldIdx}`] = oldElements[oldIdx]
          final2[oldElements[oldIdx].elemLinkTo] = oldElements[oldIdx]
          // this.updateLocalVersionOfElementsData({ op: "update", elementIdx: oldIdx, key: `${this.selectedContainerIndex}_${oldIdx}`, data: newElements[oldIdx] })
        }
        // newElements.forEach((newEl, newIdx) => {
        //   // console.log('!isEqual(oldEl, newEl :>> ', !isEqual(oldEl, newEl));
        // if(!isEqual(oldEl, newEl)){
        //   console.log('oldIdx, newIdx :>> ', oldIdx, newIdx);
        // }
        // })
      })

      const keysToDelete = Object.keys(final2)
      this.updateLocalVersionOfElementsData({ op: 'delete', _id: keysToDelete })

      console.log('keysToDelete :>> ', keysToDelete)
      console.log('changed: ' + changed)
      // console.log('final1 :>> ', final1);
      console.log('final2 :>> ', final2)

      const layout = cloneDeep(this.layout)
      layout[this.selectedContainerIndex].elements = newElements
      this.setLayoutStates(layout)
      if (changed) {
        this.setIsAllElemsAndCurrentLayoutSaved(false)
      }
      this.closeArrangeChildrenDialog()
    },
    closeArrangeChildrenDialog() {
      this.setModifiedElements()
      this.$emit('close-arrange-children-dialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.element-list {
  display: flex;
  margin: 1%;
  height: 150px;
  overflow: auto;
}
.element-box {
  flex-grow: 1;
}

.element {
  border: 1px solid black;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
  cursor: move;
}
</style>
