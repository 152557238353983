<template>
  <div>
    <div class="d-flex justify-space-between align-center ma-2 pl-2 pr-2">
      <div class="switcher-button" style="background-color: #F8FAFD;">
        <span class="switcher switcher-1">
          <input
            type="checkbox"
            id="switcher-1"
            @change="checkForOnchange($event)"
            checked
          />
          <label for="switcher-1"></label>
        </span>
      </div>
      <div>
        <v-btn
          small
          color="royal-blue"
          @click="$emit('cancelLayoutSelection')"
          >Cancel</v-btn
        >
      </div>
    </div>
    <v-divider style="margin: 0px 20px"></v-divider>
    <div>
      <!-- <v-dialog
          v-model="predifinedLayoutsDialog"
          transition="dialog-bottom-transition"
          max-width="1000"
          persistent
        > -->
      <v-card class="pa-2" min-height="500" max-height="1000" flat style="background-color: #F8FAFD;border-radius: 0">
        <!-- <div class="d-flex justify-end">
          <v-btn text @click="$emit('cancelLayoutSelection')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div> -->
        <v-container fluid grid-list-xs>
          <v-row wrap>
            <div class="ma-2" @click="createNewLayout([])">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card
                  :elevation="hover ? 5 : 0"
                  class="mx-auto predefinedLayoutCard"
                  max-width="220"
                  max-height="220"
                  style="padding: 5px; border: 2px solid rgba(0, 0, 0, 0.1)"
                  flat
                >
                  <v-img
                    class="white--text align-end"
                    height="150px"
                    :src="require(`@assets/images/custom_layout.png`)"
                  >
                  </v-img>
                  <div class="d-flex justify-space-between pa-2 align-center">
                    <span style="font-size: 14px">Custom Layout</span>
                    <!-- <v-menu bottom origin="center center">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, i) in items" :key="i" link>
                        <v-list-item-title
                          @click="submitOption(item.key, layout)"
                          >{{ item.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu> -->
                  </div>
                </v-card>
              </v-hover>
            </div>
            <div
              class="ma-2"
              v-for="(layout, index) of allPredefinedLayouts"
              :key="layout.id + index"
              @click="createNewLayout(layout.payload)"
            >
              <PredefinedLayoutCard
                :layout="layout"
                @submitOption="submitOption"
              />
            </div>
          </v-row>
        </v-container>
      </v-card>
      <!-- </v-dialog> -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as cnh } from 'vuex'
import PredefinedLayoutCard from './PredefinedLayoutCard.vue'
const {
  mapState: mapReportBuilderState,
  mapMutations: mapReportBuilderMutations
} = cnh('ReportBuilder')
export default {
  props: [''],
  components: {
    PredefinedLayoutCard
  },
  data() {
    return {
      allPredefinedLayouts: [
        {
          id: 1,
          name: 'Horizontal Layout',
          src: 'horizontal_layout',
          payload: [
            {
              x: 0,
              y: 0,
              w: 12,
              h: 4,
              i: 0,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121502eb7041d2bed63ea3',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 0,
              y: 4,
              w: 2,
              h: 8,
              i: 1,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 2,
              y: 4,
              w: 3,
              h: 8,
              i: 2,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 5,
              y: 4,
              w: 7,
              h: 8,
              i: 3,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121570eb7041d2bed63ea4',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            }
          ]
        },
        {
          id: 2,
          name: 'Mixed Layout 1',
          src: 'mixed_layout1',
          payload: [
            {
              x: 0,
              y: 0,
              w: 2,
              h: 4,
              i: 0,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121af4c20aa35f9f3be154',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 2,
              y: 0,
              w: 4,
              h: 4,
              i: 1,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 6,
              y: 0,
              w: 4,
              h: 4,
              i: 2,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 10,
              y: 0,
              w: 2,
              h: 4,
              i: 3,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 0,
              y: 4,
              w: 7,
              h: 6,
              i: 4,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121b33c20aa35f9f3be155',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 7,
              y: 4,
              w: 5,
              h: 6,
              i: 5,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            }
          ]
        },
        {
          id: 3,
          name: 'Mobile Layout',
          src: 'mobile_layout',
          payload: [
            {
              x: 0,
              y: 0,
              w: 12,
              h: 5,
              i: 0,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121c9aa4c362190d101db1',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 0,
              y: 5,
              w: 12,
              h: 5,
              i: 1,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            }
          ]
        },
        {
          id: 4,
          name: 'Vertical Layout',
          src: 'vertical_layout',
          payload: [
            {
              x: 0,
              y: 0,
              w: 5,
              h: 10,
              i: 0,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121cf2a4c362190d101db2',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 5,
              y: 0,
              w: 4,
              h: 10,
              i: 1,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 9,
              y: 0,
              w: 3,
              h: 10,
              i: 4,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            }
          ]
        },
        {
          id: 5,
          name: 'Mixed Layout 2',
          src: 'mixed_layout1',
          payload: [
            {
              x: 0,
              y: 0,
              w: 2,
              h: 4,
              i: 0,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121af4c20aa35f9f3be154',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 2,
              y: 0,
              w: 4,
              h: 4,
              i: 1,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 6,
              y: 0,
              w: 4,
              h: 4,
              i: 2,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 10,
              y: 0,
              w: 2,
              h: 4,
              i: 3,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 0,
              y: 4,
              w: 7,
              h: 6,
              i: 4,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '61121b33c20aa35f9f3be155',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            },
            {
              x: 7,
              y: 4,
              w: 5,
              h: 6,
              i: 5,
              elements: [
                {
                  size: 12,
                  elemLinkTo: '',
                  name: ''
                }
              ],
              props: {
                direction: 'horizontal',
                type: 'simple'
              },
              moved: false
            }
          ]
        }
      ],
      items: [{ title: 'Preview', key: 'preview' }],
      devicesPrior: 'desktop'
    }
  },
  computed: {
    ...mapReportBuilderState(['predifinedLayoutsDialog'])
  },
  methods: {
    ...mapReportBuilderMutations([
      'togglePredifinedLayoutsDialog',
      'setSelectedPreviewLayout',
      'setCurrentTab'
    ]),
    createNewLayout(layout = {}) {
      this.$emit('manageLayout', {
        mode: 'create',
        payload: layout,
        devicesPrior: this.devicesPrior
      })
      this.setSelectedPreviewLayout(layout)
      // this.togglePredifinedLayoutsDialog()
    },
    submitOption(action, layout) {
      if (action == 'preview') {
        this.setSelectedPreviewLayout(layout)
        this.setCurrentTab(3)
        return
      }
    },
    checkForOnchange(event) {
      // window.getComputedStyle(val.target)
      if (!event.target.checked) {
        this.devicesPrior = 'mobile'
      } else {
        this.devicesPrior = 'desktop'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.predefinedLayoutCard {
  cursor: pointer;
}

.switch-button {
  // background: rgba(255, 255, 255, 0.56);
  background: #c1c1c1;
  border-radius: 30px;
  overflow: hidden;
  width: 15%;
  height: 40px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: #fff;
  position: relative;
  padding-right: 7.5rem;
  position: relative;

  &:before {
    content: 'Mobile';
    position: absolute;
    color: #fff;
    top: 0;
    bottom: 0;
    right: 7px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(120px);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 6px;
      display: block;
      user-select: none;
      // width: 80px;
      pointer-events: none;

      &:before {
        content: '';
        background: $royal-blue;
        height: 100%;
        width: 100%;
        position: absolute;
        left: -2px;
        top: 0;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}

$black: #1e1e1e;
$grey: #cccccc;
$white: #ffffff;

.switcher-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  font-size: 14px;
  span.switcher {
    position: relative;

    width: 165px;
    height: 30px;
    border-radius: 25px;
    margin: 8px 0;
    input {
      // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      //   rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      box-shadow: rgb(60 45 56 / 25%) 1.95px 1.95px 3px 1px;
      background: rgba(255, 255, 255, 0.56);
      appearance: none;

      position: relative;

      width: 165px;
      height: 30px;
      border-radius: 25px;

      background-color: $black;
      outline: none;

      font-family: $body-font-family;
      &:before,
      &:after {
        z-index: 2;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        color: $white;
      }
      &:before {
        content: 'Desktop';
        left: 15px;
      }
      &:after {
        content: 'Mobile';
        right: 15px;
      }
    }
    label {
      z-index: 1;
      position: absolute;
      top: 0px;
      bottom: 0px;

      border-radius: 25px;
    }
    &.switcher-1 {
      input {
        transition: 0.25s -0.1s;
        &:checked {
          background-color: $white;
          &:before {
            color: $white;
            transition: color 0.5s 0.2s;
          }
          &:after {
            color: $black;
            transition: color 0.5s;
          }
          & + label {
            left: 0px;
            right: 80px;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
              0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            background: #2a51e8;

            transition: left 0.5s, right 0.4s 0.2s;
          }
        }
        &:not(:checked) {
          background: $white;
          transition: background 0.5s -0.1s;
          &:before {
            color: $black;
            transition: color 0.5s;
          }
          &:after {
            color: $white;
            transition: color 0.5s 0.2s;
          }
          & + label {
            left: 90px;
            right: 0px;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
              0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            background: #2a51e8;

            transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
          }
        }
      }
    }
  }
}

.v-application .royal-blue {
  background-color: $royal-blue !important;
  border-color: $royal-blue !important;
  color: white;
}
</style>
