import { render, staticRenderFns } from "./TreeStructure.vue?vue&type=template&id=082f479c&scoped=true&"
import script from "./TreeStructure.vue?vue&type=script&lang=js&"
export * from "./TreeStructure.vue?vue&type=script&lang=js&"
import style0 from "./TreeStructure.vue?vue&type=style&index=0&id=082f479c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "082f479c",
  null
  
)

export default component.exports