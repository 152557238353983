var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ground-area"}},[_c('GridLayout',{ref:"layout",attrs:{"id":"layout","layout":_vm.layout,"col-num":_vm.colNum,"row-height":_vm.idealGridRowHt,"margin":[10, 10],"preserve-aspect-ratio":""},on:{"layout-mounted":_vm.setMouseEventListeners}},_vm._l((_vm.layout),function(item,idx){return _c('GridItem',{key:idx,class:[
        'container',
        _vm.selectedContainerIValue === item.i ? 'selected-container' : ''
      ],attrs:{"minH":1,"minW":2,"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":idx},on:{"resize":_vm.resizeEvent,"moved":_vm.movedEvent}},[_c('div',{staticClass:"container-box",on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedContainerIValueMethod(item.i)}}},[(
            _vm.selectedContainerIValue === item.i &&
            !!_vm.selectedElementAction &&
            _vm.selectedElementAction.containerIValue !== item.i
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"PASTE_BTN",attrs:{"fab":"","color":"primary","small":"","class1":""},on:{"click":function($event){$event.stopPropagation();return _vm.pasteElements(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-content-paste")])],1)]}}],null,true)},[_c('span',[_vm._v("Paste")])]):_vm._e(),(item.elements)?_c('div',{class:("element-list " + (item.props && item.props.direction === 'vertical'
              ? 'element-list-for-vertical'
              : ''))},_vm._l((item.elements),function(element,i){return _c('div',{key:i + 'container-element',staticStyle:{"padding":"5px"},style:(_vm.getCSSAccordingToContainerDirection(
                item.props && item.props.direction
                  ? item.props.direction
                  : '',
                element
              ))},[_c('div',{class:[
                'd-flex flex-column align-center element',
                _vm.selectedContainerIValue === item.i &&
                _vm.selectedContainerElementIndex === i
                  ? 'selected-element'
                  : '',
                !!_vm.selectedElementAction &&
                _vm.selectedElementAction.elementIndex == i &&
                _vm.selectedElementAction.containerIValue == item.i
                  ? 'selected-cut-element'
                  : ''
              ],style:(("" + (_vm.inContainerElementPreview ? 'background-color:white' : ''))),on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedElementIndexConf(i, item, element)}}},[(
                  _vm.selectedContainerIValue === item.i &&
                  _vm.selectedContainerElementIndex === i &&
                  !_vm.inContainerElementPreview
                )?_c('div',{staticClass:"d-flex flex-row-reverse my-1",style:(("width:100%; padding-right: 1%; position: " + (_vm.isContainerDirectionIsVertical(item)
                    ? 'absolute'
                    : 'relative') + "; " + (_vm.isContainerDirectionIsVertical(item) ? 'z-index:123' : '')))},[(item.elements.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-size",attrs:{"icon":"","small-xs":""},on:{"click":function($event){$event.stopPropagation();return _vm.elemActionClicked('delete', {
                          element: element,
                          elementIndex: i,
                          item: item
                        })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-size",attrs:{"x-small":"","icon":"","small-xs":""},on:{"click":function($event){$event.stopPropagation();return _vm.elemActionClicked('duplicate', {
                          element: element,
                          elementIndex: i,
                          item: item
                        })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-size",attrs:{"x-small":"","disabled":!!_vm.selectedElementAction,"icon":"","small-xs":""},on:{"click":function($event){$event.stopPropagation();return _vm.cutElements(element, i, item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-cut")])],1)]}}],null,true)},[_c('span',[_vm._v("Cut")])])],1):_vm._e(),(
                  !_vm.inContainerElementPreview ||
                  _vm.showSelectedElement(i, item.i, element.elemLinkTo)
                )?_c('div',{staticClass:"parentDiv"},[_c('div',{staticClass:"leftBtn"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":i === 0,"icon":"","color":"blue"},on:{"click":function($event){_vm.elemActionClicked(
                            _vm.isContainerDirectionIsVertical(item)
                              ? 'up'
                              : 'left',
                            { element: element, elementIndex: i, item: item }
                          )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.isContainerDirectionIsVertical(item) ? 'mdi-menu-up' : 'mdi-menu-left'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isContainerDirectionIsVertical(item) ? 'Move Up' : 'Move Left'))])])],1),_c('div',{staticClass:"mainContent"},[(
                      _vm.selectedElementData._id == element.elemLinkTo &&
                      _vm.selectedElementComponentPreview.id == element.elemLinkTo
                    )?_c('div',{staticClass:"elementPreview",staticStyle:{"width":"100%","height":"100%"}},[(_vm.selectedElementComponentPreview.visibility)?[_c('div',{staticClass:"elementrender",staticStyle:{"width":"100%","height":"100%"}},[_c('BiElementBox',{key:element.elemLinkTo + 'elementComponentPreview',attrs:{"elemConf":_vm.localVersionOfElementsData[element.elemLinkTo],"containerSpec":item}})],1)]:[_c('div',{staticClass:"noPreview",staticStyle:{"transform":"translate(-50%, -50%)","position":"absolute","top":"50%","left":"50%"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.selectedElementComponentPreview.message || 'No Preview')+" ")])])]],2):(
                      _vm.inContainerElementPreview
                        ? _vm.selectedElementData._id == element.elemLinkTo
                        : true
                    )?_c('div',{staticClass:"elementContent",staticStyle:{"font-size":"10px","font-weight":"500"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',[_vm._v("Size:")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(element.size),expression:"element.size",modifiers:{"number":true}}],staticClass:"txtSizeInput",attrs:{"type":"text"},domProps:{"value":(element.size)},on:{"change":function($event){element.size =
                            element.size > 12
                              ? 12
                              : element.size <= 0
                              ? 1
                              : Number(element.size)
                          _vm.elementPropChangesTracker(element.size)},"input":function($event){if($event.target.composing){ return; }_vm.$set(element, "size", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',[_vm._v("Name: "+_vm._s(element.name))])]):_vm._e()]),_c('div',{staticClass:"rightBtn"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":i === item.elements.length - 1,"icon":"","color":"blue"},on:{"click":function($event){_vm.elemActionClicked(
                            _vm.isContainerDirectionIsVertical(item)
                              ? 'down'
                              : 'right',
                            { element: element, elementIndex: i, item: item }
                          )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.isContainerDirectionIsVertical(item) ? 'mdi-menu-down' : 'mdi-menu-right'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isContainerDirectionIsVertical(item) ? 'Move Down' : 'Move Right'))])])],1)]):_vm._e(),(
                  _vm.inContainerElementPreview &&
                  !_vm.showSelectedElement(i, item.i, element.elemLinkTo)
                )?_c('div',{staticClass:"containerPreview parentDiv"},[(
                    _vm.bindStyle[element.elemLinkTo] &&
                    _vm.bindStyle[element.elemLinkTo].visibility
                  )?[_c('div',{staticClass:"elementrender",staticStyle:{"width":"100%","height":"100%"}},[_c('BiElementBox',{attrs:{"elemConf":_vm.localVersionOfElementsData[element.elemLinkTo],"containerSpec":item,"drill":element.drill}})],1)]:_vm._e(),(
                    !_vm.bindStyle[element.elemLinkTo] ||
                    !_vm.bindStyle[element.elemLinkTo].visibility
                  )?[_c('div',{staticClass:"noPreview"},[_c('h4',[_vm._v(" "+_vm._s((_vm.bindStyle[element.elemLinkTo] && _vm.bindStyle[element.elemLinkTo].message) || 'No Preview')+" ")])])]:_vm._e()],2):_vm._e()])])}),0):_vm._e()],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }