<template>
  <div class="MAIN-COMPOSER">
    <v-toolbar elevation="0" class="toolbarWrapper" dense>
      <v-toolbar-title class="overrideTitle">
        {{
          report && Object.keys(report).length
            ? report.displayName
            : 'Fetching...'
        }}
      </v-toolbar-title>
      <v-dialog transition="dialog-top-transition" max-width="600" persistent>
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >From the top</v-btn> -->
          <v-btn
            outlined
            fab
            small
            v-bind="attrs"
            color="royal-blue"
            v-on="on"
            class="ml-5 mr-2"
          >
            <v-icon style="font-size: 20px">mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <!-- <v-toolbar color="primary" dark>Opening from the top</v-toolbar> -->
            <div class="modalHeader">
              <v-card-title>
                {{ report.displayName }}
                <!-- <v-spacer />
                <v-btn text @click="dialog.value = false"><v-icon>mdi-close</v-icon></v-btn> -->
              </v-card-title>
              <v-btn class="mr-1" color="pink" text @click="dialog.value = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>

            <!-- <v-card-text>
              <div class="text-h2 pa-12">Hello world!</div>
            </v-card-text> -->
            <!-- class="ml-5 pl-5" -->
            <v-tabs v-model="currentReportTab" class="v-tab-color">
              <v-tab class="ml-2"> Report </v-tab>
              <!-- <v-tab> Initial Parameters </v-tab> -->
              <v-tab> Supplements </v-tab>
               <v-tab> Themes </v-tab>
            </v-tabs>
            <!-- <v-divider></v-divider> -->
            <v-tabs-items v-model="currentReportTab">
              <v-tab-item>
                <report @updateReportProps="updateReportProps" />
              </v-tab-item>
              <!-- <v-tab-item> <intial-parameters /></v-tab-item> -->
              <v-tab-item>
                <report-supplements @updateReportProps="updateReportProps" />
              </v-tab-item>
              <v-tab-item>
                <report-themes @updateReportProps="updateReportProps" />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </template>
      </v-dialog>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            outlined
            fab
            color="royal-blue"
            small
            class=""
          >
            <v-icon style="font-size: 20px"
              >mdi-format-list-bulleted-square</v-icon
            >
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in reportMenuItems"
            :key="i + item.key"
            link
          >
            <v-list-item-title @click="submitReportMenuOption(item.key)">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
          <!-- Published History Dialog START-->
          <div class="text-xs-center">
            <v-dialog v-model="showPublishedHistoryDialog" width="800">
              <v-toolbar>
                <v-btn
                  icon
                  text
                  color="pink"
                  @click="showPublishedHistoryDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ showToolBarTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card>
                <v-card-text>
                  <v-alert
                    style="margin: 0 10px 0 10px"
                    v-if="reportPublishUnPublishHistory.length === 0"
                    dense
                    outlined
                    type="error"
                  >
                    <strong>{{ errorText }}</strong>
                  </v-alert>
                  <template v-if="reportPublishUnPublishHistory.length !== 0">
                    <v-timeline dense align-top>
                      <v-timeline-item
                        v-for="(history, i) in reportPublishUnPublishHistory"
                        :key="i"
                        :color="`${
                          history.type == 'publish'
                            ? 'indigo'
                            : history.type === 'unpublish'
                            ? 'red lighten-1'
                            : 'purple lighten-1'
                        }`"
                        :icon="`${
                          history.type == 'publish'
                            ? 'mdi-buffer'
                            : history.type === 'unpublish'
                            ? 'mdi-book-variant'
                            : 'mdi-buffer'
                        }`"
                        fill-dot
                      >
                        <v-card
                          :color="`${
                            history.type == 'publish'
                              ? 'indigo'
                              : history.type === 'unpublish'
                              ? 'red lighten-1'
                              : 'purple lighten-1'
                          }`"
                          dark
                        >
                          <v-card-title>{{
                            new Date(history.record.createdOn)
                          }}</v-card-title>
                          <v-card-text class="white" style="color: black">
                            <span>Revision: {{ history.revision }}</span> <br />
                            <span class="text-capitalize"
                              >Type: {{ history.type }}</span
                            >
                            <!-- <v-btn
                          :color="item.color"
                          class="mx-0"
                          outline
                        >
                          Button
                        </v-btn> -->
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </template>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="showPublishedHistoryDialog = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <!-- Published History Dialog END -->
        </v-list>
      </v-menu>
      <v-tabs
        class="ml-5 pl-5 v-tab-ovverride"
        active-class="active-tab"
        slider-size="0"
        v-model="currentTab"
      >
        <v-tab> Composer </v-tab>
        <v-tab> Layouts </v-tab>
        <v-tab> Supplement </v-tab>
        <!-- <v-tab> Preview </v-tab> -->
      </v-tabs>
      <!-- <v-toolbar-items> -->
      <v-btn
        outlined
        rounded
        color="#1B3EC4"
        small @click="saveLayout"
      >
        <div class="round-btn-wrapper">
          <span
            class="dot"
            :style="`color: ${
              isAllElemsAndCurrentLayoutSaved &&
              $store.state.ReportBuilder.isLayoutSave
                ? '#4af30e'
                : '#f52222'
            }`"
            >●</span
          >
          <span style="color: black">
            {{
              isAllElemsAndCurrentLayoutSaved &&
              $store.state.ReportBuilder.isLayoutSave
                ? 'Saved'
                : 'Unsaved'
            }}
          </span>
        </div>
      </v-btn>
      <!-- </v-toolbar-items> -->
    </v-toolbar>
    <v-divider></v-divider>
    <v-tabs-items v-model="currentTab" style="background-color: #f8fafd">
      <v-tab-item style="background-color: #f8fafd">
        <template v-if="!layoutSelection">
          <ReportBuilder @previewSelectedLayout="previewSelectedLayout" ref="reportBuilder" />
        </template>
        <template v-if="layoutSelection">
          <PredefinedLayouts
            @manageLayout="manageLayout"
            @cancelLayoutSelection="cancelLayoutSelection"
          />
        </template>
      </v-tab-item>
      <!-- <v-tab-item
        v-if="layoutSelection"
        id="Predefined-layout"
        style="background-color: #f8fafd"
      >
      </v-tab-item> -->
      <v-tab-item>
        <Layouts
          @openPredefinedLayouts="openPredefinedLayouts"
          @manageLayout="manageLayout"
          @previewSelectedLayout="previewSelectedLayout"
        />
      </v-tab-item>
      <v-tab-item> <Supplements /> </v-tab-item>
      <!-- <v-tab-item> <Preview /> </v-tab-item> -->
    </v-tabs-items>
  </div>
</template>

<script>
import ReportBuilder from '../../components/ReportBuilder/Index.vue'
import Layouts from '../../components/Layouts/Index.vue'
import Supplements from '../../components/Supplements/Index.vue'
import Preview from '../../components/Preview/Index.vue'
import Report from '../../components/ReportProperties/Report.vue'
// import IntialParameters from '../../components/ReportProperties/IntialParameters.vue';
import ReportSupplements from '../../components/ReportProperties/Supplements.vue'
import PredefinedLayouts from '../../components/Layouts/PredefinedLayouts.vue'
import ReportThemes from '../../components/ReportProperties/Themes.vue'
import {
  createNamespacedHelpers as cnh,
  mapState as mapRootState,
  mapMutations as mapRootMutations,
  mapActions as mapRootActions
} from 'vuex'
const {
  mapMutations: mapReportBuilderMutations,
  mapState: mapReportBuilderState,
  mapActions: mapReportBuilderActions
} = cnh('ReportBuilder')
import { ObjectID } from 'bson'
export default {
  data() {
    return {
      // currentTab: 0,
      // layoutSelection: false,
      dialog: false,
      currentReportTab: 0,
      reportMenuItems: [
        { title: 'Publish', key: 'publish' },
        { title: 'History', key: 'history' },
        { title: 'Documentation', key: 'documentation' },
        { title: 'Tutorial', key: 'tutorial' }
      ],
      reportPublishUnPublishHistory: [],
      showPublishedHistoryDialog: false,
      showReportNameForHistory: '',
      errorText: ''
    }
  },
  components: {
    ReportBuilder,
    Layouts,
    Supplements,
    Preview,
    Report,
    PredefinedLayouts,
    ReportSupplements,
    ReportThemes
  },
  computed: {
    ...mapRootState(['report']),
    ...mapReportBuilderState([
      'allElementsOfAReportFetchedFromDB',
      'currentLayoutData',
      'layoutSelection',
      'currentTab',
      'isAllElemsAndCurrentLayoutSaved'
    ]),
    showToolBarTitle() {
      if (this.reportPublishUnPublishHistory.length === 0) {
        return 'This report has not been published'
      } else {
        return `Pubished History of a Report - ${this.showReportNameForHistory}`
      }
    },
    currentTab: {
      get() {
        return this.$store.state.ReportBuilder.currentTab
      },
      async set(value) {
        // if(!this.isAllElemsAndCurrentLayoutSaved){
        //   const isOk = await this.openConfirmDialog({
        //     header: "Warning",
        //     message: 'Changes are Unsaved. Are you sure, you want to Navigate to other sectoion ?. Changes will be discarded.',
        //   });
        //   console.log('isOk :>> ', isOk);
        //   console.log('isOk ? value : this.currentTab :>> ', isOk ? value : this.currentTab);
        //   this.setCurrentTab(isOk ? value : this.currentTab)
        // }else{
        //   }
        if (value == 0) {
          this.setSelectedPreviewLayout()
        }
        this.setCurrentTab(value)
      }
    }
  },
  methods: {
    ...mapReportBuilderMutations([
      'setAllLayoutsData',
      'mapAllElementsOfACurrentLayout',
      'setAllFetchedElementsOfACurrentLayout',
      'togglePredifinedLayoutsDialog',
      'setLayoutSelection',
      'setCurrentTab',
      'setSelectedPreviewLayout'
    ]),
    ...mapRootMutations([
      'openSnackbarWithTime',
      'toggleLoader',
      'toggleBtnLoader',
      'setReport'
    ]),
    ...mapReportBuilderActions([
      'getAllElementsOfACurrentLayout',
      'updateBIReport',
      'getReportPublishHistory',
      'publishReport',
      'setLayoutSupplement'
    ]),
    ...mapRootActions(['openConfirmDialog']),
    async manageLayout({ mode = '', payload = {}, devicesPrior }) {
      console.log('mode, payload :>> ', mode, payload)
      const currentLayoutRefBeforeEditLayout = JSON.parse(
        JSON.stringify(this.currentLayoutData)
      )
      try {
        switch (mode) {
          case 'create':
            // this.currentTab = 0
            this.cancelLayoutSelection()
            payload.forEach((container) => {
              let modifiedElems = []
              container.elements.forEach((elem) => {
                modifiedElems.push({
                  ...elem,
                  name: '',
                  elemLinkTo: new ObjectID().toString()
                })
              })
              container.elements = modifiedElems
            })
            this.setAllLayoutsData({
              mode: 'createLayout',
              payload,
              devicesPrior
            })
            break
          case 'edit':
            console.log(
              '***********',
              this.isAllElemsAndCurrentLayoutSaved,
              this.$store.state.ReportBuilder
            )
            if (!this.isAllElemsAndCurrentLayoutSaved) {
              console.log('forced Save with confirmation')
            }
            this.toggleLoader()
            await this.setAllLayoutsData({ mode: 'editLayout', payload })
            await this.setLayoutSupplement()
            const res = await this.getAllElementsOfACurrentLayout()
            console.log('res [manageLayout][edit layout] :>> ', res)
            if (res.ok) {
              this.setAllFetchedElementsOfACurrentLayout(res.result)
              this.mapAllElementsOfACurrentLayout()
            } else {
              throw new Error('Failed to fetch Selected Layout Data')
            }
            this.toggleLoader()
            // this.currentTab = 0
            this.setCurrentTab(0)
            break
          case 'duplicate':
            // this.setAllLayoutsData({ mode: "listAllLayouts", payload })
            // this.mapAllElementsOfACurrentLayout()
            // this.currentTab = 0
            this.setCurrentTab(0)
            break
          default:
            console.warn('INVALID MANAGE LAYOUT MODE ')
            break
        }
      } catch (error) {
        // Fallback to previous layout if failed to get selected layout data such as elements
        this.toggleLoader()
        this.setAllLayoutsData({
          mode: 'editLayout',
          payload: currentLayoutRefBeforeEditLayout
        })
        console.log('error :>> ', error)
        this.openSnackbarWithTime({
          txt: "Failed to get Layout's Data. Error: " + error.message,
          time: 4000
        })
      }
    },
    previewSelectedLayout(layout = {}) {
      console.log('layout :>> ', layout)
      if (!layout || !Object.keys(layout).length) {
        console.warn('Empty Element for Preview. Layout Payload is :- ', layout)
        this.openSnackbarWithTime({
          txt: 'No Layout to Preview',
          time: 3000
        })
        return
      }
      // this.setAllLayoutsData({ mode: 'editLayout', payload: layout })
      // this.currentTab = 3
      this.setSelectedPreviewLayout(layout)
      this.setCurrentTab(3)
    },
    async submitReportMenuOption(action) {
      try {
        switch (action) {
          case 'publish':
            const isOk = await this.openConfirmDialog({
              header: 'Info',
              message: `Are you sure, You want to Publish this Report :- ${this.report.displayName} ?`
            })
            if (isOk) {
              console.log('----------publish: ')
              this.toggleLoader()
              const res = await this.publishReport({
                publish: true,
                reportId: this.report._id
              })
              console.log('res [PUBLISH REPORT] :>> ', res)
              this.openSnackbarWithTime({
                txt: res.ok
                  ? 'Report Published Successfully'
                  : 'Failed to Publish Report',
                time: 4000
              })
              this.toggleLoader()
            }
            break
          case 'history':
            console.log('----------history: ')
            this.toggleLoader()
            const res = await this.getReportPublishHistory({
              reportId: this.report._id
            })
            console.log('res [PUBLISH HISTORY] :>> ', res)
            if (res.ok) {
              this.showPublishedHistoryDialog = true
              this.reportPublishUnPublishHistory = res.result
              this.reportPublishUnPublishHistory.length === 0
                ? (this.errorText = 'There is no History for this report')
                : null
              // for (let i of this.reportPublishUnPublishHistory) {
              //   this.showReportNameForHistory = i.name
              // }
              this.reportPublishUnPublishHistory.filter(
                (e) => (this.showReportNameForHistory = e.name)
              )
            }
            this.openSnackbarWithTime({
              txt: res.ok
                ? 'Report Published History Fetched Successfully'
                : 'Failed to get Report Publish History',
              time: 4000
            })
            this.toggleLoader()
            break
          case 'documentation':
            console.log('----------documentation: ')
            this.$router.push('/home')
            break
          case 'tutorial':
            this.$router.push('/home')
            console.log('----------tutorial: ')
            break
          default:
            console.log('Wrong Report Menu Option action')
            break
        }
      } catch (error) {
        this.toggleLoader()
        console.log('error :>> ', error)
        this.openSnackbarWithTime({
          txt: 'Failed. Error: ' + error.message,
          time: 4000
        })
      }
    },
    async updateReportProps(payload = {}) {
      try {
        this.toggleLoader()
        this.toggleBtnLoader()
        const result = await this.updateBIReport(payload)
        console.log('result [updateReportProps] :>> ', result)
        if (result.ok) {
          this.setReport(result.result)
          // this.setAllLayoutsData({
          //   mode: 'listAllLayouts',
          //   payload: result.result
          // })
        }
        this.openSnackbarWithTime({
          txt: result.ok
            ? 'Report Saved Successfully'
            : 'Failed to Save Report',
          time: 3500
        })
        this.toggleLoader()
        this.toggleBtnLoader()
      } catch (error) {
        this.toggleLoader()
        this.toggleBtnLoader()
        console.log('error :>> ', error)
        this.openSnackbarWithTime({
          txt: 'Failed to Save Report',
          time: 3500
        })
      }
    },
    openPredefinedLayouts() {
      // this.currentTab = 0
      this.setCurrentTab(0)
      this.setLayoutSelection(true)
    },
    cancelLayoutSelection() {
      this.setLayoutSelection(false)
      this.setCurrentTab(0)
      // this.currentTab = 0;
    },
    handler(e) {
      console.log('(((((((((((((((((((((((((((((')
      console.log(e, this)
      alert(e.returnValue)
    },
    handlerClose: function () {
      console.log(this.isAllElemsAndCurrentLayoutSaved, 'this')
      if (!this.isAllElemsAndCurrentLayoutSaved) {
        confirm('Layout is unsaved')
        // Logic when change is detected
        // e.g. you can show a confirm() dialog to ask if user wants to proceed
      } else {
        // Logic when no change is detected
      }
    },
    async saveLayout(){
     await this.$refs['reportBuilder'].callSaveLayout()
    }
  },
  created() {
    console.log('***************66666666666666666666666666666**********')
    // window.addEventListener('beforeunload', function (e) {
    //   var confirmationMessage = 'o/'

    //   //Gecko + IE
    //   return confirmationMessage //Webkit, Safari, Chrome
    // })
    // window.addEventListener('beforeunload', function (e) {
    //   // *********** perform database operation here
    //   // before closing the browser ************** //
    //   console.log('OOOOOOOOOOOOOOOOOOOOOOOOOO')
    //   var confirmationMessage = 'o/'
    //   ;(e || window.event).returnValue = confirmationMessage
    //   // added the delay otherwise database operation will not work
    //   console.log(this)
    //   return false
    // })
    // document.addEventListener('beforeunload', this.handlerClose)
    // window.onbeforeunload = function () {
    //   console.log(`[this]: `, this);
    //   if (unsavedChanges) return true
    //   else return null
    // }

    // window.onbeforeunload = function (e) {}
  }
}
</script>

<style scoped lang="scss">
.MAIN-COMPOSER {
  height: 100vh;
  background-color: $link-water;
}
.round-btn-wrapper {
  height: 20px;
  // width: 18px;
  // border-radius: 50px;
  // border: $harlequin 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1.5px 3px 5px 6px;
  // margin-right: 5px;
}
.toolbarWrapper {
  // height: 50px !important;
  position: sticky;
  top: 0;
  z-index: 99;
}

.v-toolbar__content {
  // align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  height: 50px !important;
}
.overrideTitle {
  overflow: unset;
  text-overflow: unset;
  color: $royal-blue;
  padding-right: 1rem;
  font-size: 16px;
  font-weight: bold;
}
.dot {
  padding-right: 3px;
  font-size: 20px;
}
.active-tab {
  background-color: $deep-sapphire;
  color: $link-water !important;
}
.report-icon {
  color: $cornflower;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v-btn--fab.v-size--small {
  height: 30px;
  width: 30px;
}
.v-application .royal-blue--text {
  color: #75aae7 !important;
  caret-color: #75aae7 !important;
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 40px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
.v-list-item__title {
  align-self: center;
  font-size: 14px;
}
.v-tab {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 0.8rem;
  font-weight: 500;

  justify-content: center;
  letter-spacing: 0.0892857143em;
  line-height: normal;
  min-width: 90px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #000000;
}

.v-application .elevation-0 {
  box-shadow: rgb(33 35 38 / 18%) 0px 10px 5px -10px;
}

// .v-tabs-slider-wrapper {
//     bottom: 0;
//     margin: 0 !important;
//     position: absolute;
//     transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
//     z-index: 1;
//     he
// }
</style>
