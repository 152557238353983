<template>
  <div style="width: 100%;">
    <v-layout row wrap v-if="selectedSupplement.type !== 'initialDefaultParams'">
      <SurveyInstance
        style="width: 100%"
        v-if="showFilter"
        :json="srvJson"
        @setHandler="
          (h) => {
            sHandler = h
          }
        "
        @onInput="onChangeInput"
      />
      <template v-else>
        <div
          v-if="!showFilter && !!selectedSupplement"
          class="mt-2 loading display-1"
          style="text-align: center; width: 100%"
        >
          Loading
        </div>
      </template>
    </v-layout>
    <div v-else>
      <pre>{{idpPreviewJson}}</pre>
    </div>
  </div>
</template>

<script>
const initialState = () => ({
  pageQ: [],
  srvJson: {
    showQuestionNumbers: 'off',
    showNavigationButtons: false,
    showCompletedPage: false,
    questionTitleLocation: 'left',
    pages: []
  },
  filtersConfig: false,
  sHandler: {},
  filterRenderConfig: {
    selfData: false,
    hrcyExtraKeys: []
  }
})
import moment from 'moment'
import { createNamespacedHelpers as cnh } from 'vuex'
const { mapActions, mapGetters, mapState } = cnh('supplementStore')
export default {
  props: ['selectedSupplement'],
  data() {
    return {
      ...initialState(),
      showFilter: false,
      idpPreviewJson: null
    }
  },
  methods: {
    ...mapActions(['selfRoleConfig', 'fetchMasterDataMap', 'fetchHrcyMap', 'fetchIdp']),
    onChangeInput(whatChanged) {
      // console.log(`[whatChanged]: `, whatChanged)
      // console.log(`[this.sHandler]: `, this.sHandler)
      if (/^hrcy~/.test(whatChanged.name) && whatChanged.value) {
        this.sHandler.setValue('val~hrcy', whatChanged.value)
        this.hrcyMap(whatChanged)
      } else if (/^val~/.test(whatChanged.name) && whatChanged.value) {
        this.sHandler.setValue('copy_of_' + whatChanged.name, whatChanged.value)
      } else {
        if (
          this.filterRenderConfig.hrcyExtraKeys &&
          this.filterRenderConfig.hrcyExtraKeys.includes(whatChanged.name)
        ) {
          this.resetFilter([1, 0, 0])
        }
      }
      this.masterDataMap('choices_by_choice', whatChanged)
    },
    resetFilter(resetConfig = [1, 1, 1]) {
      // 0 => hrcy
      // 1 => periodic
      // 2 => survey
      if (resetConfig[0] && this.selectedSupplement.type == 'hrcy') {
        let selfDataForFilters = this.filterRenderConfig.selfData
        this.sHandler.setValue(
          'val~hrcy',
          selfDataForFilters.selfData[this.filtersConfig.userKey]
        )
        this.sHandler.setValue(
          'val~hrcy~' + this.filtersConfig.userRoleKey,
          selfDataForFilters.selfData[this.filtersConfig.userRoleKey]
        )
        for (let role of this.filtersConfig.roles) {
          let below_self_role = role.level > selfDataForFilters.selfData.level
          let currentRoleSrvName = 'hrcy~' + role.val
          let q = this.sHandler.getQuestionByName(currentRoleSrvName)
          if (!q) continue
          if (below_self_role) {
            q.choices = []
            q.visible = false
            this.sHandler.clearValue(currentRoleSrvName)
          }
          if (role.level == selfDataForFilters.level) {
            let temp = this.sHandler.data[currentRoleSrvName]
            this.sHandler.clearValue(currentRoleSrvName)
            this.sHandler.setValue(currentRoleSrvName, temp)
          }
        }
      }

      if (resetConfig[0] && this.selectedSupplement.type == 'periodic') {
        // this.srv.clearValue('val~periodic')
        // this.srv.setValue('periodic~period', periodicConfig.defaultValue)
        // this.srv.setValue('val~periodic', periodicConfig.defaultValue)
      }

      if (resetConfig[0] && this.selectedSupplement.type == 'other_survey') {
      }
    },
    masterDataMap(by, whatChanged) {
      // console.log('by', by, this.filtersConfig)
      let masterDataMap = this.filtersConfig.masterDataMap
      if (!masterDataMap) {
        // console.log('it saying I am not available')
        return
      }
      masterDataMap.forEach((mdConfig, index) => {
        if (
          by == 'choices_by_mortal_id' &&
          /choices_by_mortal_id/.test(mdConfig.type)
        ) {
          this.fetchMasterDataMap({ mdConfig }).then((response) => {
            console.log(
              '[masterDataMap] Response ',
              index,
              mdConfig.target,
              response
            )
            if (response.ok) {
              this.sHandler.setChoices(mdConfig.target, response.choices)
              if (
                mdConfig.hasOwnProperty('defaultValueIdx') &&
                response.choices[mdConfig.defaultValueIdx]
              ) {
                this.sHandler.setValue(
                  mdConfig.target,
                  response.choices[mdConfig.defaultValueIdx].value
                )
              }
            }
          })
        }

        if (/choices_by_sys/.test(mdConfig.type) && by == 'choices_by_sys') {
        }

        if (
          by == 'choices_by_choice' &&
          /choices_by_choice/.test(mdConfig.type) &&
          ((mdConfig.source && mdConfig.source == whatChanged.name) ||
            (mdConfig.sourceKeysMap &&
              Object.values(mdConfig.sourceKeysMap).includes(whatChanged.name)))
        ) {
          let sourceValue = mdConfig.source
            ? whatChanged.value
            : mdConfig.sourceKeysMap
            ? Object.keys(mdConfig.sourceKeysMap).reduce(
                (accumulator, sourceKey) => {
                  accumulator[sourceKey] =
                    this.sHandler.data[mdConfig.sourceKeysMap[sourceKey]]
                  return accumulator
                },
                {}
              )
            : false
          console.log(`[sourceValue]: `, mdConfig.target, sourceValue)
          console.log(`[sourceKeysMap]: `, mdConfig.sourceKeysMap)
          this.fetchMasterDataMap({
            mdConfig,
            mortal: false,
            sourceValue
          }).then((response) => {
            console.log(
              'OnChange masterDataMap Response ',
              index,
              mdConfig.target,
              response
            )
            if (response.ok) {
              response.choices.find((e) => {
                if (e.value == this.sHandler.data[mdConfig.target]) {
                  this.sHandler.clearValueOfQuestion(mdConfig.target)
                  return true
                }
                return false
              })
              this.sHandler.setChoices(mdConfig.target, response.choices)
              if (mdConfig.hasOwnProperty('defaultValueIdx')) {
                this.sHandler.setValue(
                  mdConfig.target,
                  response.choices[mdConfig.defaultValueIdx].value
                )
              }
            }
          })
        }
      })
    },
    hrcyMap(whatChanged) {
      try {
        let hrcyConfig = this.filtersConfig
        let selfData = this.filterRenderConfig.selfData
        let currentSelectionRole = hrcyConfig.roles.find(
          (r) => 'hrcy~' + r.val == whatChanged.name
        )
        this.sHandler.setValue('val~hrcy-role', currentSelectionRole.text)
        let hrcyMapObj = {
          bossId: whatChanged.value,
          extraKeys: {}
        }
        if (hrcyConfig.extraKeys && hrcyConfig.extraKeys.length > 0) {
          for (let i of hrcyConfig.extraKeys) {
            if (!(i.type && i.keyToSendAs && (i.filterKey || i.value))) {
              console.log(
                '[Warning] Missing params in extraKeys for HrcyMap for --> ',
                i
              )
            } else {
              if (i.type === 'from_filters') {
                this.filterRenderConfig.hrcyExtraKeys.push(i.filterKey)
                hrcyMapObj.extraKeys[i.keyToSendAs] =
                  this.sHandler.data[i.filterKey] || i.default
              } else if (i.type === 'constant') {
                hrcyMapObj.extraKeys[i.keyToSendAs] = i.value
              }
            }
          }
        }
        this.fetchHrcyMap({ hrcyConfig, mortal: false, ...hrcyMapObj }).then(
          (response) => {
            console.log('Response of hrcyMap ', whatChanged.name, response)
            if (!response.ok) {
              console.error('Problem in response of hrcyMap ')
              return
            }
            let currentSelectionRole = hrcyConfig.roles.find(
              (r) => 'hrcy~' + r.val == whatChanged.name
            )
            for (let hIdx = 0; hIdx < hrcyConfig.roles.length; hIdx++) {
              let ctx = this
              let role = hrcyConfig.roles[hIdx]
              let below_self_role = role.level > selfData.level
              let below_selected_role = role.level > currentSelectionRole.level
              let currentRoleSrvName = 'hrcy~' + role.val
              let q = this.sHandler.getQuestionByName(currentRoleSrvName)
              if (!q) continue
              if (!below_self_role) {
                if (role.level == selfData.level) {
                  q.visible = true
                  q.readOnly = true
                } else {
                  q.visible = false
                }
              }
              if (below_self_role) {
                let choices = response.reportees.filter(
                  (choice) => choice.role == role.val
                )
                if (choices.length) {
                  ctx.sHandler.setChoices(currentRoleSrvName, choices)
                  if (
                    !choices.find((el) => el.value == ctx.sHandler.data[q.name])
                  ) {
                    ctx.sHandler.clearValue(q.name)
                  } else {
                    ctx.sHandler.setValue(q.name, ctx.sHandler.data[q.name])
                  }
                  q.visible = true
                } else if (below_selected_role) {
                  ctx.sHandler.clearValue(q.name)
                  q.choices = []
                  q.visible = false
                } else {
                  /* below self role but above selected role */
                }
              }
            }
          }
        )
      } catch (error) {
        console.error(error)
      }
    },
    renderFilterHrcy() {
      let selfData = this.filterRenderConfig.selfData
      if (!selfData) {
        this.filterRenderConfig.selfData = this.filtersConfig.selfData
        selfData = this.filtersConfig.selfData || false
      }
      let panelQs = [
        {
          type: 'text',
          name: 'val~hrcy-role',
          title: 'Selected Role',
          readOnly: true,
          visible: true
        },
        {
          type: 'text',
          name: 'val~hrcy',
          title: 'Selected Employee',
          readOnly: true,
          visible: true
        }
      ]
      this.filtersConfig.roles = this.filtersConfig.roles.sort(
        (a, b) => a.level > b.level
      )
      let roleIdx = 0
      let below_self_role = false
      for (var hIdx = 0; hIdx < this.filtersConfig.roles.length; hIdx++) {
        let role = this.filtersConfig.roles[hIdx]
        if (
          !below_self_role &&
          role.val == selfData[this.filtersConfig.userRoleKey]
        ) {
          below_self_role = true
          this.filterRenderConfig.selfData.level = role.level
          let q = {
            type: 'dropdown',
            name: 'hrcy~' + role.val,
            readOnly: true,
            title: role.text,
            choices: [
              {
                text: selfData[this.filtersConfig.displayKey],
                value:
                  selfData[this.filtersConfig.selfRoleConfig.employerGIDField]
              }
            ]
          }
          panelQs.push(q)
        } else if (below_self_role) {
          let q = {
            type: 'dropdown',
            name: 'hrcy~' + role.val,
            choicesOrder: 'asc',
            title: role.text,
            choices: []
          }
          panelQs.push(q)
        }
        roleIdx++
      }

      this.pageQ.push({
        type: 'panel',
        name: this.filtersConfig.type,
        title: this.filtersConfig.panelDisplayName || '',
        elements: panelQs
      })
      this.srvJson.pages = [
        {
          name: '_',
          elements: this.pageQ
        }
      ]
      setTimeout(() => {
        this.sHandler.setChoices(
          'hrcy~' + selfData[this.filtersConfig.userRoleKey],
          [
            {
              text: selfData[this.filtersConfig.displayKey],
              value: selfData[this.filtersConfig.userKey]
            }
          ]
        )
        this.sHandler.setValue(
          'hrcy~' + selfData[this.filtersConfig.userRoleKey],
          selfData[this.filtersConfig.userKey]
        )
      }, 1000)
    },
    renderFilterPeriodic() {
      try {
        console.log(`[this.filtersConfig]: `, { ...this.filtersConfig })
        let panelQs = []
        if (this.filtersConfig.months) {
          console.log('<months>')
          let monthsChoices = []
          let defaultMonthVal = false
          if (Array.isArray(this.filtersConfig.months)) {
            monthsChoices = this.filtersConfig.months
          } else if (this.filtersConfig.months.range) {
          } else {
            let format = this.filtersConfig.months.format
            let currMonth = new Date().getMonth() + 1
            if (typeof this.filtersConfig.months.format == 'string')
              format = { text: format, value: format }

            let count = 0
            for (
              let mIdx = this.filtersConfig.yearStartFrom;
              mIdx < 12 + this.filtersConfig.yearStartFrom;
              mIdx++
            ) {
              console.log('mIdx', mIdx, this.filtersConfig.yearStartFrom)
              count++
              if (count == 20) break
              let modifiedMIdx = mIdx > 12 ? mIdx % 12 : mIdx
              let choice = {
                text: moment(modifiedMIdx, 'M').format(format.text),
                value: moment(modifiedMIdx, 'M').format(format.value),
                sortIdx:
                  modifiedMIdx <= currMonth ? modifiedMIdx + 12 : modifiedMIdx
              }
              if (
                modifiedMIdx == currMonth &&
                this.filtersConfig.defaultMonthCurrent
              ) {
                defaultMonthVal = choice.value
              }
              monthsChoices.push(choice)
            }
            monthsChoices = monthsChoices.sort((l, r) =>
              l.sortIdx < r.sortIdx ? 1 : -1
            )
          }

          let q = {
            type: 'dropdown',
            name: 'periodic~' + this.filtersConfig.filterKey,
            title: this.filtersConfig.filterKey,
            choices: monthsChoices
          }
          if (this.filtersConfig.defaultMonthValue)
            q.defaultValue = this.filtersConfig.defaultMonthValue
          if (this.filtersConfig.defaultMonthCurrent)
            q.defaultValue = defaultMonthVal
          panelQs.push(q)

          if (this.filtersConfig.extra)
            panelQs.push({
              type: 'dropdown',
              name: 'periodic~' + this.filtersConfig.filterKey,
              title: 'OR',
              choices: this.filtersConfig.extra
            })
        }
        if (this.filtersConfig.years) {
          console.log('<years>')
          let yearChoices = []
          let defaultYearVal = false
          if (Array.isArray(this.filtersConfig.years)) {
            yearChoices = this.filtersConfig.years
          } else {
            let currYear = new Date().getFullYear()
            let fyStartedThisYear =
              this.filtersConfig.yearStartFrom <= new Date().getMonth() + 1
            currYear = currYear - (fyStartedThisYear ? 0 : 1)
            yearChoices = this.filtersConfig.years.range
              .sort((a, b) => a - b)
              .map((e) => currYear + e)
              .map((yr) => {
                let yrFormatted = yr
                switch (this.filtersConfig.years.format) {
                  case 'YYYY':
                    yrFormatted = this.filtersConfig.years.isNumber
                      ? yr
                      : String(yr)
                    break
                  case 'YY-YY':
                    yrFormatted =
                      String(yr % 100) + '-' + String((yr + 1) % 100)
                    break
                  case 'YYYY-YY':
                    yrFormatted = String(yr) + '-' + String((yr + 1) % 100)
                    break
                  case 'YYYY-YYYY':
                    yrFormatted = String(yr) + '-' + String(yr + 1)
                    break
                }
                if (yr === currYear && this.filtersConfig.defaultYearCurrent)
                  defaultYearVal = yrFormatted
                return yrFormatted
              })
          }
          let q = {
            type: 'dropdown',
            name: 'periodic~' + this.filtersConfig.yearFilterKey,
            title: this.filtersConfig.yearFilterKey,
            choices: yearChoices
          }
          if (this.filtersConfig.defaultYearValue)
            q.defaultValue = this.filtersConfig.defaultYearValue
          if (this.filtersConfig.defaultYearCurrent)
            q.defaultValue = defaultYearVal
          panelQs.push(q)
        }
        // this.pageQ = panelQs;
        this.pageQ.push({
          type: 'panel',
          name: this.filtersConfig.type,
          title: this.filtersConfig.panelDisplayName || '',
          elements: panelQs
        })
        this.srvJson.pages = [
          {
            name: '_',
            elements: this.pageQ
          }
        ]
      } catch (error) {
        console.error('[renderFilterPeriodic]', error)
      }
    },
    renderOtherSurvey() {
      try {
        if (this.filtersConfig.form && this.filtersConfig.form.elements) {
          this.pageQ = this.pageQ.concat(this.filtersConfig.form.elements)
        }
        this.srvJson.pages = [
          {
            name: '_',
            elements: this.pageQ
          }
        ]
        this.masterDataMap('choices_by_mortal_id')
      } catch (error) {
        console.log('[renderOtherSurvey]', error)
      }
    },
    async renderIDP() {
      try {
        // console.log(`[this.selectedSupplement]: `, { ...this.selectedSupplement })
        let idpJson = await this.fetchIdp({idpConfig: this.selectedSupplement})
        console.log(`[idpJson]: `, idpJson)
        if (idpJson.ok) {
          // let data = idpJson.data
          // let data = JSON.stringify(idpJson.data, undefined, 4)
          // console.log(data,">>>>>>>>>>")
          this.idpPreviewJson = JSON.stringify(idpJson.data, undefined, 4)
          console.log(this.idpPreviewJson, "==========")
          }
      } catch (error) {
        console.log(error)
      }
    },
    async renderFilterForm() {
      this.filtersConfig = JSON.parse(this.selectedSupplement.configuration)
      let selfData = {}
      if (
        this.filtersConfig.type == 'hrcy' &&
        this.filtersConfig.hasOwnProperty('selfRoleConfig')
      ) {
        selfData = await this.selfRoleConfig({ hrcyConfig: this.filtersConfig })
        console.log(`[selfData]: `, selfData)
        if (selfData.ok) this.filterRenderConfig.selfData = selfData.data
      }
      if (this.filtersConfig.type === 'hrcy')
        this.renderFilterHrcy(this.filtersConfig)
      if (this.filtersConfig.type === 'periodic')
        this.renderFilterPeriodic(this.filtersConfig)
      if (this.filtersConfig.type === 'other_survey')
        this.renderOtherSurvey(this.filtersConfig)
      if (this.selectedSupplement.type === 'initialDefaultParams'){
        this.renderIDP(this.selectedSupplement)
      }
    },
  },
  async created() {
    console.log('______________RENDER______________')
    await this.renderFilterForm()
    this.showFilter = true
    // setTimeout(() => {
    //   console.log(`[this]: `, this)
    // }, 2000)
  },
  watch: {
    async selectedSupplement(nv) {
      console.log(`[nv]: `, nv)
      this.showFilter = false
      Object.assign(this.$data, initialState())
      await this.renderFilterForm()
      this.showFilter = true
      // setTimeout(() => {
      //   console.log(`[this]: `, this)
      // }, 2000)
    }
  }
}
</script>

<style>
.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(255, 255, 255, 0);
    text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
      0.5em 0 0 rgba(255, 255, 255, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
      0.5em 0 0 rgba(255, 255, 255, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(255, 255, 255, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}
</style>