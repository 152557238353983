var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],staticStyle:{"padding":"5px","border":"2px solid rgba(0, 0, 0, 0.1)"},attrs:{"elevation":"0","min-width":"300","max-width":"300","min-height":"180","max-height":"180"}},[(_vm.layout && _vm.layout.props && _vm.layout.props.isRoot)?_c('div',{staticClass:"ribbon ribbon-top-left"},[_c('span',{staticStyle:{"background-color":"#10b71f"},attrs:{"darken-2":""}},[_vm._v(" Root Layout")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-between",staticStyle:{"height":"180px","cursor":"pointer"}},[_c('div',{staticClass:"mt-5",staticStyle:{"height":"180px","text-align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.hide)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","x-small":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.submitOption('duplicate', _vm.layout)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Duplicate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-6",attrs:{"icon":"","text":"","x-small":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.submitOption('edit', _vm.layout)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}])},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hide)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","x-small":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.submitOption('preview', _vm.layout)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Preview")])]),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.layout.name)+" ")]),_c('v-card-subtitle',{staticStyle:{"margin-top":"-16px","text-align":"initial"}},[_vm._v(" "+_vm._s(_vm.layout.record && _vm.layout.record.updatedOn ? _vm.formatDate((_vm.layout.record.updatedOn)) : _vm.formatDate((_vm.layout.updatedOn)))+" ")])],1),(!_vm.hide)?_c('div',[_c('v-card-actions',[_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","origin":"center center"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',{staticClass:"v-list-custom"},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.submitOption(item.key, _vm.layout)}}},[_vm._v(_vm._s(item.key !== 'rootLayout' ? item.title : ("" + (_vm.layout && _vm.layout.props && _vm.layout.props.isRoot ? 'UnMark as Root Layout' : 'Mark as Root Layout'))))])],1)}),1)],1)],1)],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }