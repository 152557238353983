<template>
  <!-- <v-card height="100%" width="100%">
    <v-card-title primary-title class="pa-1">
      <div style="display: flex; width: 100%; padding: 4px">
        <div cols="8" style="width: 60%">
          <p
            style="
              font-size: 16px !important;
              font-weight: 500;
              text-transform: capitalize;
            "
            class="mb-0"
          >
            {{ name.replace(/_/g, ' ') }}
          </p>
        </div>
        <div style="display: flex; width: 40%; padding-right: 2px" cols="4">
          <slot name="action"> </slot>
        </div>
      </div>
      <div wrap no-gutters>
        <slot name="description" style="color: grey"></slot>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text
      :style="{
        borderBottom: borderColor !== '' ? `4px solid ${borderColor}` : ''
      }"
      style="padding: unset"
    >
      <v-container fluid grid-list-xs>
        <v-row wrap no-gutters>
          <v-col cols="4">
            <span class="mb-0">{{ type }}</span>
          </v-col>
          <v-col cols="8">
            <template v-for="(key, idx) of filterKeys">
              <v-chip
                :key="key + idx"
                x-small
                outlined
                style="
                  margin-right: 4px;
                  color: rgb(27, 62, 196) !important;
                  caret-color: rgb(27, 62, 196) !important;
                "
                color="primary"
                >{{ key }}</v-chip
              >&nbsp;&nbsp;
            </template>
          </v-col>
        </v-row>
      </v-container>
      <slot name="status"> </slot>
    </v-card-text>
  </v-card> -->
  <v-card style="min-width: 180px">
    <div class="ma-0 pa-4 d-flex align-top">
      <div>
        <div
          style="
            font-size: 16px !important;
            font-weight: 500;
            text-transform: capitalize;
          "
          class="d-flex flex-wrap"
        >
          {{ name.replace(/_/g, ' ') }}
        </div>
        <v-card-subtitle class="pa-0 pt-1 caption">
          {{ requestId }}
        </v-card-subtitle>
      </div>

      <v-spacer></v-spacer>
      <div class="d-flex align-start ml-auto">
        <div class="d-flex align-end">
          <slot name="action"> </slot>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <v-card-text
      v-if="!borderColor"
      class="ma-0 pa-2"
      :style="{
        borderBottom: borderColor !== '' ? `4px solid ${borderColor}` : ''
      }"
    >
      <div style="width: 100%; display: flex; word-wrap: break-word">
        <v-card-text class="justify-start" style="width: 82%">
          {{ formattedBody }}
        </v-card-text>
        <v-card-actions
          class="justify-end"
          v-if="formattedBody && formattedBody.length > 25"
        >
          <v-btn icon @click="expand = !expand">
            <v-icon>{{
              expand ? 'mdi-chevron-up' : 'mdi-chevron-down'
            }}</v-icon>
          </v-btn>
        </v-card-actions>
        <!-- <v-card-text class="justify-start" style="width: 80%"> -->

        <!-- <slot name="description" style="color: grey"></slot> -->
      </div>
      <v-card-actions class="justify-end mx-2 pb-4 pr-2">
        <v-dialog
          transition="slide-x-reverse-transition"
          max-width="400"
          v-model="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined fab color="#2a51e8" v-bind="attrs" v-on="on">
              <v-icon>mdi-keyboard-backspace mdi-rotate-180</v-icon>
            </v-btn>
          </template>
          <v-card class="rounded-lg" min-height="200px">
            <v-app-bar flat color="white" class="ma-0 pa-2">
              <v-toolbar-title
                style="
                  font-size: 16px !important;
                  font-weight: 500;
                  text-transform: capitalize;
                "
              >
                {{ type.replace('_', ' ') }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-actions>
            </v-app-bar>
            <v-divider></v-divider>
            <v-card-text class="justify-center mt-2">
              <v-list-item>
                <v-list-item-subtitle class="text-center"
                  >OP</v-list-item-subtitle
                >
                <v-list-item-subtitle class="text-center"
                  >IP</v-list-item-subtitle
                >
              </v-list-item>
              <v-list class="transparent">
                <v-list-item v-for="(filterkey, i) in filterKeys" :key="i">
                  <v-list-item-title
                    class="text-center"
                    style="text-transform: capitalize; color: #2a51e8"
                    >{{ filterkey }}</v-list-item-title
                  >

                  <v-list-item-icon
                    v-if="
                      getDepenedentParamsList(type, filterkey, configuration)
                    "
                  >
                    <v-icon color="#2a51e8">mdi-keyboard-backspace</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="text-center">
                    <template
                      v-for="(depen, idx) of getDepenedentParamsList(
                        type,
                        filterkey,
                        configuration
                      )"
                    >
                      <v-chip
                        :key="idx + depen"
                        style="margin-right: 2px"
                        outlined
                        color="#2a51e8"
                        >{{ depen }}</v-chip
                      >
                    </template>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-actions>
      <slot name="status"> </slot>
    </v-card-text>
    <v-card-text v-else
      class="ma-0 pa-2"
      :style="{
        borderBottom: borderColor !== '' ? `4px solid ${borderColor}` : ''
      }">
      <v-list-item >
          <v-list-item-subtitle
            style="text-transform: capitalize"
          >
           {{ type.replace('_', ' ') }}
          </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-subtitle
          style="text-transform: capitalize; color: #2a51e8"
          class="text-center"
          >OP</v-list-item-subtitle
        >
        <v-list-item-icon>
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-center">
          <template v-for="(filterkey, i) in filterKeys">
            <v-chip
              :key="i + filterkey"
              style="margin-right: 2px"
              outlined
              small
              color="#2a51e8"
              >{{ filterkey }}</v-chip
            >
          </template>
        </v-list-item-subtitle>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    name: String,
    type: String,
    requestId: String,
    borderColor: String,
    creatorNote: String,
    filterKeys: {
      type: Array,
      default: () => []
    },
    configuration: String
  },
  data() {
    return {
      expand: false,
      dialog: false
    }
  },
  methods: {
    getDepenedentParamsList(type, filterkey, conf) {
      // console.log(`[suppConf]: `, suppConf)
      let returnable = []
      if (type == 'hrcy') {
      }
      if (type == 'other_survey') {
        let config = JSON.parse(conf || JSON.stringify({}))
        if (config.masterDataMap && config.masterDataMap.length) {
          let filterMap = config.masterDataMap.find(
            (el) => el.target == filterkey
          )
          if (filterMap) {
            if (filterMap.sourceKey) {
              returnable.push(filterMap.sourceKey)
            }
            if (
              filterMap.sourceKeysMap &&
              typeof filterMap.sourceKeysMap == 'object'
            ) {
              returnable.push(...Object.values(filterMap.sourceKeysMap))
            }
          }
        }
      }
      if (type == 'periodic') {
      }
      // console.log(`[returnable]: `, returnable)
      return returnable
    }
  },
  computed: {
    formattedBody() {
      if (this.creatorNote) {
        if (this.expand) {
          return this.creatorNote
        }
        if (this.creatorNote.length < 25) {
          return this.creatorNote
        }
        return `${this.creatorNote.slice(0, 25).trim()}...`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chip {
  border-radius: 50px;
  padding: 10px;
  margin: 4px 4px 4px 0px;
  color: rgb(27, 62, 196) !important;
  caret-color: rgb(27, 62, 196) !important;
}
.filter-key-chip {
  margin-left: 10px;
}
.grow {
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
  height: 25px;
  margin: 5px 1% 5px 1%;
  float: left;
  position: relative;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  overflow: hidden;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.grow:hover {
  height: 100%;
  overflow: unset !important;
  text-overflow: unset !important;
}
</style>