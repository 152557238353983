<template>
  <v-container grid-list-xs fluid style="padding:unset">
    <v-row wrap no-gutters>
      <v-col cols="12">
        <ActionBar @previewLayout="previewLayout" ref="actionBar"/>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="8" class="mt-2 pr-1">
        <GroundArea />
      </v-col>
      <v-col cols="4" class="mt-2 pl-1">
        <SettingPanel />
        <!-- <ToolPanel /> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActionBar from '../../components/ReportBuilder/ActionBar.vue'
import GroundArea from '../../components/ReportBuilder/GroundArea.vue'
// import ToolPanel from '../../components/ReportBuilder/ToolPanel'
import SettingPanel from './SettingPanel.vue'

export default {
  components: {
    ActionBar,
    GroundArea,
    // ToolPanel,
    SettingPanel
  },
  methods: {
    previewLayout(layout) {
      this.$emit('previewSelectedLayout', layout)
    },
    async callSaveLayout(){
      await this.$refs['actionBar'].saveLayout()
    }
  }
}
</script>
